import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Collapse from "@material-ui/core/Collapse";
import Drawer, { DrawerProps } from "@material-ui/core/Drawer";
import MuiLink from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItemBase, {
  ListItemProps as ListItemPropsBase,
} from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import Guard from "../../common/Guard";
import { CalculationContext } from "../../state/Calculation";
import { CalculationErrorContext } from "../../state/CalculationErrors";
import { GuardContext } from "../../state/Guard";
import { RootContext } from "../../state/root";
import { Colors } from "../../utilities/colors";
import { isUserAdmin, isUserFullAdmin } from "../../utilities/user";
import ExportResultsDialog from "../Admin/ExportResults";
import ContactUsDialog from "../ContactUs/ContactUs";

const useStyles = makeStyles((theme) => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryItem: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(6),
    paddingBottom: theme.spacing(2),
  },
  selectedMainItem: {
    backgroundColor: Colors.Black,
  },
  selectedCategoryItem: {
    backgroundColor: Colors.NavMenuGrey,
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
    "$selectedMainItem &": {
      color: Colors.Sky,
    },
  },
  itemIcon: {
    minWidth: "auto",
    marginRight: theme.spacing(2),
  },
  list: {
    marginTop: "5rem",
  },
  listActive: {
    backgroundColor: Colors.Black,
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  listLogout: {
    paddingTop: "2rem",
    paddingBottom: "1rem",
    backgroundColor: Colors.Black,
  },
}));

interface ListItemProps extends ListItemPropsBase {
  component?: React.ComponentType<any> | undefined;
  icon?: IconProp;
  label: string;
  to?: string;
  selected?: boolean;
  disabled?: boolean;
  main?: boolean;
  href?: string;
  target?: string;
}

const ListItem: React.FC<ListItemProps> = ({
  icon,
  to,
  label,
  selected,
  disabled,
  main = true,
  component = Link,
  href,
  target,
  onClick,
}) => {
  const classes = useStyles();
  return (
    <ListItemBase
      button
      className={clsx({
        [classes.categoryHeader]: main,
        [classes.categoryItem]: !main,
        [classes.selectedCategoryItem]: selected && !main,
        [classes.selectedMainItem]: selected && main,
      })}
      component={component}
      to={to}
      disabled={disabled}
      href={href}
      target={target}
      onClick={onClick}
    >
      {icon && (
        <ListItemIcon className={classes.itemIcon}>
          <FontAwesomeIcon
            icon={icon}
            color={selected ? Colors.Sky : "white"}
          />
        </ListItemIcon>
      )}
      <ListItemText
        classes={{
          primary: classes.categoryHeaderPrimary,
        }}
      >
        {label}
      </ListItemText>
    </ListItemBase>
  );
};

const Navigator: React.FC<DrawerProps> = observer((props: DrawerProps) => {
  const classes = useStyles();
  const [contactUsOpen, setContactUsOpen] = useState(false);
  const [exportOpen, setExportOpen] = useState(false);
  const history = useHistory<string[]>();
  const rootContext = useContext(RootContext);
  const calculationErrorContext = useContext(CalculationErrorContext);
  const { pathname } = useLocation();
  const guard = useContext(GuardContext);

  const inCalculation = pathname.startsWith("/Calculation");
  const calculationPage =
    inCalculation && pathname.replace("/Calculation/", "");

  const inAdmin = pathname.startsWith("/Admin");
  const adminPage = inAdmin && pathname.replace("/Admin/", "");

  const calculationContext = useContext(CalculationContext);
  const { currentCalculation } = calculationContext;
  const calculationPageId = currentCalculation?.pageId ?? 1;

  const onChangePage = async (page: string) => {
    const toCalculation = page.startsWith("/Calculation");
    if (!toCalculation && inCalculation && !(await guard.showGuard())) {
      return;
    }
    runInAction(() => {
      if (calculationErrorContext.hasAnyErrors && toCalculation) {
        calculationErrorContext.validateErrors = true;
        return;
      }

      calculationErrorContext.validateErrors = false;
      history.push(page);
    });
  };

  return (
    <Drawer variant="permanent" {...props} className="no-print">
      <List disablePadding className={classes.list}>
        <ListItem
          component={MuiLink}
          onClick={() => onChangePage("/Home")}
          label="Home"
          icon="home"
          selected={pathname === "/Home" || pathname === "/"}
        />

        {isUserAdmin(rootContext.user) && (
          <>
            <ListItem
              component={MuiLink}
              onClick={() => onChangePage("/Admin/ManageMember")}
              label="Admin"
              icon="tools"
              selected={inAdmin}
            />
            <Collapse in={inAdmin} timeout="auto" unmountOnExit>
              <List disablePadding className={classes.listActive}>
                <ListItem
                  to={"/Admin/ManageMember"}
                  label="Manage member"
                  selected={adminPage === "ManageMember"}
                  main={false}
                />
                <ListItem
                  to={"/Admin/CreateCalculation"}
                  label="Create calculation"
                  selected={adminPage === "CreateCalculation"}
                  main={false}
                />
                <ListItem
                  to={"/Admin/CreateMember"}
                  label="Create member"
                  selected={adminPage === "CreateMember"}
                  main={false}
                />
                <ExportResultsDialog
                  open={exportOpen}
                  setOpen={setExportOpen}
                />
                <ListItem
                  label="Export results"
                  component={MuiLink}
                  onClick={() => setExportOpen(true)}
                  main={false}
                />
                {isUserFullAdmin(rootContext.user) && (
                  <>
                    <ListItem
                      to={"/Admin/BulkUpdate"}
                      label="Bulk update"
                      selected={adminPage === "BulkUpdate"}
                      main={false}
                    />
                    <ListItem
                      to={"/Admin/Spreadsheet"}
                      label="Spreadsheet"
                      selected={adminPage === "Spreadsheet"}
                      main={false}
                    />
                  </>
                )}
              </List>
            </Collapse>
          </>
        )}

        <ListItem
          component={MuiLink}
          onClick={() => onChangePage("/Calculation/BasicInformation")}
          label="Calculation"
          icon="calculator"
          selected={inCalculation}
        />
        <Collapse in={inCalculation} timeout="auto" unmountOnExit>
          {inCalculation && <Guard />}
          <List disablePadding className={classes.listActive}>
            <ListItem
              onClick={() => onChangePage("/Calculation/BasicInformation")}
              label="Basic information"
              selected={
                calculationPage === "BasicInformation" || !calculationPage
              }
              main={false}
              component={MuiLink}
            />
            <ListItem
              onClick={() => onChangePage("/Calculation/Contributions")}
              label="Contributions"
              selected={calculationPage === "Contributions"}
              disabled={calculationPageId < 2}
              main={false}
              component={MuiLink}
            />
            <ListItem
              onClick={() => onChangePage("/Calculation/ServiceAndSalary")}
              label="Service and salary"
              selected={calculationPage === "ServiceAndSalary"}
              disabled={calculationPageId < 3}
              main={false}
              component={MuiLink}
            />
            <ListItem
              onClick={() => onChangePage("/Calculation/BenefitElections")}
              label="Benefit elections"
              selected={calculationPage === "BenefitElections"}
              disabled={calculationPageId < 4}
              main={false}
              component={MuiLink}
            />
            <ListItem
              onClick={() => onChangePage("/Calculation/Results")}
              label="Results"
              selected={calculationPage === "Results"}
              disabled={calculationPageId < 5}
              main={false}
              component={MuiLink}
            />
          </List>
        </Collapse>
        <ListItem
          component={MuiLink}
          onClick={() => onChangePage("/RepaymentOfContributions")}
          label="Repayment of contributions"
          icon="donate"
          selected={pathname === "/RepaymentOfContributions"}
        />
        <ListItem
          component={MuiLink}
          onClick={() => onChangePage("/BenefitOptions")}
          label="Benefit options"
          icon="list"
          selected={pathname === "/BenefitOptions"}
        />
        <ListItem
          label="Help"
          icon="question"
          component={MuiLink}
          href="https://www.cityoftacoma.org/cms/One.aspx?portalId=169&pageId=1423"
          target="_blank"
        />
        <ContactUsDialog open={contactUsOpen} setOpen={setContactUsOpen} />
        <ListItem
          label="Contact us"
          icon="paper-plane"
          component={MuiLink}
          onClick={() => setContactUsOpen(true)}
        />
      </List>

      <List disablePadding className={classes.listLogout}>
        <ListItemBase
          button
          className={classes.categoryHeader}
          onClick={async () => {
            if (inCalculation && !(await guard.showGuard())) {
              return;
            }
            await rootContext.Logout();
            window.open("./", "_self");
          }}
        >
          <ListItemIcon className={classes.itemIcon}>
            <FontAwesomeIcon icon="sign-out-alt" color="white" />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.categoryHeaderPrimary,
            }}
          >
            Log out
          </ListItemText>
        </ListItemBase>
      </List>
    </Drawer>
  );
});

export default Navigator;
