import {
  Box,
  Button,
  Checkbox,
  createStyles,
  FormControlLabel,
  Grid,
  Hidden,
  Link,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";

import { RootContext } from "../../state/root";

const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      marginTop: 0,
      marginBottom: 0,
      height: "100%",
    },
    loginForm: {
      padding: theme.spacing(2),
      marginLeft: "auto",
      marginRight: "auto",
    },
    signInBtn: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    visualContainer: {
      margin: 0,
      height: "100%",
      backgroundImage: "url(/public/images/signup.png)",
      backgroundRepeat: "no-repeat",
      clipPath: "polygon(10% 0, 100% 0, 100% 100%, 0 100%)",
    },
    disclaimer: {
      overflowX: "hidden",
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "green",
      },
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    forgotLinks: {
      marginTop: theme.spacing(1),
    },
  })
);

const Login: React.FC = observer(() => {
  const rootContext = useContext(RootContext);
  const [error, setError] = useState<string>();
  const history = useHistory<string[]>();

  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [acceptedTerms, setAcceptedTerms] = useState(
    rootContext.localStorage.acceptedTerms
  );
  const styles = useStyles();
  const login = async () => {
    if (!acceptedTerms) {
      return;
    }
    const success = await rootContext.Login(userName, password);
    if (success) {
      history.push("");
    } else {
      setError("Login Failed");
    }
  };

  return (
    <Grid className={styles.content} container justifyContent="space-around">
      <Grid className={styles.loginForm} item sm={12} md={4}>
        <TextField
          fullWidth
          label="User Name"
          variant="outlined"
          margin="normal"
          value={userName}
          onChange={(e) => setUserName(e.currentTarget.value)}
        />
        <TextField
          fullWidth
          label="Password"
          variant="outlined"
          margin="normal"
          type="password"
          value={password}
          onKeyDown={(e) => e.key === "Enter" && login()}
          onChange={(e) => setPassword(e.currentTarget.value)}
        />
        <Box
          className={styles.disclaimer}
          hidden={rootContext.localStorage.acceptedTerms}
          border={1}
          borderRadius="borderRadius"
          borderColor="palette"
          padding={"1rem"}
          component="div"
          overflow="scroll"
          maxHeight="10rem"
        >
          <Typography paragraph>
            The online estimator is provided to assist you with your retirement
            planning. You can use it to create a self-estimate of your future
            retirement benefit. The estimated benefit is based on information
            reported to TERS, and information you provide. The estimate benefit
            is shown as a gross amount. Any deductions taken for federal taxes
            or health care coverage will not be reflected.
          </Typography>
          <Typography paragraph>
            TERS makes no guarantee that the estimate you create with this
            online tool will be the same as the benefit you receive at
            retirement. Your actual benefit may be higher or lower. TERS will
            not be held responsible for any use you make of the information or
            decision you make based upon the results provided.
          </Typography>
          <Typography paragraph>
            By clicking "Accept & Continue" below, you agree that you have read
            and understand this information and are ready to create an estimate.
          </Typography>
          <Grid container justifyContent="center">
            <Grid item>
              <FormControlLabel
                label="Accept &amp; Continue"
                control={
                  <Checkbox
                    checked={acceptedTerms}
                    onChange={(event) => setAcceptedTerms(event.target.checked)}
                  />
                }
              />
            </Grid>
          </Grid>
        </Box>
        <Button
          fullWidth
          className={styles.signInBtn}
          variant="contained"
          color="primary"
          disabled={!acceptedTerms}
          onClick={login}
        >
          Sign In
        </Button>
        <Grid
          container
          className={styles.forgotLinks}
          direction="row"
          justifyContent="space-between"
        >
          <Tooltip title="Your username is your six digit Employee Number (e.g. 001234)">
            <Link>Forgot username</Link>
          </Tooltip>
          <Link component={RouterLink} to="/Login/ForgotPassword">
            Forgot password
          </Link>
        </Grid>
        {error && <Alert severity="error">{error}</Alert>}
      </Grid>
      <Hidden smDown>
        <Grid className={styles.visualContainer} item md={6} />
      </Hidden>
    </Grid>
  );
});

export default Login;
