import {
  Box,
  Button,
  createStyles,
  Grid,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";

import CalculationTextField from "../../common/CalculationTextField";
import PasswordField from "../../common/PasswordField";
import { useId } from "../../common/useId";
import {
  CalculationErrorContext,
  CalculationErrors,
} from "../../state/CalculationErrors";
import { RootContext } from "../../state/root";
import { ErrorCode, StatusCode } from "../../state/User";

const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      marginTop: 0,
      marginBottom: 0,
      height: "100%",
    },
    loginForm: {
      padding: theme.spacing(2),
      marginLeft: "auto",
      marginRight: "auto",
    },
    spacing: {
      marginTop: "2rem",
      marginBottom: "2rem",
      marginLeft: "1rem",
    },
    disclaimer: {
      overflowX: "hidden",
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "green",
      },
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    forgotLinks: {
      marginTop: theme.spacing(1),
    },
    formContainer: {
      backgroundColor: theme.palette.background.default,
      padding: "2rem",
    },

    actions: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
      paddingTop: theme.spacing(2),
    },
  })
);

const UpdatePassword: React.FC = observer(() => {
  const id = useId();
  const history = useHistory<string[]>();
  const errorContext = useContext(CalculationErrorContext);
  const { user } = useContext(RootContext);
  const styles = useStyles();

  const [state, updateState] = useState<{
    password: string;
    confirmPassword: string;
    currentPassword: string;
    emailAddress: string;
  }>({
    password: "",
    confirmPassword: "",
    currentPassword: "",
    emailAddress: "",
  });

  useEffect(() => {
    updateState((oldState) => ({
      ...oldState,
      emailAddress: user?.email ?? "",
    }));
  }, [user]);

  const handleUpdate = async () => {
    errorContext.clear();
    const result = await user?.saveToServer(
      {
        email: state.emailAddress,
      },
      state.password,
      state.currentPassword
    );
    if (result?.status === StatusCode.Failed) {
      switch (result.errorCode) {
        case ErrorCode.InvalidPassword:
          errorContext.addError(id, "Supplied password is invalid");
          break;
      }
    } else {
      history.push(``);
    }
  };

  return (
    <Grid className={styles.content} container justifyContent="space-around">
      <Grid className={styles.loginForm} item sm={8}>
        <Box className={styles.formContainer}>
          <Typography variant="h6">Update Your Password</Typography>
          <Typography variant="subtitle2" className={styles.spacing}>
            In order to use the system you must first change your temporary
            password:
          </Typography>
          <PasswordField
            label="Enter your current password:"
            value={state.currentPassword}
            onChange={(value) => {
              updateState((oldState) => ({
                ...oldState,
                currentPassword: value as string,
              }));
              errorContext.removeError(id, "Supplied password is invalid");
            }}
            enforcePasswordValidation={false}
          />
          <PasswordField
            label="Enter a new password:"
            value={state.password}
            validation={() =>
              state.currentPassword === state.password
                ? "Current password and new password should not match"
                : undefined
            }
            onChange={(value) =>
              updateState((oldState) => ({
                ...oldState,
                password: value as string,
              }))
            }
            enforcePasswordValidation={true}
          />
          <PasswordField
            label="Confirm your new password:"
            validation={() =>
              state.confirmPassword !== state.password
                ? "New password and confirmation password do not match"
                : undefined
            }
            value={state.confirmPassword}
            onChange={(value) =>
              updateState((oldState) => ({
                ...oldState,
                confirmPassword: value as string,
              }))
            }
            enforcePasswordValidation={false}
          />
        </Box>

        <Box className={styles.formContainer}>
          <Typography variant="h6">Add/Update Your Email Account</Typography>
          <CalculationTextField
            type="email"
            label="Email:"
            value={state.emailAddress}
            mustHaveValue={false}
            onChange={(value) =>
              updateState((oldState) => ({
                ...oldState,
                emailAddress: value as string,
              }))
            }
          />
        </Box>
        {errorContext.validateErrors && errorContext.hasAnyErrors && (
          <Alert severity="error">{errorContext.allErrorString}</Alert>
        )}
        <Grid
          container
          className={styles.actions}
          direction="row"
          justifyContent="space-between"
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={async () => {
                runInAction(() => {
                  errorContext.validateErrors = true;
                });
                if (errorContext.hasAnyErrors) {
                  return;
                }
                await handleUpdate();
              }}
            >
              Confirm
            </Button>
          </Grid>
          <Grid item>
            <Link component={RouterLink} to="/Login">
              Cancel
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default () => (
  <CalculationErrorContext.Provider value={new CalculationErrors(false)}>
    <UpdatePassword />
  </CalculationErrorContext.Provider>
);
