import axios from "axios";

// IE 11 disable cache
axios.defaults.headers.get["Pragma"] = "no-cache";
axios.defaults.headers.get["Cache-Control"] = "no-cache, no-store";

axios.interceptors.response.use(
  (config) => config,
  (error) => error.response
);

export class Api {
  static request = axios;
}
