import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import makeStyles from "@material-ui/styles/makeStyles";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";

import Button from "../../common/Button";
import CalculationDate from "../../common/CalculationDate";
import CalculationHeader from "../../common/CalculationHeader";
import CalculationLabel from "../../common/CalculationLabel";
import CalculationSection from "../../common/CalculationSection";
import CalculationSelect, { MenuItem } from "../../common/CalculationSelect";
import CalculationTextField from "../../common/CalculationTextField";
import { AdminContext, NewUser } from "../../state/Admin";
import { AlertContext } from "../../state/Alert";
import {
  CalculationErrorContext,
  CalculationErrors,
} from "../../state/CalculationErrors";
import { StatusCode } from "../../state/User";
import { employerLookUp } from "../Calculation/Contributions";

type EmployeeLookupKey = typeof employerLookUp;

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "80rem",
    minWidth: "40rem",
  },
  spacing: {
    marginBottom: "1rem",
    margin: "1rem",
  },
}));

const newUserTemplate = {
  employeeNumber: "",
  firstName: "",
  lastName: "",
  gender: "Male",
  dateOfBirth: undefined,
  employer: "C",
  dateOfMembership: undefined,
  lastDayOfEmployment: undefined,
  totalMemberContributions: 0,
  totalBenefitContributions: 0,
  nonTaxableContributions: 0,
  memberContributionsLastPayPeriod: 0,
  benefitContributionsLastPayPeriod: 0,
  currentContributionsLastPayDate: undefined,
  currentGrossPayPerPeriod: 0,
  additionalContributionsPre83: 0,
  additionalContributionsPost83: 0,
};

const CreateMember = observer(() => {
  const styles = useStyles();
  const [error, setError] = useState("");
  const [newUser, setNewUserState] =
    useState<Partial<NewUser>>(newUserTemplate);
  const adminContext = useContext(AdminContext);
  const errorContext = useContext(CalculationErrorContext);
  const alertContext = useContext(AlertContext);

  useEffect(() => {
    // tslint:disable-next-line: no-floating-promises
    adminContext.getUserList();
  }, [adminContext]);

  if (!adminContext.users) {
    return null;
  }

  const setNewUser = (newUser: Partial<NewUser>) => {
    setNewUserState((state) => ({ ...state, ...newUser }));
  };
  const foundUser = adminContext.users.find(
    (u) => u.employeeId === newUser.employeeNumber
  );
  const eeNumber = newUser.employeeNumber ?? "";
  const goodEe = !foundUser && eeNumber.length >= 4;

  const handleAddUser = async () => {
    setError("");
    runInAction(() => (errorContext.validateErrors = true));
    if (errorContext.hasAnyErrors) {
      return;
    }
    const result = await adminContext.addUser(newUser as NewUser);
    if (result.status === StatusCode.Failed) {
      setError("Add user failed, check password requirements and EmployeeId.");
    } else {
      await adminContext.getUserList(true);
      setNewUserState(newUserTemplate);
      alertContext.showAlert({
        type: "success",
        content: `User created`,
      });
    }
  };

  return (
    <>
      <Grid container className={styles.container}>
        <CalculationHeader title="Create member" variant="secondary" />
        <CalculationSection className={styles.spacing}>
          <CalculationTextField
            label="EE number:"
            value={newUser.employeeNumber}
            onChange={(value) =>
              setNewUser({ employeeNumber: value as string })
            }
            mustHaveValue={false}
          />
        </CalculationSection>

        {goodEe && (
          <>
            <CalculationHeader title="Required fields:" variant="secondary" />
            <CalculationSection className={styles.spacing}>
              <CalculationLabel
                label="Employee number:"
                value={newUser.employeeNumber}
              />
              <CalculationTextField
                label="First name:"
                value={newUser.firstName}
                onChange={(value) => setNewUser({ firstName: value as string })}
              />
              <CalculationTextField
                label="Last name:"
                value={newUser.lastName}
                onChange={(value) => setNewUser({ lastName: value as string })}
              />
              <CalculationDate
                label="Date of birth:"
                value={newUser.dateOfBirth}
                onChange={(value) => setNewUser({ dateOfBirth: value })}
              />
              <CalculationDate
                label="Date of membership:"
                value={newUser.dateOfMembership}
                onChange={(value) => setNewUser({ dateOfMembership: value })}
              />
              <CalculationDate
                label="Last pay date:"
                value={newUser.currentContributionsLastPayDate}
                onChange={(value) =>
                  setNewUser({ currentContributionsLastPayDate: value })
                }
              />
            </CalculationSection>

            <CalculationHeader title="Additional fields:" variant="secondary" />
            <CalculationSection className={styles.spacing}>
              <CalculationTextField
                label="Gross monthly pay:"
                type="number"
                format="dollar"
                value={newUser.currentGrossPayPerPeriod}
                onChange={(value) =>
                  setNewUser({ currentGrossPayPerPeriod: value as number })
                }
              />
              <CalculationSelect
                label="Gender:"
                value={newUser.gender}
                onChange={(value) => setNewUser({ gender: value })}
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
              </CalculationSelect>
              <CalculationSelect
                label="Employer:"
                value={newUser.employer}
                onChange={(value) => setNewUser({ employer: value })}
              >
                {Object.keys(employerLookUp).map((key) => (
                  <MenuItem value={key}>
                    {employerLookUp[key as keyof EmployeeLookupKey]}
                  </MenuItem>
                ))}
              </CalculationSelect>
              <CalculationTextField
                label="Member contributions as of last quarter:"
                type="number"
                format="dollar"
                value={newUser.totalMemberContributions}
                onChange={(value) =>
                  setNewUser({
                    totalMemberContributions: value as number,
                  })
                }
              />
              <CalculationTextField
                label="Benefit contributions as of last quarter:"
                type="number"
                format="dollar"
                value={newUser.totalBenefitContributions}
                onChange={(value) =>
                  setNewUser({
                    totalBenefitContributions: value as number,
                  })
                }
              />
              <CalculationTextField
                label="Member contributions as of last pay period:"
                type="number"
                format="dollar"
                value={newUser.memberContributionsLastPayPeriod}
                onChange={(value) =>
                  setNewUser({
                    memberContributionsLastPayPeriod: value as number,
                  })
                }
              />
              <CalculationTextField
                label="Benefit contributions as of last pay period:"
                type="number"
                format="dollar"
                value={newUser.benefitContributionsLastPayPeriod}
                onChange={(value) =>
                  setNewUser({
                    benefitContributionsLastPayPeriod: value as number,
                  })
                }
              />
              <CalculationTextField
                label="Additional contributions pre 8/1/1983:"
                type="number"
                format="dollar"
                value={newUser.additionalContributionsPre83}
                onChange={(value) =>
                  setNewUser({ additionalContributionsPre83: value as number })
                }
              />
              <CalculationTextField
                label="Additional contributions post 8/1/1983:"
                type="number"
                format="dollar"
                value={newUser.additionalContributionsPost83}
                onChange={(value) =>
                  setNewUser({ additionalContributionsPost83: value as number })
                }
              />
            </CalculationSection>
          </>
        )}
      </Grid>

      {(error ||
        foundUser ||
        (errorContext.validateErrors && errorContext.hasAnyErrors)) && (
        <Alert
          style={{
            marginRight: "1rem",
            marginLeft: "1rem",
            marginBottom: "1rem",
          }}
          severity="error"
        >
          {error || errorContext.allErrorString}
          {foundUser &&
            "This employee number has already been used. Please try again with another employee number"}
        </Alert>
      )}

      <Button
        style={{ marginLeft: "1rem" }}
        disabled={!goodEe}
        color="secondary"
        onClick={handleAddUser}
      >
        Create
      </Button>
    </>
  );
});

export default () => (
  <CalculationErrorContext.Provider value={new CalculationErrors(false)}>
    <CreateMember />
  </CalculationErrorContext.Provider>
);
