import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Alert from "@material-ui/lab/Alert";
import clsx from "clsx";
import { debounce } from "debounce";
import { autorun, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

import Button from "../../common/Button";
import CalculationDialog from "../../common/CalculationDialog";
import CalculationHeader from "../../common/CalculationHeader";
import { CalculationContext } from "../../state/Calculation";
import { CalculationErrorContext } from "../../state/CalculationErrors";
import { RootContext } from "../../state/root";
import { isUserAdmin, isUserFullAdmin } from "../../utilities/user";

import BasicInformation from "./BasicInformation";
import BenefitElections from "./BenefitElections";
import Contributions from "./Contributions";
import Results from "./Results";
import ServiceAndSalary from "./ServiceAndSalary";

const pageIdToTab = (tabId: number) => {
  switch (tabId) {
    case 1:
      return "BasicInformation";
    case 2:
      return "Contributions";
    case 3:
      return "ServiceAndSalary";
    case 4:
      return "BenefitElections";
    case 5:
      return "Results";
    default:
      return "BasicInformation";
  }
};

const tabToPageId = (tabId: string) => {
  switch (tabId) {
    case "BasicInformation":
      return 1;
    case "Contributions":
      return 2;
    case "ServiceAndSalary":
      return 3;
    case "BenefitElections":
      return 4;
    case "Results":
      return 5;
    default:
      return 1;
  }
};

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginTop: "1rem",
  },
  buttonRight: {
    float: "right",
    marginRight: 0,
  },
}));

const Calculation: React.FC = observer(() => {
  const styles = useStyles();
  const history = useHistory<string[]>();
  const { tabId } = useParams<{ tabId: string }>();
  const { user } = useContext(RootContext);
  const calculationContext = useContext(CalculationContext);
  const calculationErrorContext = useContext(CalculationErrorContext);

  const { currentCalculation } = calculationContext;
  const tab = tabId ?? "BasicInformation";
  const currentPageId = tabToPageId(tab);
  const calculationPageId = currentCalculation?.pageId ?? 1;
  const isAdmin = isUserAdmin(user);

  const changeTab = (tabId: string) => {
    runInAction(() => {
      if (calculationErrorContext.hasAnyErrors) {
        calculationErrorContext.validateErrors = true;
        return;
      }

      calculationErrorContext.validateErrors = false;
      if (calculationPageId >= tabToPageId(tabId)) {
        history.push(`/Calculation/${tabId}`);
      }
    });
  };

  useEffect(() => {
    runInAction(() => {
      calculationErrorContext.validateErrors = false;
    });
  }, [calculationErrorContext]);

  useEffect(() => {
    if (!currentCalculation) {
      // tslint:disable-next-line: no-floating-promises
      calculationContext.load();
    }
  }, [currentCalculation, calculationContext]);

  useEffect(() => {
    if (currentPageId > calculationPageId) {
      changeTab(pageIdToTab(calculationPageId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculationPageId, currentPageId]);

  // Automatically apply updates
  useEffect(() => {
    const updateCalculations = debounce(() => {
      if (!calculationErrorContext.hasAnyErrors) {
        currentCalculation?.updateCalculation();
      }
      updateCalculations.clear();
    }, 2500);

    const dispose = autorun(() => {
      if (
        currentCalculation?.lastState !== currentCalculation?.getState() &&
        !calculationErrorContext.hasAnyErrors
      ) {
        updateCalculations();
      }
    });

    return () => {
      dispose();
    };
  }, [currentCalculation, calculationErrorContext]);

  if (!currentCalculation) {
    return null;
  }

  return (
    <>
      {tab !== "Export" && (
        <>
          <Grid
            alignItems="center"
            justifyContent="space-between"
            direction="row"
            container
            className="no-print"
          >
            <Grid item>
              <CalculationHeader
                variant="secondary"
                title={`Calculation: ${currentCalculation.calculationName}`}
              />
            </Grid>
            <div>
              {isAdmin && (
                <>
                  {currentPageId === 5 && (
                    <Button
                      color="red"
                      disabled={calculationErrorContext.hasAnyErrors}
                      onClick={() =>
                        window.open(
                          `/BackupCalculations/${currentCalculation.uniqueId}`,
                          "_blank"
                        )
                      }
                    >
                      Backup Calculations
                    </Button>
                  )}
                  {isUserFullAdmin(user) && (
                    <Button
                      onClick={() =>
                        window.open(
                          `api/calculation/SaveSpreadsheet/${currentCalculation.uniqueId}`,
                          "_blank"
                        )
                      }
                      color="green"
                    >
                      Save spreadsheet
                    </Button>
                  )}
                </>
              )}
              <CalculationDialog page={tab} />
            </div>
          </Grid>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(_, value) => changeTab(value)}
            className="no-print"
          >
            <Tab label="Basic Information" value="BasicInformation" />
            <Tab
              label="Contributions"
              value="Contributions"
              disabled={calculationPageId < 2}
            />
            <Tab
              label="Service and salary"
              value="ServiceAndSalary"
              disabled={calculationPageId < 3}
            />
            <Tab
              label="Benefit elections"
              value="BenefitElections"
              disabled={calculationPageId < 4}
            />
            <Tab
              label="Results"
              value="Results"
              disabled={calculationPageId < 5}
            />
          </Tabs>
        </>
      )}

      {tab === "BasicInformation" && <BasicInformation />}
      {tab === "Contributions" && <Contributions />}
      {tab === "ServiceAndSalary" && <ServiceAndSalary />}
      {tab === "BenefitElections" && <BenefitElections />}
      {tab === "Results" && <Results />}

      {calculationErrorContext.validateErrors &&
        calculationErrorContext.hasAnyErrors && (
          <Alert severity="error">
            {calculationErrorContext.allErrorString}
          </Alert>
        )}

      <div className={clsx(styles.buttons, "no-print")}>
        {currentPageId < 5 ? (
          <Button
            color="primary"
            disabled={
              calculationErrorContext.validateErrors &&
              calculationErrorContext.hasAnyErrors
            }
            onClick={() => {
              runInAction(() => {
                if (calculationErrorContext.hasAnyErrors) {
                  calculationErrorContext.validateErrors = true;
                  return;
                }

                calculationErrorContext.validateErrors = false;
                currentCalculation.pageId = Math.max(
                  currentPageId + 1,
                  currentCalculation.pageId!
                );
                history.push(`/Calculation/${pageIdToTab(currentPageId + 1)}`);
              });
            }}
          >
            Next
          </Button>
        ) : (
          <>
            <Button
              color="primary"
              disabled={calculationErrorContext.hasAnyErrors}
              onClick={() => window.print()}
            >
              Print
            </Button>
          </>
        )}
        <Button
          color="secondary"
          disabled={
            calculationErrorContext.hasAnyErrors || !currentCalculation.isDirty
          }
          onClick={() => currentCalculation.saveCalculation()}
        >
          Save
        </Button>
        <Button
          color="slate"
          className={styles.buttonRight}
          disabled={
            (calculationErrorContext.validateErrors &&
              calculationErrorContext.hasAnyErrors) ||
            currentPageId === 1
          }
          onClick={() => {
            changeTab(pageIdToTab(currentPageId - 1));
          }}
        >
          Back
        </Button>
      </div>
    </>
  );
});

export default Calculation;
