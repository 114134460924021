import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect } from "react";

import { CalculationContext } from "../state/Calculation";
import { GuardContext } from "../state/Guard";
import { Colors } from "../utilities/colors";

import Button from "./Button";
import Modal, { DialogActions } from "./Dialog";

const useStyles = makeStyles((theme) => ({
  error: {
    color: Colors.Red,
  },
}));

const GuardDialog: React.FC = observer(() => {
  const style = useStyles();
  const [showDialog, setShowDialog] = React.useState(false);
  const guard = useContext(GuardContext);
  const calculationContext = useContext(CalculationContext);
  const { currentCalculation } = calculationContext;

  const onCancel = useCallback(
    async (proceed = false) => {
      if (proceed) {
        await calculationContext.load(
          currentCalculation?.userId,
          currentCalculation?.uniqueId,
          true
        );
      }
      runInAction(() => {
        guard.showGuardDialog = undefined;
        guard.resolveGuard?.(proceed);
        guard.resolveGuard = undefined;
        setShowDialog(false);
      });
    },
    [
      guard,
      calculationContext,
      currentCalculation?.uniqueId,
      currentCalculation?.userId,
    ]
  );

  const onSave = useCallback(async () => {
    await currentCalculation?.saveCalculation();
    runInAction(() => {
      guard.showGuardDialog = undefined;
      guard.resolveGuard?.(true);
      guard.resolveGuard = undefined;
      setShowDialog(false);
    });
  }, [guard, currentCalculation]);

  useEffect(() => {
    if (guard.showGuardDialog && currentCalculation?.isDirty) {
      setShowDialog(true);
    } else {
      runInAction(() => {
        guard.showGuardDialog = undefined;
        guard.resolveGuard?.(true);
        guard.resolveGuard = undefined;
      });
    }
  }, [guard, guard.showGuardDialog, currentCalculation, onCancel]);

  return (
    <Modal title="Save Warning" open={showDialog} setOpen={onCancel}>
      <Grid container>
        <div style={{ paddingLeft: "1rem" }}>
          <p>
            <span className={style.error}>Warning:</span> we have detected
            unsaved changes to your account. Select the save button to save your
            work, or ignore to continue without saving.
          </p>
        </div>
      </Grid>

      <DialogActions>
        <Button autoFocus color="secondary" onClick={onSave}>
          Save
        </Button>
        <Button color="primary" onClick={() => onCancel(true)}>
          Ignore
        </Button>
      </DialogActions>
    </Modal>
  );
});

export default GuardDialog;
