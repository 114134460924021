import { Grid, Theme } from "@material-ui/core";
import makeStyles from "@material-ui/styles/makeStyles";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";

import CalculationHeader from "../../common/CalculationHeader";
import CalculationLabelBase, {
  CalculationLabelProps,
} from "../../common/CalculationLabel";
import CalculationSection from "../../common/CalculationSection";
import {
  BenefitOption,
  CalculationContext,
  NonTaxable,
} from "../../state/Calculation";
import { RootContext } from "../../state/root";
import { filterUserBenefitOptions, isUserAdmin } from "../../utilities/user";

const SalaryMayExceed415ToolTip = () => (
  <>
    <p>
      The internal Revenue Code (IRC) provides an annual computation that sets a
      dollar limitation of benefits to be paid from a defined benefit plan like
      TERS. This compensation is subject to annual adjustments for cost of
      living increases.
    </p>
    <p>
      This means that any amount that exceeds the limits imposed by the IRC
      Section 415(m)(2) will be treated like private deferred compensation for
      income tax purposes and subject to withholding similar to wages, which are
      also subject to FICA and Medicare deductions for both employer and the
      employee (retiree). A separate deposit will be paid from the City of
      Tacoma Payroll Division for any excess payment over the benefit
      limitation, net of mandatory withholding.
    </p>
    <p>
      Once you retire, a reminder notice will be sent to you should the IRC
      Section 415 benefit limits apply.
    </p>
  </>
);

const SalaryMayExceed401Tooltip = () => (
  <>
    <p>
      In order to be a qualified plan, TERS must satisfy section 401(a)(17) of
      the Internal Revenue Code (IRC), which limits the amount of compensation
      that may be taken into account. The regulations imposed under IRC Section
      401(a)(17) apply the compensation limit to "allocations" in a defined
      contribution plan and "benefit accruals" in a defined benefit plan like
      TERS. This means that once a member reaches the yearly limits, no TERS
      contributions will be taken out from both the employee (member) and the
      employer. In effect, the average final compensation (AFC) that will be
      used to determine TERS benefit will probably be limited also. This amount
      is generally adjusted on a yearly basis factoring in the cost of living
      increases.
    </p>
  </>
);

const useStyles = makeStyles<Theme>((theme) => ({
  gridLeft: {
    [theme.breakpoints.up("md")]: {
      paddingRight: "2rem",
    },
  },
  gridRight: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "2rem",
    },
  },
}));

export const BenefitsDescription: React.FC<{
  option?: BenefitOption | NonTaxable;
}> = ({ option }) => {
  if (!option?.description1 || !option?.description2 || !option?.description3) {
    return <span style={{ marginTop: "1rem" }}>&nbsp;</span>;
  }
  return (
    <>
      <span
        style={{ marginTop: ".5rem" }}
        dangerouslySetInnerHTML={{
          __html: `${option?.description1} ${option?.description2} ${option?.description3}`,
        }}
      ></span>
    </>
  );
};
const CalculationLabel: React.FC<CalculationLabelProps> = (props) => (
  <CalculationLabelBase labelWidth={20} rightAlign {...props} />
);

const Results: React.FC = observer(() => {
  const calculationContext = useContext(CalculationContext);
  const rootContext = useContext(RootContext);
  const styles = useStyles();
  const { currentCalculation } = calculationContext;
  const isAdmin = isUserAdmin(rootContext.user);

  if (!currentCalculation) {
    return null;
  }

  const benefitOption = currentCalculation.benefitOptions?.find(
    (bo) => bo.value === currentCalculation.benefitOption
  );

  return (
    <Grid container direction="row" spacing={2}>
      <CalculationHeader centered title="TACOMA EMPLOYEES' RETIREMENT SYSTEM" />{" "}
      <CalculationHeader centered title="Estimation of Retirement Allowance" />
      <CalculationHeader
        centered
        title={`Filename: ${currentCalculation.calculationName}`}
      />
      <CalculationHeader title="Basic information" variant="secondary" />
      <Grid container item md={12} alignContent="flex-start">
        <CalculationSection>
          <Grid item md={6} className={styles.gridLeft}>
            <CalculationLabel
              label="Name:"
              value={`${currentCalculation.firstName} ${currentCalculation.lastName}`}
            />
            <CalculationLabel
              label="Membership date:"
              value={currentCalculation.dateOfMembership!.format("L")}
            />
            <CalculationLabel
              label="Retirement date:"
              value={currentCalculation.dateOfRetirement!.format("L")}
            />
            <CalculationLabel
              label="Member birth date:"
              value={currentCalculation.dateOfBirth!.format("L")}
            />
            {currentCalculation.annuitantType !== "No Annuitant" && (
              <CalculationLabel
                label="Annuitant birth date:"
                value={currentCalculation.annuitantDateOfBirth!.format("L")}
              />
            )}
            {(currentCalculation.benefitType === "Death-Deferred" ||
              currentCalculation.benefitType === "Death-Immediate") && (
              <CalculationLabel
                label="Death beneficiary birth date:"
                value={currentCalculation.beneficiaryDateOfBirth!.format("L")}
              />
            )}
          </Grid>
          <Grid item md={6} className={styles.gridRight}>
            <CalculationLabel
              label="SAP number:"
              value={currentCalculation.employeeNumber}
            />
            <CalculationLabel
              label="Termination date:"
              value={currentCalculation.lastDayOfEmployment!.format("L")}
            />
            {isAdmin && (
              <CalculationLabel
                label="Gender:"
                value={currentCalculation.gender}
              />
            )}
            <CalculationLabel
              label="Member age:"
              minimumFractionDigits={2}
              value={currentCalculation.results!.ageOfRetirement}
            />
            {currentCalculation.annuitantType !== "No Annuitant" && (
              <CalculationLabel
                label="Annuitant age:"
                minimumFractionDigits={2}
                value={currentCalculation.results!.annuitantAge}
              />
            )}
            {(currentCalculation.benefitType === "Death-Deferred" ||
              currentCalculation.benefitType === "Death-Immediate") && (
              <CalculationLabel
                label="Death beneficiary age:"
                minimumFractionDigits={2}
                value={currentCalculation.results!.deathBeneficiaryAge}
              />
            )}
          </Grid>
        </CalculationSection>
      </Grid>
      <Grid container item md={6} alignContent="flex-start">
        <CalculationHeader title="Contributions" variant="secondary" />
        <CalculationSection>
          {isAdmin && (
            <CalculationLabel
              label="Total member contributions:"
              format="dollar"
              minimumFractionDigits={2}
              value={currentCalculation.results!.totalMemberContributions}
            />
          )}
          <CalculationLabel
            label="Total benefit contributions:"
            value={currentCalculation.results!.totalBenefitContributions}
            minimumFractionDigits={2}
            format="dollar"
          />
          {!!currentCalculation?.dateOfMembership?.isBefore("01/01/1983") && (
            <CalculationLabel
              label="Additional pre-83 contributions:"
              value={currentCalculation.results!.pre83ExtraContributions}
              minimumFractionDigits={2}
              format="dollar"
            />
          )}
          <CalculationLabel
            label="Additional post-83 contributions:"
            value={currentCalculation.results!.post83ExtraContributions}
            minimumFractionDigits={2}
            format="dollar"
          />
          <CalculationLabel
            label="Non-taxable contributions:"
            value={currentCalculation.results!.nonTaxableContributions}
            minimumFractionDigits={2}
            format="dollar"
          />
        </CalculationSection>
      </Grid>
      <Grid container item md={6} alignContent="flex-start">
        <CalculationHeader title="Service and salary" variant="secondary" />
        <CalculationSection>
          <CalculationLabel
            label="Average final salary:"
            value={currentCalculation.results!.averageFinalSalary}
            minimumFractionDigits={2}
            format="dollar"
          />
          <CalculationLabel
            label="TERS service:"
            minimumFractionDigits={5}
            value={currentCalculation.results!.tersService}
          />
        </CalculationSection>
      </Grid>
      {currentCalculation.portability === "Y" && (
        <Grid container item md={6} alignContent="flex-start">
          <CalculationHeader title="Portability" variant="secondary" />
          <CalculationSection>
            <CalculationLabel
              label="Portable average salary:"
              value={currentCalculation.portabilityFae}
              minimumFractionDigits={2}
              format="dollar"
            />
            <CalculationLabel
              label="Portability service:"
              minimumFractionDigits={5}
              value={currentCalculation.portabilityService}
            />
          </CalculationSection>
        </Grid>
      )}
      <Grid container item md={6} alignContent="flex-start">
        <CalculationHeader title="IRS tests" variant="secondary" />
        <CalculationSection>
          <CalculationLabel
            label="Benefit amount may exceed the 415 limit:"
            value={
              currentCalculation.results?.flag415Limit === "Y" ? "Yes" : "No"
            }
            tooltip={<SalaryMayExceed415ToolTip />}
          />
          <CalculationLabel
            label="Salary may exceed the 401(a)(17) limit:"
            value={
              currentCalculation.results?.flag401Limit === "Y" ? "Yes" : "No"
            }
            tooltip={<SalaryMayExceed401Tooltip />}
          />
        </CalculationSection>
      </Grid>
      {currentCalculation.servicePurchaseSelected === "Yes" &&
        currentCalculation.results?.servicePurchaseAvailable === "Yes" &&
        !!currentCalculation.amountOfServiceToPurchase && (
          <Grid container item md={6} alignContent="flex-start">
            <CalculationHeader title="Service Purchase" variant="secondary" />
            <CalculationSection>
              <CalculationLabel
                label="Service purchase years:"
                value={currentCalculation.amountOfServiceToPurchase}
                format="number"
                minimumFractionDigits={5}
              />
              <CalculationLabel
                label="Service purchase cost:"
                value={currentCalculation.results?.servicePurchaseAmount}
                format="dollar"
                minimumFractionDigits={2}
              />
            </CalculationSection>
          </Grid>
        )}
      {currentCalculation.ssAdjustment === "Yes" && (
        <Grid container item md={6} alignContent="flex-start">
          <CalculationHeader
            title="Social security adjustments"
            variant="secondary"
          />
          <CalculationSection>
            <CalculationLabel
              label="Social security benefit at age 62:"
              value={currentCalculation.ssAtAge62}
              format="dollar"
              minimumFractionDigits={2}
            />
          </CalculationSection>
        </Grid>
      )}
      <Grid container item md={12}>
        <CalculationHeader
          title={`Benefit elections - you have selected: ${
            currentCalculation.benefitType
          } - ${benefitOption?.name ?? "Show all options"}`}
          variant="secondary"
        />
        <CalculationSection>
          {currentCalculation.results?.isEligibleForRetirement === "Y" ? (
            benefitOption ? (
              <BenefitsDescription option={benefitOption} />
            ) : (
              filterUserBenefitOptions(
                currentCalculation.benefitOptions,
                isAdmin
              )?.map((bo, index) => (
                <BenefitsDescription key={index} option={bo} />
              ))
            )
          ) : (
            "You are not eligible to retire under this scenario. If applicable, you can purchase up to 5 years of additional service under the ‘Benefit Elections’ screen. Please contact the Retirement Department if you have any questions."
          )}
        </CalculationSection>
      </Grid>
      {currentCalculation.results?.isEligibleForRetirement === "Y" &&
        currentCalculation?.nonTaxables!.filter((nt) => nt.display).length >
          0 && (
          <Grid container item md={12}>
            <CalculationHeader
              title="Non-taxable portion of the benefit"
              variant="secondary"
            />
            <CalculationSection>
              {currentCalculation
                ?.nonTaxables!.filter((nt) => nt.display)
                ?.map((bo, index) => (
                  <BenefitsDescription key={index} option={bo} />
                ))}
            </CalculationSection>
          </Grid>
        )}
      <Grid container item md={12}>
        <CalculationHeader title={"Disclaimer:"} />
        <CalculationSection>
          Application for Retirement must be made at least 30 days prior to
          Retirement. Retirement counseling takes approximately one hour for the
          options to be explained and the forms to be filled out. A Birth
          Certificate is needed for proof of age. If Option D or E is elected, a
          Marriage Certificate is required. Please contact us if you have worked
          under another Retirement System in Washington since you could have
          dual membership which may affect your benefits. If you have any
          questions or to make an appointment please call 253-502-8200 or
          888-404-3787.
        </CalculationSection>
      </Grid>
    </Grid>
  );
});

export default Results;
