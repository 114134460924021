import MomentUtils from "@date-io/moment";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCalculator,
  faDonate,
  faHome,
  faList,
  faPaperPlane,
  faPencilAlt,
  faQuestion,
  faQuestionCircle,
  faSignOutAlt,
  faSquare,
  faTools,
  faTrash,
  faTrashAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import MuiPickersUtilsProvider from "@material-ui/pickers/MuiPickersUtilsProvider";
import moment from "moment";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import App from "./App";

library.add(
  faCalculator,
  faDonate,
  faHome,
  faList,
  faPaperPlane,
  faPencilAlt,
  faQuestion,
  faQuestionCircle,
  faSignOutAlt,
  faSquare,
  faTools,
  faTrash,
  faTrashAlt,
  faUser
);
const baseUrl = document
  .getElementsByTagName("base")[0]
  .getAttribute("href") as string;
const rootElement = document.getElementById("root");

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
      <App />
    </MuiPickersUtilsProvider>
  </BrowserRouter>,
  rootElement
);
