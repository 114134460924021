import Grid from "@material-ui/core/Grid";
import React, { useCallback, useContext } from "react";

import { RootContext } from "../state/root";
import { Api } from "../utilities/request";

import Button from "./Button";
import Modal, { DialogActions } from "./Dialog";

// authentication timeout should be 60 minutes so we will show the dialog with 15 minutes to spare
// and force a refresh after the session expiry
const warningTime = 45 * 60 * 1000;
const expiryTime = 15 * 60 * 1000;

const TimeoutDialog: React.FC = () => {
  const [showDialog, setShowDialog] = React.useState(false);
  const rootContext = useContext(RootContext);

  const refresh = useCallback(async () => {
    setShowDialog(false);
    if (!(await rootContext.user?.load())) {
      window.location.reload();
    }
  }, [rootContext]);

  React.useEffect(() => {
    let showDialogHandle = 0;
    let logoutHandle = 0;
    let interceptRequestHandle = 0;

    if (rootContext.user) {
      if (showDialog) {
        logoutHandle = window.setTimeout(refresh, expiryTime);
      } else {
        showDialogHandle = window.setTimeout(
          () => setShowDialog(true),
          warningTime
        );
      }

      // handle requests
      interceptRequestHandle = Api.request.interceptors.request.use(
        (request) => {
          clearTimeout(showDialogHandle);
          clearTimeout(logoutHandle);
          showDialogHandle = window.setTimeout(
            () => setShowDialog(true),
            warningTime
          );
          return request;
        }
      );
    }

    return () => {
      clearTimeout(showDialogHandle);
      clearTimeout(logoutHandle);
      Api.request.interceptors.request.eject(interceptRequestHandle);
    };
  }, [showDialog, rootContext.user, refresh]);

  return (
    <Modal title="Timeout Warning" open={showDialog} setOpen={setShowDialog}>
      <Grid container>
        <div style={{ paddingLeft: "1rem" }}>
          <p>A period of inactivity has been detected.</p>
          <p>
            You will be logged out shortly and any unsaved work will be lost.
          </p>
        </div>
      </Grid>

      <DialogActions>
        <Button autoFocus onClick={refresh} color="secondary">
          Ok
        </Button>
        <Button autoFocus onClick={refresh} color="primary">
          Close
        </Button>
      </DialogActions>
    </Modal>
  );
};

export default TimeoutDialog;
