import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";

import Button from "../../common/Button";
import { AdminContext } from "../../state/Admin";
import { AlertContext } from "../../state/Alert";
import { CalculationContext } from "../../state/Calculation";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: "1rem",
  },
  input: {
    width: "100%",
  },
}));

const BulkUpdate: React.FC = observer(() => {
  const styles = useStyles();
  const adminContext = useContext(AdminContext);
  const alertContext = useContext(AlertContext);
  const calculationContext = useContext(CalculationContext);
  const [value, setValue] = useState("");

  const onUpdate = async () => {
    const result = await adminContext.bulkUpdate(value);
    await adminContext.getUserList(true);
    await calculationContext.load(
      calculationContext.currentCalculation?.userId,
      calculationContext.currentCalculation?.uniqueId,
      true
    );
    alertContext.showAlert({
      type: "info",
      content: `Users created: ${result.usersCreated}, updated: ${result.usersUpdated}, deleted: ${result.usersDeleted} and failed: ${result.usersFailed}`,
    });
    setValue("");
  };

  return (
    <>
      <TextField
        className={styles.input}
        multiline
        rows={20}
        onChange={(e) => setValue(e.currentTarget.value)}
        value={value}
        variant="outlined"
      />
      <Button className={styles.button} onClick={onUpdate} color="default">
        Update
      </Button>
    </>
  );
});

export default BulkUpdate;
