import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import moment, { Moment } from "moment";
import React, { FC, ReactNode, useContext } from "react";

import { employerLookUp } from "../components/Calculation/Contributions";
import { BenefitsDescription } from "../components/Calculation/Results";
import { Calculation, CalculationContext } from "../state/Calculation";
import { RootContext } from "../state/root";
import { Colors } from "../utilities/colors";
import { filterUserBenefitOptions, isUserAdmin } from "../utilities/user";

import Button from "./Button";
import CalculationHeader from "./CalculationHeader";
import { Tooltip } from "./Tooltip";

const useDialogTitleStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export interface DialogTitleProps {
  id: string;
  title: string;
  onClose: () => void;
}

const DialogTitle: FC<DialogTitleProps> = (props) => {
  const { onClose, title } = props;
  const classes = useDialogTitleStyles();
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <CalculationHeader title={title} />
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

interface CalculationRowData {
  title: string;
  property?: string;
  height?: number;
  getValue?: (calculation: Calculation, isAdmin: boolean) => string | ReactNode;
  show?: (calculation: Calculation, isAdmin: boolean) => boolean;
  groupId?: string;
  groupFilter?: (calculation: Calculation) => boolean;
  decimalPlaces?: number;
  format?: "number" | "dollar" | "percentage";
}

const formatNumber = (value: number | undefined, decimalPlaces: number) =>
  value?.toLocaleString("en-US", {
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits: decimalPlaces,
  });
const hasBenefit = (c?: Calculation) =>
  c?.benefitType === "Death-Deferred" || c?.benefitType === "Death-Immediate";
const dateToString = (m?: Moment) =>
  m?.isSame("0001-01-01") ? "N/A" : m?.format("L");
const isYes = (input?: string) => input === "Y" || input === "Yes";
const toYesNo = (input?: string) =>
  input === "Y" || input === "Yes" ? "Yes" : "No";
const hasBeneficiary = (c?: Calculation) =>
  c?.benefitType === "Death-Deferred" || c?.benefitType === "Death-Immediate";

const BasicInformationData: CalculationRowData[] = [
  { title: "Calculation description:", property: "description" },
  { title: "Benefit type:", property: "benefitType" },
  { title: "Participant information" },
  { title: "Employee number:", property: "employeeNumber" },
  { title: "First name:", property: "firstName" },
  { title: "Last name:", property: "lastName" },
  { title: "Date of birth:", property: "dateOfBirth" },
  { title: "Date of membership:", property: "dateOfMembership" },
  { title: "Last day of employment:", property: "lastDayOfEmployment" },
  { title: "Date of retirement:", property: "dateOfRetirement" },
  {
    title: "Age at retirement:",
    property: "results.ageOfRetirement",
    decimalPlaces: 2,
  },
  {
    title: "Death beneficiary information",
    height: 4,
    groupId: "death-ben",
    groupFilter: hasBenefit,
  },
  {
    title: "First name:",
    groupId: "death-ben",
    getValue: (c) =>
      hasBenefit(c) ? c?.beneficiaryInformationFirstName : undefined,
  },
  {
    title: "Last name:",
    groupId: "death-ben",
    getValue: (c) =>
      hasBenefit(c) ? c?.beneficiaryInformationLastName : undefined,
  },
  {
    title: "Date of birth:",
    getValue: (c) =>
      hasBenefit(c) ? dateToString(c?.beneficiaryDateOfBirth) : undefined,
    groupId: "death-ben",
  },
];

const ContributionData: CalculationRowData[] = [
  { title: "Calculation description:", property: "description" },
  { title: "Employer:", getValue: (c) => employerLookUp[c?.employer!] },
  { title: "Employee contributions" },
  {
    title: "Benefit contributions as of last quarter:",
    property: "benefitContributionsLastQuarter",
    format: "dollar",
    decimalPlaces: 2,
  },
];

const ServiceAndSalaryData: CalculationRowData[] = [
  { title: "Calculation description:", property: "description" },
  { title: "Service adjustment" },
  {
    title: "Before 8/1/1983:",
    groupId: "pre-83",
    groupFilter: (c) => !!c?.dateOfMembership?.isBefore("01/01/1983"),
    getValue: (c) =>
      !!c?.dateOfMembership?.isBefore("01/01/1983") ? (
        <>
          <b>Years: </b>
          {formatNumber(c?.serviceAdjustmentPre83Years, 0)}&nbsp;
          <b>Months: </b>
          {formatNumber(c?.serviceAdjustmentPre83Months, 0)}&nbsp;
          <b>Days: </b>
          {formatNumber(c?.serviceAdjustmentPre83Days, 0)}&nbsp;
          <b>Hours: </b>
          {formatNumber(c?.serviceAdjustmentPre83Hours, 1)}
        </>
      ) : undefined,
  },
  {
    title: "After 8/1/1983:",
    getValue: (c) => (
      <>
        <b>Years: </b>
        {formatNumber(c?.serviceAdjustmentPost83Years, 0)}&nbsp;
        <b>Months: </b>
        {formatNumber(c?.serviceAdjustmentPost83Months, 0)}&nbsp;
        <b>Days: </b>
        {formatNumber(c?.serviceAdjustmentPost83Days, 0)}&nbsp;
        <b>Hours: </b>
        {formatNumber(c?.serviceAdjustmentPost83Hours, 1)}
      </>
    ),
  },
  { title: "Portability" },
  {
    title: "Portability?:",
    getValue: (c) => toYesNo(c?.portability),
  },
  {
    title: "Portability system:",
    getValue: (c) => (isYes(c?.portability) ? c?.portabilitySystem : undefined),
    groupId: "portability",
    groupFilter: (c) => isYes(c?.portability),
  },
  {
    title: "Portability after 1/1/1997:",
    getValue: (c) =>
      isYes(c?.portability)
        ? toYesNo(c?.portabilityOfServicePost97)
        : undefined,
    groupId: "portability",
  },
  {
    title: "Portability monthly highest average salary:",
    getValue: (c) => (isYes(c?.portability) ? c?.portabilityFae : undefined),
    height: 4,
    groupId: "portability",
    format: "dollar",
    decimalPlaces: 2,
  },
  {
    title: "Portability service years:",
    getValue: (c) =>
      isYes(c?.portability) ? c?.portabilityService : undefined,
    groupId: "portability",
    decimalPlaces: 5,
  },
  { title: "Salary" },
  {
    title: "Current monthly salary:",
    property: "currentGrossPayPerPeriod",
    format: "dollar",
    decimalPlaces: 2,
  },
  {
    title: "Assumed annual raises:",
    property: "salaryInflation",
    format: "percentage",
  },
];

const BenefitElectionsData: CalculationRowData[] = [
  { title: "Calculation description:", property: "description" },
  {
    title: "Annuitant information",
  },
  { title: "Annuitant type:", property: "annuitantType" },
  {
    title: "Annuitant date of birth:",
    groupId: "annuitant",
    getValue: (c) =>
      c?.annuitantType !== "No Annuitant" ? c?.annuitantDateOfBirth : undefined,
    groupFilter: (c) => c.annuitantType !== "No Annuitant",
  },
  { title: "Benefit form" },
  {
    title: "Benefit option:",
    getValue: (c) =>
      c?.benefitOptions?.find((bo) => bo.value === c.benefitOption)?.name ??
      "All Options",
    height: 4,
  },
  {
    title: "Social security adjustments",
    groupId: "social-security",
    groupFilter: (c) => c?.ssAdjustment === "Yes",
  },
  {
    title: "Social security benefit at age 62:",
    groupId: "social-security",
    getValue: (c) => (c?.ssAdjustment === "Yes" ? c?.ssAtAge62 : undefined),
    height: 4,
  },
  { title: "Service purchase" },
  {
    title: "Is service purchase available?:",
    property: "results.servicePurchaseAvailable",
    height: 4,
  },
  {
    title: "Years of service purchase needed to retire:",
    height: 4,
    decimalPlaces: 5,
    getValue: (c) =>
      c?.results?.servicePurchaseAvailable === "Yes"
        ? c.results.yearsNeededToRetire
        : undefined,
    groupId: "service-purchase",
    groupFilter: (c) => c?.results?.servicePurchaseAvailable === "Yes",
  },
  {
    title: "Maximum available years of service purchase:",
    getValue: (c) =>
      c?.results?.servicePurchaseAvailable === "Yes"
        ? c.results.maxEligibleServicePurchase
        : undefined,
    height: 4,
    decimalPlaces: 5,
    groupId: "service-purchase",
  },
  {
    title: "Are you purchasing additional service?:",
    getValue: (c) =>
      c?.results?.servicePurchaseAvailable === "Yes"
        ? c.servicePurchaseSelected
        : undefined,
    height: 4,
    groupId: "service-purchase",
  },
  {
    title: "Years of service to purchase:",
    getValue: (c) =>
      c?.results?.servicePurchaseAvailable === "Yes"
        ? c.amountOfServiceToPurchase
        : undefined,
    decimalPlaces: 5,
    groupId: "service-purchase",
  },
];

const ResultsData: CalculationRowData[] = [
  { title: "Basic information" },
  { title: "Calculation description:", property: "description" },
  { title: "SAP number:", property: "employeeNumber" },
  { title: "Name:", getValue: (c) => `${c?.firstName} ${c?.lastName}` },
  { title: "Membership date:", property: "dateOfMembership" },
  { title: "Termination date:", property: "lastDayOfEmployment" },
  { title: "Retirement date:", property: "dateOfRetirement" },
  { title: "Member birth date:", property: "dateOfBirth" },
  {
    title: "Annuitant birth date:",
    getValue: (c) =>
      c?.annuitantType !== "No Annuitant" ? c?.annuitantDateOfBirth : undefined,
    groupId: "adob",
    groupFilter: (c) => c?.annuitantType !== "No Annuitant",
  },
  {
    title: "Death beneficiary birth date:",
    getValue: (c) =>
      hasBeneficiary(c) ? dateToString(c?.beneficiaryDateOfBirth) : undefined,
    groupId: "dbdob",
    groupFilter: hasBeneficiary,
  },
  { title: "Gender:", property: "gender", show: (c, isAdmin) => isAdmin },
  {
    title: "Member age:",
    property: "results.ageOfRetirement",
    decimalPlaces: 2,
  },
  {
    title: "Annuitant age:",
    groupId: "adob",
    getValue: (c) =>
      c?.annuitantType !== "No Annuitant"
        ? c?.results?.annuitantAge
        : undefined,
    decimalPlaces: 2,
  },
  {
    title: "Death beneficiary age:",
    property: "results.deathBeneficiaryAge",
    groupId: "dbdob",
    getValue: (c) =>
      hasBeneficiary(c) ? c?.results?.deathBeneficiaryAge : undefined,
    decimalPlaces: 2,
  },
  { title: "Contributions" },
  {
    title: "Total member contributions:",
    property: "results.totalMemberContributions",
    format: "dollar",
    decimalPlaces: 2,
  },
  {
    title: "Total benefit contributions:",
    property: "results.totalBenefitContributions",
    format: "dollar",
    decimalPlaces: 2,
  },
  {
    title: "Additional pre-83 contributions:",
    format: "dollar",
    decimalPlaces: 2,
    getValue: (c) =>
      !!c?.dateOfMembership?.isBefore("01/01/1983")
        ? c?.results?.pre83ExtraContributions
        : undefined,
    groupFilter: (c) => !!c?.dateOfMembership?.isBefore("01/01/1983"),
    groupId: "pre-83",
  },
  {
    title: "Additional post-83 contributions:",
    property: "results.post83ExtraContributions",
    format: "dollar",
    decimalPlaces: 2,
  },
  {
    title: "Non-taxable contributions:",
    property: "results.nonTaxableContributions",
    format: "dollar",
    decimalPlaces: 2,
  },
  { title: "Service and salary" },
  {
    title: "Average final salary:",
    property: "results.averageFinalSalary",
    format: "dollar",
    decimalPlaces: 2,
  },
  { title: "TERS service:", property: "results.tersService" },
  {
    title: "Portability",
    groupId: "portability",
    groupFilter: (c) => c?.portability === "Y",
  },
  {
    title: "Portability average salary:",
    groupId: "portability",
    format: "dollar",
    decimalPlaces: 2,
    getValue: (c) => (c?.portability === "Y" ? c.portabilityFae : undefined),
  },
  {
    title: "Portability service:",
    groupId: "portability",
    decimalPlaces: 5,
    getValue: (c) =>
      c?.portability === "Y" ? c.portabilityService : undefined,
  },
  { title: "IRS tests" },
  {
    title: "Benefit amount may exceed 415 limit:",
    getValue: (c) => toYesNo(c?.results?.flag415Limit),
  },
  {
    title: "Benefit amount may exceed 401(a)(17) limit:",
    getValue: (c) => toYesNo(c?.results?.flag401Limit),
    height: 4,
  },
  {
    title: "Service Purchase",
    groupId: "servicePurchase",
    groupFilter: (c) => !!(c?.servicePurchaseSelected === "Yes"),
  },
  {
    title: "Service purchase years:",
    groupId: "servicePurchase",
    decimalPlaces: 5,
    getValue: (c) =>
      c?.servicePurchaseSelected === "Yes"
        ? c?.amountOfServiceToPurchase
        : undefined,
  },
  {
    title: "Service purchase amount:",
    groupId: "servicePurchase",
    decimalPlaces: 2,
    getValue: (c) =>
      c?.servicePurchaseSelected === "Yes"
        ? c?.results!.servicePurchaseAmount
        : undefined,
  },
  {
    title: "Social security adjustments",
    groupId: "social-security",
    groupFilter: (c) => c?.ssAdjustment === "Yes",
  },
  {
    title: "Social security benefit at age 62:",
    groupId: "social-security",
    getValue: (c) => (c?.ssAdjustment === "Yes" ? c?.ssAtAge62 : undefined),
  },
  { title: "Benefits" },
  {
    title: "Benefits election:",
    getValue: (c) =>
      c?.benefitOptions?.find((bo) => bo.value === c.benefitOption)?.name ??
      "Show all options",
  },
  {
    title: "Option description:",
    getValue: (c, u) => {
      const benefitOption = c?.benefitOptions?.find(
        (bo) => bo.value === c.benefitOption
      );
      const isEligible = c?.results?.isEligibleForRetirement === "Y";
      if (isEligible) {
        return benefitOption ? (
          <BenefitsDescription option={benefitOption} />
        ) : (
          filterUserBenefitOptions(c?.benefitOptions, u)?.map((bo, index) => (
            <BenefitsDescription key={index} option={bo} />
          ))
        );
      }
      return "You do not have enough service to retire. If available, you can purchase up to 5 years of additional service under the 'Benefit Elections' screen.";
    },
    height: -1,
  },
];

const useCalculationDialogStyles = makeStyles((theme) => ({
  itemBold: {
    fontWeight: 700,
  },
  itemLine: {
    borderTop: `3px solid ${Colors.Steel}`,
  },
  content: {
    width: "1300px",
  },
  paper: {
    padding: ".5rem",
    boxShadow: "none",
  },
  estimatePaper: {
    borderRadius: ".2rem",
    border: "#dfdfe08f",
    borderStyle: "solid",
  },
}));

const Row: FC<{
  pos?: number;
  calculation?: Calculation;
  header: boolean;
  isAdmin: boolean;
  data: CalculationRowData[];
}> = ({ calculation, data, header, pos, isAdmin }) => {
  const classes = useCalculationDialogStyles();
  const getProperty = (property: string) => {
    let obj = calculation as any;
    property.split(".").forEach((m) => (obj = obj?.[m]));
    return obj;
  };

  const getValue = (
    title: string,
    property?: string,
    getRowValue?: (
      calculation: Calculation,
      isAdmin: boolean
    ) => string | ReactNode,
    decimalPlaces?: number,
    format: "number" | "dollar" | "percentage" = "number"
  ) => {
    let value;
    if (!header && property) {
      value = getProperty(property);
    } else {
      value = getRowValue?.(calculation!, isAdmin);
    }

    if (moment.isMoment(value)) {
      value = dateToString(value);
    } else if (typeof value === "number" && decimalPlaces) {
      value = value.toLocaleString("en-US", {
        maximumFractionDigits: decimalPlaces,
        minimumFractionDigits: decimalPlaces,
      });
    }

    if (value !== undefined) {
      if (format !== "number") {
        switch (format) {
          case "dollar":
            value = `$${value}`;
            break;
          case "percentage":
            value = `${value * 100}%`;
            break;
        }
      }
    } else if (getRowValue) {
      value = "N/A";
    }

    return header ? title : value;
  };

  return (
    <Grid item xs={3} key={pos ?? "header"}>
      <Paper
        className={clsx(classes.paper, {
          [classes.estimatePaper]: !header,
        })}
      >
        <Grid item style={{ height: `3rem` }}>
          <Typography className={classes.itemBold}>
            {header ? <br /> : `Estimate ${pos}`}
          </Typography>
        </Grid>
        {data.map(
          (
            {
              title,
              property,
              height = 2.5,
              getValue: getRowValue,
              show,
              decimalPlaces,
              format,
            },
            index
          ) => {
            const value = getValue(
              title,
              property,
              getRowValue,
              decimalPlaces,
              format
            );
            const shouldShow = show?.(calculation!, isAdmin) ?? true;
            const heightStyle = height === -1 ? "unset" : `${height}rem`;

            if (!shouldShow) {
              return undefined;
            }

            return (
              <Grid key={index} item style={{ height: heightStyle }}>
                <Typography
                  className={clsx({
                    [classes.itemBold]: header && !property && !getRowValue,
                    [classes.itemLine]: value === undefined && index,
                  })}
                >
                  {value ?? <br />}
                </Typography>
              </Grid>
            );
          }
        )}
      </Paper>
    </Grid>
  );
};

export interface CalculationDialogProps {
  page: "BasicInformation" | string;
}

const CalculationDialog: FC<CalculationDialogProps> = observer(({ page }) => {
  const [open, setOpen] = React.useState(false);
  const classes = useCalculationDialogStyles();
  const rootContext = useContext(RootContext);
  const calculationContext = useContext(CalculationContext);
  const isAdmin = isUserAdmin(rootContext.user);
  const calculations = calculationContext?.calculations
    ?.filter((c) => !c.isRepayment)
    ?.slice(0, 3);

  let data: CalculationRowData[];
  switch (page) {
    case "BasicInformation":
      data = BasicInformationData;
      break;
    case "Contributions":
      data = ContributionData;
      break;
    case "ServiceAndSalary":
      data = ServiceAndSalaryData;
      break;
    case "BenefitElections":
      data = BenefitElectionsData;
      break;
    case "Results":
    default:
      data = ResultsData;
      break;
  }

  const filters = data?.filter((d) => d.groupFilter);
  filters.forEach(({ groupFilter, groupId }) => {
    if (!groupFilter || !groupId) {
      return;
    }
    if (!calculations?.some(groupFilter)) {
      data = data.filter((d) => d.groupId !== groupId);
    }
  });

  return (
    <>
      <Button variant="contained" color="gold" onClick={() => setOpen(true)}>
        Compare
      </Button>
      <Tooltip
        style={{ margin: 0, float: "right" }}
        tooltip="This allows you to compare the three calculation scenarios. Please input information in each scenario for the most useful comparison."
      />
      <Dialog
        aria-labelledby="customized-dialog-title"
        maxWidth="xl"
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => setOpen(false)}
          title="Compare Estimates"
        />
        <DialogContent dividers className={classes.content}>
          <Grid container spacing={2}>
            <Row header={true} data={data} isAdmin={isAdmin} />
            <Row
              pos={1}
              calculation={calculations![0]}
              header={false}
              data={data}
              isAdmin={isAdmin}
            />
            <Row
              pos={2}
              calculation={calculations![1]}
              header={false}
              data={data}
              isAdmin={isAdmin}
            />
            <Row
              pos={3}
              calculation={calculations![2]}
              header={false}
              data={data}
              isAdmin={isAdmin}
            />
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
});

export default CalculationDialog;
