import { autorun, makeAutoObservable } from "mobx";
export class LocalStorage {
  acceptedTerms = false;

  constructor() {
    makeAutoObservable(this);
    this._syncToLocalStorage("localStore");
  }

  private _syncToLocalStorage(name: string): void {
    const storedJson = localStorage.getItem(name);
    if (storedJson) {
      Object.assign(this, JSON.parse(storedJson));
    }

    autorun(() => {
      localStorage.setItem(
        name,
        JSON.stringify({ acceptedTerms: this.acceptedTerms })
      );
    });
  }
}
