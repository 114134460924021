import { Grid } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/styles";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { FC, useContext, useState } from "react";

import Button from "../../common/Button";
import CalculationTextField from "../../common/CalculationTextField";
import Dialog, { DialogActions, DialogContent } from "../../common/Dialog";
import {
  CalculationErrorContext,
  CalculationErrors,
} from "../../state/CalculationErrors";
import { RootContext } from "../../state/root";
export interface ContactUsDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const useStyles = makeStyles(() => ({
  grid: {
    width: " 95%",
    marginTop: "2rem",
  },
}));

const ContactUsDialog: FC<ContactUsDialogProps> =
  observer<ContactUsDialogProps>(({ open, setOpen }) => {
    const styles = useStyles();
    const rootContext = useContext(RootContext);
    const errorContext = useContext(CalculationErrorContext);
    const [result, setResult] = useState({
      name: "",
      email: "",
      telephone: "",
      message: "",
    });

    const handleSubmit = async () => {
      runInAction(() => (errorContext.validateErrors = true));
      if (errorContext.hasAnyErrors) {
        return;
      }
      await rootContext.ContactUs(
        result.name,
        result.telephone,
        result.email,
        result.message
      );
      setOpen(false);
      setResult({
        name: "",
        email: "",
        telephone: "",
        message: "",
      });
    };

    return (
      <Dialog
        setOpen={() => setOpen(false)}
        open={open}
        title="Contact us"
        maxWidth="md"
      >
        <DialogContent>
          <Typography gutterBottom>
            Please fill out the form below or call the Retirement Department at
            (253) 502-8200 during regular business hours or email &nbsp;
            <Link
              href="mailto:TERSretirement@cityoftacoma.org"
              underline="always"
            >
              TERSretirement@cityoftacoma.org
            </Link>
            .
          </Typography>
          <Grid className={styles.grid}>
            <CalculationTextField
              label="Name:"
              value={result.name}
              onChange={(value) =>
                setResult({ ...result, name: value as string })
              }
              labelWidth={5}
            />
            <CalculationTextField
              label="Email:"
              type="email"
              value={result.email}
              onChange={(value) =>
                setResult({ ...result, email: value as string })
              }
              mustHaveValue={false}
              labelWidth={5}
            />
            <CalculationTextField
              label="Telephone:"
              value={result.telephone}
              onChange={(value) =>
                setResult({ ...result, telephone: value as string })
              }
              mustHaveValue={false}
              labelWidth={5}
            />
            <CalculationTextField
              label="Message:"
              value={result.message}
              onChange={(value) =>
                setResult({ ...result, message: value as string })
              }
              labelWidth={5}
              multiline
            />
          </Grid>

          {errorContext.validateErrors && errorContext.hasAnyErrors && (
            <Alert severity="error">{errorContext.allErrorString}</Alert>
          )}

          <DialogActions>
            <Button
              onClick={handleSubmit}
              disabled={
                errorContext.validateErrors && errorContext.hasFormErrors
              }
              color="secondary"
            >
              Submit
            </Button>
            <Button onClick={() => setOpen(false)} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  });

export default (props: ContactUsDialogProps) => (
  <CalculationErrorContext.Provider value={new CalculationErrors(false)}>
    <ContactUsDialog {...props} />
  </CalculationErrorContext.Provider>
);
