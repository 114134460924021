import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import NumberFormat from "react-number-format";

import CalculationHeader from "../../common/CalculationHeader";
import CalculationSection from "../../common/CalculationSection";
import CalculationSelect, { MenuItem } from "../../common/CalculationSelect";
import CalculationTextField from "../../common/CalculationTextField";
import { CalculationContext } from "../../state/Calculation";
import { RootContext } from "../../state/root";
import { Colors } from "../../utilities/colors";
import { isUserAdmin } from "../../utilities/user";

import { CalculationPageProps } from "./CalculationPage";

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat<number> | null) => void;
  onChange: (event: { target: { value: number | undefined } }) => void;
}

const NumberFormatCustom = (props: NumberFormatCustomProps) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.floatValue,
          },
        });
      }}
      thousandSeparator
    />
  );
};

const NumberField = ({
  decimalScale,
  inputAdornmentLabel,
  max,
  ...props
}: TextFieldProps & {
  decimalScale: number;
  inputAdornmentLabel?: string;
  max?: number;
}) => {
  const isAllowed = ({ floatValue }: { floatValue: number }) =>
    floatValue === undefined || !max || Math.abs(floatValue) <= max;
  return (
    <TextField
      variant="outlined"
      InputProps={{
        inputProps: {
          min: 0,
          max,
          decimalScale,
          fixedDecimalScale: true,
          isAllowed,
        },
        startAdornment: inputAdornmentLabel && (
          <InputAdornment position="start">
            {inputAdornmentLabel}
          </InputAdornment>
        ),
        inputComponent: NumberFormatCustom as any,
      }}
      onBlur={() => {
        if (isNaN(props.value as number)) {
          props.onChange?.({ target: { value: 0 } } as any);
        }
      }}
      onKeyDown={(event) => {
        if (["+", "e"].includes(event.key)) {
          event.preventDefault();
        }
      }}
      {...props}
    />
  );
};
const useSalaryStyles = makeStyles((theme) => ({
  input: {
    width: "10rem",
    borderRadius: ".5rem",
    backgroundColor: Colors.White,
    "& input": {
      background: Colors.White,
      borderRadius: "inherit",
    },
  },
  label: {
    minWidth: "18rem",
    display: "inline-block",
  },
  labelCommon: {
    marginLeft: "16px",
    marginRight: "-11px",
  },
  center: {
    display: "inline-flex",
    alignItems: "center",
  },
}));

const useStyles = makeStyles((theme) => ({
  label: {
    minWidth: "18rem",
    display: "inline-block",
  },
  labelRightBorder: {
    marginLeft: "5px",
    marginRight: "5px",
  },
  labelCommon: {
    marginLeft: "16px",
    marginRight: "-11px",
  },
  smallNumber: {
    width: "5rem",
  },
  labelPlacementStart: {
    justifyContent: "space-between",
  },
  center: {
    display: "inline-flex",
    alignItems: "center",
  },
}));

interface SalaryRateProps {
  showHeaders?: boolean;
  rate: number;
  hourlyRate?: number;
  hourlyRateChanged: (rate: number) => void;
  longevity?: number;
  longevityChanged: (rate: number) => void;
  months?: number;
  monthsChanged: (rate: number) => void;
}

const SalaryRate: React.FC<SalaryRateProps> = ({
  showHeaders = false,
  rate,
  hourlyRate,
  hourlyRateChanged,
  longevity,
  longevityChanged,
  months,
  monthsChanged,
}) => {
  const styles = useSalaryStyles();
  return (
    <Grid item sm={12} style={{ marginLeft: "4rem", marginBottom: "1rem" }}>
      <div className={styles.center}>
        <Typography
          component="span"
          className={clsx(styles.label, styles.labelCommon)}
        >
          Rate {rate}:
        </Typography>
        <NumberField
          label={showHeaders && "Hourly rate"}
          inputAdornmentLabel="$"
          decimalScale={2}
          className={styles.input}
          value={hourlyRate}
          onChange={(e) => {
            runInAction(() => hourlyRateChanged(+e.target.value));
          }}
        />
        &nbsp;
        <NumberField
          label={showHeaders && "Longevity"}
          className={styles.input}
          decimalScale={0}
          value={longevity}
          onChange={(e) => {
            runInAction(() => longevityChanged(+e.target.value));
          }}
        />
        &nbsp;
        <NumberField
          label={showHeaders && "Months"}
          className={styles.input}
          decimalScale={1}
          value={months}
          onChange={(e) => {
            runInAction(() => monthsChanged(+e.target.value));
          }}
        />
      </div>
    </Grid>
  );
};

const SalaryProjectionToolTip = () => (
  <>
    <p>
      Benefit estimate will be based on a projection of the current monthly
      salary shown, unless the final average salary is entered. The actual
      retirement benefit calculated by the Retirement Service Staff will be
      based on the highest 24 consecutive monthly salary.
    </p>
  </>
);

const ServiceAdjustmentToolTip = () => (
  <>
    <p>
      Service Adjustment: A member shall receive service credit for hours of
      City Service for which the member is paid under the provisions of Section
      1.30.130, but in all cases excluding overtime hours.
    </p>
    <p>
      A full year of membership service will be credited for each full calendar
      year from date of membership that a member is employed as a full time
      employee, not counting periods in excess of 15 consecutive calendar days
      during which the member is in a nonpaid status. A full year of membership
      service will be credited to each other member for each 2,080 hours of City
      Service for which the member is paid under the provisions of Section
      1.30.130 (excluding overtime hours). A member forced to take dock time due
      to emergency overtime work shall not lose credit.
    </p>
    <p>
      A partial year of membership service shall be credited as earned. (Ord.
      26694 § 1; passed Sept. 12, 2000: Ord. 26118 § 1; passed Aug. 19, 1997:
      Ord. 25955 § 13; passed Sept. 24, 1996: Ord. 18805 § 1; passed Apr. 22,
      1969: Ord. 17014 § 1; passed Mar. 20, 1962)
    </p>
  </>
);

const PortabilityToolTip = () => (
  <>
    <p>
      Tacoma Municipal Code Section 1.30.890 - Agreements for Portability of
      Retirement Benefits with the State of Washington
    </p>
    <p>
      Portability: RCW Chapter 41.54 - Portability of Public Retirement Systems
      was passed in 1987 and subsequent amendments provided for portability of
      service under chapters 41.26, 41.32, 41.40, 41.44, 41.35 (effective
      September 1, 2000), and 43.43 of the Revised Code of Washington (RCW).
    </p>
    <p>
      In summary, RCW Chapter 41.54 provides for portability of public pension
      benefits between the Teachers Retirement System (TRS), Public Employees'
      Retirement System (PERS), the Statewide City Employees' Retirement System
      (SCERS), Washington State Patrol Retirement System (WSPRS), Law
      Enforcement Officers /Fire Fighters Retirement System - Plan II (LEOFF
      II), Washington School Employees' Retirement System (SERS) (effective
      September 1, 2000) and the three "first class" cities (Seattle, Spokane,
      and Tacoma).
    </p>
    <p>
      <strong>
        The term portability refers to retirement service that can be utilized
        in conjunction with another retirement system.
      </strong>
    </p>
    <p>
      Upon retirement, each retirement system will issue a check for the service
      earned at that system.
    </p>
  </>
);

const ServiceAndSalary: React.FC<CalculationPageProps> = observer(() => {
  const styles = useStyles();
  const calculationContext = useContext(CalculationContext);
  const rootContext = useContext(RootContext);
  const { currentCalculation } = calculationContext;
  const isAdmin = isUserAdmin(rootContext.user);

  useEffect(() => {
    if (currentCalculation?.portability === "N") {
      runInAction(() => {
        currentCalculation.portabilityFae = 0;
        currentCalculation.portabilityService = 0;
      });
    }
    if (currentCalculation?.dateOfMembership?.isAfter("01/01/1983")) {
      runInAction(() => {
        currentCalculation.serviceAdjustmentPre83Years = 0;
        currentCalculation.serviceAdjustmentPre83Months = 0;
        currentCalculation.serviceAdjustmentPre83Days = 0;
        currentCalculation.serviceAdjustmentPre83Hours = 0;
      });
    }
  });

  if (!currentCalculation) {
    return null;
  }

  return (
    <Grid container>
      <CalculationHeader title="Service" />
      <CalculationHeader
        title="Service adjustment"
        variant="secondary"
        tooltip={<ServiceAdjustmentToolTip />}
      />
      <CalculationSection>
        <Grid container direction="row">
          <Grid item sm={12}>
            <Typography paragraph className={styles.labelCommon}>
              Enter the total amount of time between your date of membership and
              last day of employment in which you did not work full-time.
            </Typography>
          </Grid>
          {currentCalculation?.dateOfMembership?.isBefore("01/01/1983") && (
            <Grid item sm={12}>
              <div className={styles.center}>
                <Typography
                  component="span"
                  className={clsx(styles.label, styles.labelCommon)}
                >
                  Pre 8/1/1983:
                </Typography>
                <Typography
                  component="span"
                  className={styles.labelRightBorder}
                >
                  Years
                </Typography>
                <NumberField
                  className={styles.smallNumber}
                  decimalScale={0}
                  defaultValue={0}
                  max={99}
                  value={currentCalculation.serviceAdjustmentPre83Years}
                  onChange={(e) =>
                    (currentCalculation.serviceAdjustmentPre83Years =
                      +e.target.value)
                  }
                />
                <Typography
                  component="span"
                  className={styles.labelRightBorder}
                >
                  Months
                </Typography>
                <NumberField
                  className={styles.smallNumber}
                  decimalScale={0}
                  defaultValue={0}
                  max={99}
                  value={currentCalculation.serviceAdjustmentPre83Months}
                  onChange={(e) =>
                    (currentCalculation.serviceAdjustmentPre83Months =
                      +e.target.value)
                  }
                />
                <Typography
                  component="span"
                  className={styles.labelRightBorder}
                >
                  Days
                </Typography>
                <NumberField
                  className={styles.smallNumber}
                  decimalScale={0}
                  defaultValue={0}
                  max={999}
                  value={currentCalculation.serviceAdjustmentPre83Days}
                  onChange={(e) =>
                    (currentCalculation.serviceAdjustmentPre83Days =
                      +e.target.value)
                  }
                />
                <Typography
                  component="span"
                  className={styles.labelRightBorder}
                >
                  Hours
                </Typography>
                <NumberField
                  className={styles.smallNumber}
                  decimalScale={2}
                  defaultValue={0}
                  max={9999.99}
                  value={currentCalculation.serviceAdjustmentPre83Hours}
                  onChange={(e) =>
                    (currentCalculation.serviceAdjustmentPre83Hours =
                      +e.target.value)
                  }
                />
              </div>
            </Grid>
          )}

          <Grid item sm={12}>
            <div className={styles.center}>
              <Typography
                component="span"
                className={clsx(styles.label, styles.labelCommon)}
              >
                After 8/1/1983:
              </Typography>
              <Typography component="span" className={styles.labelRightBorder}>
                Years
              </Typography>
              <NumberField
                className={styles.smallNumber}
                decimalScale={0}
                defaultValue={0}
                max={99}
                value={currentCalculation.serviceAdjustmentPost83Years}
                onChange={(e) =>
                  (currentCalculation.serviceAdjustmentPost83Years =
                    +e.target.value)
                }
              />
              <Typography component="span" className={styles.labelRightBorder}>
                Months
              </Typography>
              <NumberField
                className={styles.smallNumber}
                decimalScale={0}
                defaultValue={0}
                max={99}
                value={currentCalculation.serviceAdjustmentPost83Months}
                onChange={(e) =>
                  (currentCalculation.serviceAdjustmentPost83Months =
                    +e.target.value)
                }
              />
              <Typography component="span" className={styles.labelRightBorder}>
                Days
              </Typography>
              <NumberField
                className={styles.smallNumber}
                decimalScale={0}
                defaultValue={0}
                max={999}
                value={currentCalculation.serviceAdjustmentPost83Days}
                onChange={(e) =>
                  (currentCalculation.serviceAdjustmentPost83Days =
                    +e.target.value)
                }
              />
              <Typography component="span" className={styles.labelRightBorder}>
                Hours
              </Typography>
              <NumberField
                className={styles.smallNumber}
                decimalScale={2}
                defaultValue={0}
                max={9999.99}
                value={currentCalculation.serviceAdjustmentPost83Hours}
                onChange={(e) =>
                  (currentCalculation.serviceAdjustmentPost83Hours =
                    +e.target.value)
                }
              />
            </div>
          </Grid>
        </Grid>
      </CalculationSection>

      <CalculationHeader
        title="Portability"
        variant="secondary"
        tooltip={<PortabilityToolTip />}
      />
      <CalculationSection>
        <CalculationSelect
          label="Portability?"
          value={currentCalculation.portability}
          onChange={(value) => (currentCalculation.portability = value)}
        >
          <MenuItem value="Y">Yes</MenuItem>
          <MenuItem value="N">No</MenuItem>
        </CalculationSelect>

        {currentCalculation.portability === "Y" && (
          <>
            <CalculationSelect
              label="Portability system:"
              value={currentCalculation.portabilitySystem}
              onChange={(value) =>
                (currentCalculation.portabilitySystem = value)
              }
            >
              <MenuItem value="TRS">TRS</MenuItem>
              <MenuItem value="PERS">PERS</MenuItem>
              <MenuItem value="SCERS">SCERS</MenuItem>
              <MenuItem value="WSPRS">WSPRS</MenuItem>
              <MenuItem value="LEOFF II">LEOFF II</MenuItem>
              <MenuItem value="SERS">SERS</MenuItem>
              <MenuItem value="SEATTLE">SEATTLE</MenuItem>
              <MenuItem value="SPOKANE">SPOKANE</MenuItem>
              <MenuItem value="Multiple Systems">Multiple Systems</MenuItem>
            </CalculationSelect>
            <CalculationSelect
              label="Portability after 1/1/1997?:"
              value={currentCalculation.portabilityOfServicePost97}
              onChange={(value) =>
                (currentCalculation.portabilityOfServicePost97 = value)
              }
            >
              <MenuItem value="Y">Yes</MenuItem>
              <MenuItem value="N">No</MenuItem>
            </CalculationSelect>
            <CalculationTextField
              label="Portability monthly highest average salary:"
              format="dollar"
              value={currentCalculation.portabilityFae}
              onChange={(value) =>
                (currentCalculation.portabilityFae = value as number)
              }
              type="number"
            />
            <CalculationTextField
              label="Portability service years:"
              value={currentCalculation.portabilityService}
              onChange={(value) =>
                (currentCalculation.portabilityService = value as number)
              }
              type="number"
              maxLength={5}
            />
          </>
        )}
      </CalculationSection>

      <CalculationHeader title="Salary" />
      {currentCalculation.isProjection === "Y" && (
        <>
          <CalculationHeader
            title="Current salary information"
            variant="secondary"
          />
          <CalculationSection>
            <CalculationTextField
              label="Current monthly salary*:"
              format="dollar"
              value={currentCalculation.currentGrossPayPerPeriod}
              onChange={(value) =>
                (currentCalculation.currentGrossPayPerPeriod = value as number)
              }
              type="number"
            />
            <span style={{ margin: "1rem" }}>
              *If you plan to retire in the next 24 months or you are not
              presently employed by the City of Tacoma, enter your final average
              earnings instead of you current monthly salary to obtain a better
              estimate of your benefit.
            </span>
            <CalculationSelect
              label="Assumed annual raises:"
              value={currentCalculation.salaryInflation}
              onChange={(value) => (currentCalculation.salaryInflation = value)}
            >
              <MenuItem value="0">0%</MenuItem>
              <MenuItem value="0.01">1%</MenuItem>
              <MenuItem value="0.02">2%</MenuItem>
              <MenuItem value="0.03">3%</MenuItem>
              <MenuItem value="0.04">4%</MenuItem>
              <MenuItem value="0.05">5%</MenuItem>
            </CalculationSelect>
          </CalculationSection>
        </>
      )}
      <>
        {isAdmin && currentCalculation.isProjection === "Y" && (
          <CalculationHeader
            title="Salary projection"
            variant="secondary"
            tooltip={<SalaryProjectionToolTip />}
          />
        )}
        <CalculationSection>
          {isAdmin && currentCalculation.isProjection === "Y" && (
            <CalculationSelect
              label="Do you wish to enter pay rates, longevity pay code, and temp upgrade pay?"
              value={currentCalculation.finalSalary}
              onChange={(value) => (currentCalculation.finalSalary = value)}
            >
              <MenuItem value="Y">Yes</MenuItem>
              <MenuItem value="N">No</MenuItem>
            </CalculationSelect>
          )}
          {(currentCalculation.finalSalary === "Y" ||
            currentCalculation.isProjection === "N") && (
            <>
              <span style={{ margin: "1rem" }}>
                Enter your highest TERS salary earned over 24 consecutive
                months, broken down into each hourly rate, longevity, and months
                at that rate.
              </span>
              <Grid container direction="row">
                <SalaryRate
                  showHeaders
                  rate={1}
                  hourlyRate={currentCalculation.monthlyRate1}
                  hourlyRateChanged={(rate) =>
                    (currentCalculation.monthlyRate1 = rate)
                  }
                  longevity={currentCalculation.longevity1}
                  longevityChanged={(longevity) =>
                    (currentCalculation.longevity1 = longevity)
                  }
                  months={currentCalculation.months1}
                  monthsChanged={(months) =>
                    (currentCalculation.months1 = months)
                  }
                />
                <SalaryRate
                  rate={2}
                  hourlyRate={currentCalculation.monthlyRate2}
                  hourlyRateChanged={(rate) =>
                    (currentCalculation.monthlyRate2 = rate)
                  }
                  longevity={currentCalculation.longevity2}
                  longevityChanged={(longevity) =>
                    (currentCalculation.longevity2 = longevity)
                  }
                  months={currentCalculation.months2}
                  monthsChanged={(months) =>
                    (currentCalculation.months2 = months)
                  }
                />
                <SalaryRate
                  rate={3}
                  hourlyRate={currentCalculation.monthlyRate3}
                  hourlyRateChanged={(rate) =>
                    (currentCalculation.monthlyRate3 = rate)
                  }
                  longevity={currentCalculation.longevity3}
                  longevityChanged={(longevity) =>
                    (currentCalculation.longevity3 = longevity)
                  }
                  months={currentCalculation.months3}
                  monthsChanged={(months) =>
                    (currentCalculation.months3 = months)
                  }
                />
                <SalaryRate
                  rate={4}
                  hourlyRate={currentCalculation.monthlyRate4}
                  hourlyRateChanged={(rate) =>
                    (currentCalculation.monthlyRate4 = rate)
                  }
                  longevity={currentCalculation.longevity4}
                  longevityChanged={(longevity) =>
                    (currentCalculation.longevity4 = longevity)
                  }
                  months={currentCalculation.months4}
                  monthsChanged={(months) =>
                    (currentCalculation.months4 = months)
                  }
                />
                <SalaryRate
                  rate={5}
                  hourlyRate={currentCalculation.monthlyRate5}
                  hourlyRateChanged={(rate) =>
                    (currentCalculation.monthlyRate5 = rate)
                  }
                  longevity={currentCalculation.longevity5}
                  longevityChanged={(longevity) =>
                    (currentCalculation.longevity5 = longevity)
                  }
                  months={currentCalculation.months5}
                  monthsChanged={(months) =>
                    (currentCalculation.months5 = months)
                  }
                />
                <SalaryRate
                  rate={6}
                  hourlyRate={currentCalculation.monthlyRate6}
                  hourlyRateChanged={(rate) =>
                    (currentCalculation.monthlyRate6 = rate)
                  }
                  longevity={currentCalculation.longevity6}
                  longevityChanged={(longevity) =>
                    (currentCalculation.longevity6 = longevity)
                  }
                  months={currentCalculation.months6}
                  monthsChanged={(months) =>
                    (currentCalculation.months6 = months)
                  }
                />
                <SalaryRate
                  rate={7}
                  hourlyRate={currentCalculation.monthlyRate7}
                  hourlyRateChanged={(rate) =>
                    (currentCalculation.monthlyRate7 = rate)
                  }
                  longevity={currentCalculation.longevity7}
                  longevityChanged={(longevity) =>
                    (currentCalculation.longevity7 = longevity)
                  }
                  months={currentCalculation.months7}
                  monthsChanged={(months) =>
                    (currentCalculation.months7 = months)
                  }
                />
                <SalaryRate
                  rate={8}
                  hourlyRate={currentCalculation.monthlyRate8}
                  hourlyRateChanged={(rate) =>
                    (currentCalculation.monthlyRate8 = rate)
                  }
                  longevity={currentCalculation.longevity8}
                  longevityChanged={(longevity) =>
                    (currentCalculation.longevity8 = longevity)
                  }
                  months={currentCalculation.months8}
                  monthsChanged={(months) =>
                    (currentCalculation.months8 = months)
                  }
                />
              </Grid>
              <CalculationHeader
                title="Temporary upgrade"
                variant="secondary"
              />
              <CalculationSection>
                <CalculationTextField
                  label="Additional earnings:"
                  value={currentCalculation.code9Time}
                  onChange={(value) =>
                    (currentCalculation.code9Time = value as number)
                  }
                  type="number"
                  format="dollar"
                />
              </CalculationSection>
            </>
          )}
        </CalculationSection>
      </>
    </Grid>
  );
});

export default ServiceAndSalary;
