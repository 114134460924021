import { Button, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/styles/makeStyles";
import { observer } from "mobx-react-lite";
import React, { FC, useContext, useEffect } from "react";
import { useHistory } from "react-router";

import CalculationHeader from "../../common/CalculationHeader";
import CalculationLabel from "../../common/CalculationLabel";
import CalculationSection from "../../common/CalculationSection";
import { Calculation, CalculationContext } from "../../state/Calculation";
import { RootContext } from "../../state/root";
import { isUserAdmin } from "../../utilities/user";

import HomeAdmin from "./HomeAdmin";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "60rem",
    minWidth: "40rem",
  },
  description: {
    margin: "1rem",
  },
  descriptionSpacing: {
    marginBottom: "1.5rem",
  },
}));

const useRowStyles = makeStyles((theme) => ({
  button: {
    paddingLeft: "2rem",
    paddingTop: ".05rem",
    "& button": {
      height: "2.2rem",
    },
  },

  label: {
    flexGrow: 1,
  },
}));

interface CalculationRowProps {
  calculation: Calculation;
  index: number;
  onSelect: () => void;
}

const CalculationRow: FC<CalculationRowProps> = observer(
  ({ calculation, index, onSelect }) => {
    const styles = useRowStyles();
    return (
      <Grid item container direction="row">
        <Grid item className={styles.label}>
          <CalculationLabel
            labelWidth={6}
            label={`Estimate ${index}:`}
            value={calculation.description}
          />
        </Grid>
        <Grid item className={styles.button}>
          <Button variant="contained" color="primary" onClick={onSelect}>
            Select
          </Button>
        </Grid>
      </Grid>
    );
  }
);

const Home = observer(() => {
  const rootContext = useContext(RootContext);
  const calculationContext = useContext(CalculationContext);
  const history = useHistory<string[]>();
  const styles = useStyles();
  const { user } = rootContext;

  useEffect(() => {
    if (!calculationContext.calculations) {
      // tslint:disable-next-line: no-floating-promises
      calculationContext.load();
    }
  }, [calculationContext]);

  if (!calculationContext.calculations) {
    return null;
  }

  const handleSelect = (calculation: Calculation) => {
    calculationContext.currentCalculation = calculation;
    history.push(`/Calculation/`);
  };

  if (isUserAdmin(user)) {
    return <HomeAdmin />;
  }

  const calculations = calculationContext.calculations.filter(
    (c) => !c.isRepayment
  );

  return (
    <Grid container className={styles.container}>
      <CalculationHeader
        title={`Welcome ${user?.firstName}`}
        variant="secondary"
      />
      <Grid item className={styles.description}>
        <Typography className={styles.descriptionSpacing}>
          This website will calculate your Tacoma Employees' Retirement System
          (TERS) benefit. You will be able to save three different estimates.
          Some data is provided by the TERS office and cannot be overridden.
        </Typography>
        <Typography className={styles.descriptionSpacing}>
          Please select one of the estimates below to get started. Update the
          input box alongside the estimate number to give your estimate a name.
          Example: Age 60.
        </Typography>
      </Grid>
      <CalculationSection>
        {calculations.map((c, index) => (
          <CalculationRow
            key={index}
            calculation={c}
            index={index + 1}
            onSelect={() => handleSelect(c)}
          />
        ))}
      </CalculationSection>
    </Grid>
  );
});

export default Home;
