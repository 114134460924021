import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { FC } from "react";

import { Colors } from "../utilities/colors";

import { Tooltip } from "./Tooltip";

const useStyles = makeStyles((theme) => ({
  centered: {
    textAlign: "center",
    display: "inline-block !important",
    marginTop: "0.5rem !important",
    marginBottom: "0rem !important",
  },
  formLabel: {
    display: "flex",
    margin: "1rem",
    color: "black",
    height: "1rem",
    width: "100%",
    fontWeight: "bold",
  },
  primary: {
    color: Colors.MillimanBlue,
  },
  secondary: {
    color: Colors.Black,
  },
}));

interface CalculationHeaderProps {
  title: string;
  tooltip?: React.ReactChild | React.ReactFragment | React.ReactPortal;
  variant?: "primary" | "secondary";
  centered?: boolean;
}

const CalculationHeader: FC<CalculationHeaderProps> = ({
  title,
  tooltip,
  variant = "primary",
  centered = false,
}) => {
  const styles = useStyles();

  return (
    <FormLabel
      className={clsx(styles.formLabel, {
        [styles.primary]: variant === "primary",
        [styles.secondary]: variant === "secondary",
        [styles.centered]: centered,
      })}
    >
      {title}
      <Tooltip tooltip={tooltip} />
    </FormLabel>
  );
};

export default CalculationHeader;
