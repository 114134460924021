import Button, {
  ButtonProps as ButtonPropsBase,
} from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

import { Colors } from "../utilities/colors";

type ColorType =
  | "inherit"
  | "primary"
  | "secondary"
  | "default"
  | "gold"
  | "red"
  | "slate"
  | "green";
interface ButtonProps extends Omit<ButtonPropsBase, "color"> {
  color: ColorType;
}

const useStyles = makeStyles((theme) => ({
  gold: {
    backgroundColor: Colors.Gold,
    "&:hover": {
      backgroundColor: Colors.GoldHover,
    },
  },
  red: {
    backgroundColor: Colors.Red,
    "&:hover": {
      backgroundColor: Colors.RedHover,
    },
  },
  slate: {
    backgroundColor: Colors.Slate,
    "&:hover": {
      backgroundColor: Colors.SlateHover,
    },
  },
  green: {
    backgroundColor: Colors.MillimanGreen,
    "&:hover": {
      backgroundColor: Colors.MillimanGreenHover,
    },
  },
}));

export default ({
  color: colorInitial,
  variant = "contained",
  ...props
}: ButtonProps) => {
  const styles = useStyles();
  let containedPrimary: string | undefined;
  let color = colorInitial;
  switch (colorInitial) {
    case "gold":
      containedPrimary = styles.gold;
      color = "primary";
      break;
    case "red":
      containedPrimary = styles.red;
      color = "primary";
      break;
    case "slate":
      containedPrimary = styles.slate;
      color = "primary";
      break;
    case "green":
      containedPrimary = styles.green;
      color = "primary";
      break;
    default:
      containedPrimary = undefined;
      break;
  }

  return (
    <Button
      classes={{
        containedPrimary,
      }}
      variant={variant}
      color={color as any}
      {...props}
    />
  );
};
