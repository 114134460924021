import Grid, { GridProps } from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { FC } from "react";

import { Colors } from "../utilities/colors";

const useStyles = makeStyles((theme) => ({
  grid: {
    backgroundColor: Colors.CalculationsBackground,
    padding: "2rem",
    borderRadius: ".2rem",
  },
}));

export interface CCalculationSectionProps extends GridProps {}

const CalculationSection: FC<CCalculationSectionProps> = ({
  className,
  children,
  ...props
}) => {
  const styles = useStyles();
  return (
    <Grid
      container
      item
      sm={12}
      className={clsx(styles.grid, className)}
      {...props}
    >
      {children}
    </Grid>
  );
};

export default CalculationSection;
