import { action, makeObservable, observable } from "mobx";
import React from "react";

class GuardStore {
  @observable
  showGuardDialog?: Promise<boolean>;

  @observable
  resolveGuard?: (value: boolean) => void;

  constructor() {
    makeObservable(this);
  }

  @action
  showGuard(): Promise<boolean> {
    return (this.showGuardDialog = new Promise<boolean>((resolve) => {
      this.resolveGuard = resolve;
    }));
  }
}

const GuardState = new GuardStore();
const GuardContext = React.createContext(GuardState);

export { GuardContext, GuardState };
