import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import makeStyles from "@material-ui/styles/makeStyles";
import { observer } from "mobx-react-lite";
import React from "react";

import CalculationHeader from "../../common/CalculationHeader";
import { Colors } from "../../utilities/colors";

const items = [
  {
    title: "Unmodified Option",
    content: [
      "The maximum allowance you can receive is the unmodified monthly form of payment.",
    ],
  },
  {
    title: "Options",
    content: [
      "The following options provide for a death benefit under which your estate or your named beneficiary may receive a payment following your death. Because a death benefit is payable, the monthly amount of your retirement allowance will be less under these options. The total financial value of each option, however, remains the same (i.e. the options are actuarially equivalent).",
      "It is important to update your beneficiary form so the monies may be paid properly. Under Options A, B, and C, you may name a beneficiary to receive a death benefit. If you want to benefit a person under age 18, it is recommended you do so through a trust to avoid the requirement of a legal guardianship. Please consult with your lawyer. You may select an optional form of benefit within the 30-day period preceding your retirement date. Your election will be irrevocable.",
    ],
  },
  {
    title: "Option A",
    content: [
      "Provides that at your death your estate or beneficiary will receive a lump sum refund of the difference between your total accumulated contributions and interest at the date of retirement and the annuity payments you have received since retirement.",
      "Annuity payment refers to that portion of the retirement benefit that is considered payable from the member’s accumulated contributions.",
    ],
  },
  {
    title: "Option B",
    content: [
      "Provides that at your death your estate or beneficiary will continue to receive monthly annuity payments until the total of them equals your total accumulated contributions and interest at the date of retirement.",
      "Annuity payment refers to that portion of the retirement benefit that is considered payable from the member’s accumulated contributions.",
    ],
  },
  {
    title: "Option C",
    content: [
      "Provides for the payment of a retirement allowance for a guaranteed period of years. If you live beyond the guaranteed period, the allowance is continued to you for life, but if you die before the expiration of the period, the allowance will be continued to your estate or beneficiary for the balance of the time. You have an option of five or ten years for the guaranteed period.",
    ],
  },
  {
    title: "Option D",
    content: [
      "(See Pop-Up provision) (See Divorce Pop-Up provision).",
      "Provides a modified monthly allowance for life and after your death your spouse or domestic partner at the time of retirement will receive one-half of this amount each month for life.",
    ],
  },
  {
    title: "Option E",
    content: [
      "(See Pop-Up provision) (See Divorce Pop-Up provision).",
      "Provides a modified monthly allowance for life and after your death your spouse or domestic partner at the time of retirement will receive the same monthly allowance for life.",
    ],
  },
  {
    title: "Option F",
    content: [
      "(See Pop-Up provision)",
      "If a member at the time of retirement has no spouse or domestic partner, a modified monthly allowance for life is provided and after death, the beneficiary will receive one-half of this amount each month for life or will receive the same monthly allowance for life.",
    ],
  },
  {
    title: "Pop-Up provision",
    content: [
      "A member retiring on or after 01/01/1999 electing Option D or E, if the spouse or domestic partner dies before the retiree, the member’s benefit is increased to the Unmodified retirement allowance. This same benefit is provided for Option F as well.",
    ],
  },
  {
    title: "Divorce Pop-Up provision",
    content: [
      "A member retiring on or after 01/01/2008, electing Option D or E and divorced thereafter and has satisfied Section 1.30.690.C of the Tacoma Municipal Code, the benefit payable to the member effective as of the first day of the month following the entry of the Order shall be increased to the Unmodified retirement allowance. Provided further, that in the event the member is divorced and later remarries, within three months of remarriage the member will have the option to irrevocably designate the member’s new spouse or domestic partner as the wife or husband under Options D or E, subject to the same conditions as provided in Options D or E for a member who remarries after the death of a husband or wife.",
    ],
  },
  {
    title: "Social Security modification",
    content: [
      "A member retiring from service, who has not reached the age of 62, may elect to receive an additional pension allowance equal to a portion of their official projected Social Security benefit at age 62. This additional amount will continue until they reach the age of 62. At age 62, the member’s pension will be permanently reduced by the official projected Social Security benefit at age 62. The portion is calculated such that the total benefit paid from TERS is actuarially equivalent to the benefit paid from TERS had this option not been selected. If the member dies leaving a beneficiary entitled to an allowance, the allowance shall be the same as the beneficiary would have received had the member not selected this modification.",
    ],
  },
];

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "60rem",
    minWidth: "40rem",
  },
  description: {
    margin: "1rem",
  },
  accordion: {
    margin: "16px 0",
    backgroundColor: Colors.CalculationsBackground,
  },
  accordionExpanded: {
    minHeight: "unset",
  },
  accordionSummary: {
    backgroundColor: Colors.MillimanBlue,
    color: Colors.White,
    minHeight: "unset !important",
    transition: "unset",
  },
  accordionContent: {
    margin: "12px !important",
    minHeight: "unset !important",
  },
  accordionDetails: {
    flexDirection: "column",
  },
}));

const Home = observer(() => {
  const styles = useStyles();
  return (
    <Grid container className={styles.container}>
      <CalculationHeader
        title="Retirement and disability benefit options"
        variant="secondary"
      />
      <Grid item className={styles.description}>
        {items.map((item, index) => (
          <Accordion
            key={index}
            classes={{
              root: styles.accordion,
              expanded: styles.accordionExpanded,
            }}
            square
          >
            <AccordionSummary
              classes={{
                expanded: styles.accordionSummary,
                content: styles.accordionContent,
              }}
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>{item.title}</Typography>
            </AccordionSummary>
            <AccordionDetails classes={{ root: styles.accordionDetails }}>
              {item.content.map((item, index) => (
                <Typography display="block" gutterBottom key={index}>
                  {item}
                </Typography>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Grid>
  );
});

export default Home;
