import "@fontsource/roboto";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { Route, Switch, useHistory } from "react-router";

import TimeoutDialog from "./common/TimeoutDialog";
import BulkUpdate from "./components/Admin/BulkUpdate";
import CreateCalculation from "./components/Admin/CreateCalculation";
import CreateMember from "./components/Admin/CreateMember";
import ManageMember from "./components/Admin/ManageMember";
import Spreadsheet from "./components/Admin/Spreadsheet";
import BenefitOptions from "./components/BenefitOptions";
import Calculation from "./components/Calculation";
import { Export } from "./components/Calculation/Export";
import Home from "./components/Home";
import Login, { ForgotPassword } from "./components/Login";
import ResetPassword from "./components/Login/ResetPassword";
import UpdatePassword from "./components/Login/UpdatePassword";
import RepaymentOfContributions from "./components/RepaymentOfContributions";
import { Layout } from "./Layout";
import { AlertContext } from "./state/Alert";
import { RootContext } from "./state/root";
import { User } from "./state/User";

export default observer(() => {
  const rootContext = useContext(RootContext);
  const alertContext = useContext(AlertContext);
  const history = useHistory<string[]>();
  const alert = alertContext.currentAlert;

  useEffect(() => {
    // tslint:disable-next-line: no-floating-promises
    (async () => {
      if (!rootContext.user) {
        const user = await new User().load();
        if (user) {
          runInAction(() => {
            rootContext.user = user;
          });
        }
      }
    })();

    if (rootContext.user?.needsPasswordChange) {
      history.push(`/Login/UpdatePassword`);
    }
  }, [rootContext, rootContext.user, history]);

  return (
    <Layout>
      <>
        {rootContext.user && !rootContext.user.needsPasswordChange ? (
          <>
            <TimeoutDialog />
            <Switch>
              <Route
                path="/BackupCalculations/:calculationId"
                component={Export}
              />
              <Route path="/Admin/ManageMember" component={ManageMember} />
              <Route path="/Admin/Spreadsheet" component={Spreadsheet} />
              <Route
                path="/Admin/CreateCalculation"
                component={CreateCalculation}
              />
              <Route path="/Admin/CreateMember" component={CreateMember} />
              <Route path="/Admin/BulkUpdate" component={BulkUpdate} />
              <Route
                path={[`/Calculation/:tabId`, "/Calculation"]}
                component={Calculation}
              />
              <Route
                exact
                path="/RepaymentOfContributions"
                component={RepaymentOfContributions}
              />
              <Route exact path="/BenefitOptions" component={BenefitOptions} />
              <Route path={["/", "/Home"]} component={Home} />
            </Switch>
          </>
        ) : (
          <Switch>
            <Route
              exact
              path="/Login/ForgotPassword"
              component={ForgotPassword}
            />
            <Route
              exact
              path="/Login/ResetPassword"
              component={ResetPassword}
            />
            <Route
              exact
              path="/Login/UpdatePassword"
              component={UpdatePassword}
            />
            <Route component={Login} />
          </Switch>
        )}
        {alert && (
          <Snackbar
            anchorOrigin={{
              horizontal: "center",
              vertical: "top",
            }}
            open={true}
            TransitionComponent={Slide}
            autoHideDuration={6000}
            onClose={() => alertContext.showAlert()}
          >
            <Alert
              onClose={() => alertContext.showAlert()}
              severity={alert.type}
            >
              {alert.content}
            </Alert>
          </Snackbar>
        )}
      </>
    </Layout>
  );
});
