import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputBase from "@material-ui/core/InputBase";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import clsx from "clsx";
import React, { FC, ReactNode } from "react";

import { Colors } from "../utilities/colors";

const useStyles = makeStyles<Theme, CalculationLabelProps>((theme) => ({
  label: {
    minWidth: ({ labelWidth }) => `${labelWidth}rem`,
    maxWidth: ({ labelWidth }) => `${labelWidth}rem`,
    color: `${Colors.Black} !important`,
    display: "flex",
    marginRight: "0.5rem",
  },
  root: {
    paddingTop: "0 !important",
    paddingBottom: ".5rem !important",
  },
  labelPlacementStart: {
    justifyContent: "space-between",
    paddingTop: ".5rem",
    paddingBottom: ".5rem",
    alignItems: "baseline",
    width: "100%",
  },
  inputRight: {
    textAlign: "right",
  },
}));

export interface CalculationLabelProps {
  className?: string;
  label: string;
  value: string | number | undefined;
  labelWidth?: number;
  tooltip?: ReactNode;
  rightAlign?: boolean;
  format?: "number" | "dollar" | "percentage";
  minimumFractionDigits?: number;
}

const CalculationLabel: FC<CalculationLabelProps> = ({
  className,
  label,
  value,
  labelWidth = 17,
  tooltip,
  minimumFractionDigits = 0,
  rightAlign = false,
  format = "number",
}) => {
  const styles = useStyles({ label, value, labelWidth });
  let displayValue =
    typeof value === "number"
      ? value.toLocaleString("en-US", {
          maximumFractionDigits: minimumFractionDigits ?? 2,
          minimumFractionDigits,
        })
      : value;
  if (format !== "number") {
    switch (format) {
      case "dollar":
        displayValue = `$${displayValue}`;
        break;
      case "percentage":
        displayValue = `${displayValue}%`;
        break;
    }
  }

  return (
    <FormControlLabel
      control={
        <InputBase
          disabled
          fullWidth
          inputProps={{
            className: clsx({
              [styles.inputRight]: rightAlign,
            }),
          }}
        />
      }
      label={
        <>
          <span>{label}</span>
          {tooltip && (
            <Tooltip title={tooltip} placement="top">
              <div>
                <FontAwesomeIcon
                  icon="question-circle"
                  size="sm"
                  color={Colors.MillimanBlue}
                />
              </div>
            </Tooltip>
          )}
        </>
      }
      labelPlacement="start"
      className={className}
      classes={{
        root: styles.root,
        labelPlacementStart: styles.labelPlacementStart,
        label: styles.label,
      }}
      value={displayValue}
    />
  );
};

export default CalculationLabel;
