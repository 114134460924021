import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import Alert from "@material-ui/lab/Alert";
import { ClassNameMap } from "@material-ui/styles/withStyles";
import clsx from "clsx";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { FC, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";

import Button from "../../common/Button";
import CalculationHeader from "../../common/CalculationHeader";
import CalculationLabel from "../../common/CalculationLabel";
import CalculationTextField from "../../common/CalculationTextField";
import PasswordField from "../../common/PasswordField";
import { useId } from "../../common/useId";
import {
  CalculationErrorContext,
  CalculationErrors,
} from "../../state/CalculationErrors";
import { RootContext } from "../../state/root";
import { ErrorCode, StatusCode, User } from "../../state/User";
import { Colors } from "../../utilities/colors";

const useUserStyles = makeStyles((theme) => ({
  content: {
    cursor: "pointer",
  },
}));

interface UserInfoProps {
  state: "open" | "closed";
  user?: User;
  classes: ClassNameMap<"main" | "text" | "profileIcon" | "header">;
  setUserPanelOpen: (value: boolean) => void;
}

const UserInfo: FC<UserInfoProps> = ({
  classes,
  user,
  setUserPanelOpen,
  state,
}) => {
  const styles = useUserStyles();
  const { pathname } = useLocation();
  const inBackupCalculations = pathname.startsWith("/BackupCalculations");
  const showHeader = user && !user.needsPasswordChange && !inBackupCalculations;

  if (!user) {
    return null;
  }

  return showHeader ? (
    <Grid item className={styles.content}>
      <Grid
        item
        container
        justifyContent="space-between"
        direction="row"
        onClick={() => setUserPanelOpen(state === "closed")}
      >
        <div className={classes.profileIcon}>
          <FontAwesomeIcon icon="user" size="3x" color="white" />
        </div>
        <div>
          <Typography className={classes.text}>
            Welcome {user.firstName}
          </Typography>
          <Typography className={classes.text}>
            Employee num: {user.employeeId}
          </Typography>
        </div>
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          onClick={() => setUserPanelOpen(state === "closed")}
        >
          {state === "closed" ? (
            <ArrowDropDownIcon fontSize="medium" />
          ) : (
            <ArrowDropUpIcon fontSize="medium" />
          )}
        </IconButton>
      </Grid>
    </Grid>
  ) : null;
};

const PASSWORDS_MATCH_ERROR = "New password and confirm password do not match";
const INVALID_PASSWORD_ERROR = "The current password entered is not valid";

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginLeft: "1rem",
    marginTop: "1.5rem",
  },
  main: {
    backgroundColor: Colors.NormalBackground,
    minHeight: "5rem",
  },
  profileIcon: {
    padding: ".2rem",
    backgroundColor: Colors.MillimanBlue,
    borderRadius: ".3rem",
    marginRight: ".5rem",
    marginLeft: "1rem",
  },
  header: {
    borderBottomStyle: "solid",
    borderBottomColor: Colors.Slate,
    borderBottomWidth: "1px",
  },
  control: {
    width: "85% !important",
  },
  icon: {
    marginLeft: "15px",
    marginBottom: "10px",
    color: Colors.VibrantBlue,
  },
  text: {
    color: Colors.TextHeader,
  },
  userDrawer: {
    backgroundColor: Colors.CalculationsBackground,
    borderStyle: "none",
    maxWidth: "25rem",
    marginRight: "1rem",
  },
  userInfo: {
    marginTop: "2rem",
  },
}));

const Header = observer(() => {
  const classes = useStyles();
  const rootContext = useContext(RootContext);
  const [userPanelOpen, setUserPanelOpen] = useState(false);
  const [passwordEdit, setPasswordEdit] = useState(false);
  const [emailEdit, setEmailEdit] = useState(false);
  const { user } = rootContext;
  const [firstName, setFirstName] = useState(user?.firstName);
  const [lastName, setLastName] = useState(user?.lastName);
  const [email, setEmail] = useState(user?.email);
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const errorContext = useContext(CalculationErrorContext);
  const id = useId();

  useEffect(() => {
    setFirstName(user?.firstName);
    setLastName(user?.lastName);
    setEmail(user?.email);
    setPassword("");
    setPasswordEdit(false);
    setEmailEdit(false);
    setConfirmPassword("");
    setCurrentPassword("");
    errorContext.clear();
    errorContext.setValidateErrors(false);
  }, [errorContext, user, userPanelOpen]);

  const handleUpdate = async () => {
    errorContext.clear();
    const result = await user?.saveToServer(
      {
        firstName,
        lastName,
        email,
      },
      password,
      currentPassword
    );
    if (result?.status === StatusCode.Failed) {
      switch (result.errorCode) {
        case ErrorCode.InvalidEmail:
          errorContext.addError(
            id,
            "Email address is already associated with another user."
          );
          break;
        case ErrorCode.InvalidPassword:
          errorContext.addError(id, INVALID_PASSWORD_ERROR);
          break;
      }
    } else {
      setUserPanelOpen(false);
    }
  };

  const isDirty = emailEdit || passwordEdit;

  return (
    <React.Fragment>
      <AppBar
        position="sticky"
        elevation={0}
        className={clsx(classes.main, classes.header, "no-print")}
      >
        <Toolbar>
          <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.main}
            justifyContent="space-between"
          >
            <Grid item>
              <Typography variant="h5" className={classes.text}>
                Tacoma Employees' Retirement System
              </Typography>
            </Grid>
            <UserInfo
              state="closed"
              user={user}
              classes={classes}
              setUserPanelOpen={setUserPanelOpen}
            />
          </Grid>
        </Toolbar>
      </AppBar>

      <Drawer
        SlideProps={{ direction: "down" }}
        anchor="right"
        open={userPanelOpen}
        onClose={() => setUserPanelOpen(false)}
      >
        <div className={classes.userDrawer}>
          <UserInfo
            state="open"
            user={user}
            classes={classes}
            setUserPanelOpen={setUserPanelOpen}
          />
          <div className={classes.userInfo}>
            <CalculationHeader variant="secondary" title="User details" />
            <CalculationLabel
              labelWidth={9}
              label="First name:"
              value={firstName}
            />
            <CalculationLabel
              labelWidth={9}
              label="Last name:"
              value={lastName}
            />
            <CalculationLabel
              labelWidth={9}
              label="Employee num:"
              value={user?.employeeId ?? ""}
            />
          </div>
          <div className={classes.userInfo}>
            <CalculationHeader variant="secondary" title="Email" />
            {emailEdit ? (
              <CalculationTextField
                className={classes.control}
                labelWidth={7}
                disabled={!emailEdit}
                label="Email:"
                type="email"
                value={email}
                onChange={(value: string | number) => setEmail(value as string)}
              />
            ) : (
              <CalculationLabel
                label="Email:"
                value={email}
                labelWidth={9}
                className={classes.control}
              />
            )}
            <IconButton
              size="small"
              className={classes.icon}
              disabled={emailEdit}
              onClick={() => setEmailEdit(true)}
            >
              <FontAwesomeIcon icon="pencil-alt" />
            </IconButton>
          </div>
          <div className={classes.userInfo}>
            <CalculationHeader variant="secondary" title="Password" />
            {passwordEdit ? (
              <PasswordField
                className={classes.control}
                labelWidth={7}
                disabled={!passwordEdit}
                label="New password:"
                value={password}
                onChange={(value: string | number) =>
                  setPassword(value as string)
                }
                mustHaveValue={false}
                enforcePasswordValidation={true}
              />
            ) : (
              <CalculationLabel
                label="Password:"
                value="****************"
                labelWidth={9}
                className={classes.control}
              />
            )}
            <IconButton
              size="small"
              className={classes.icon}
              disabled={passwordEdit}
              onClick={() => setPasswordEdit(true)}
            >
              <FontAwesomeIcon icon="pencil-alt" />
            </IconButton>
            {passwordEdit && (
              <>
                <PasswordField
                  className={classes.control}
                  labelWidth={7}
                  label="Confirm:"
                  value={confirmPassword}
                  onChange={(value: string | number) =>
                    setConfirmPassword(value as string)
                  }
                  validation={(value) =>
                    value !== password ? PASSWORDS_MATCH_ERROR : undefined
                  }
                  enforcePasswordValidation={false}
                />
              </>
            )}
          </div>
          <div className={classes.userInfo}>
            {isDirty && (
              <>
                <CalculationHeader
                  variant="secondary"
                  title="Enter your password to confirm changes"
                />
                <PasswordField
                  className={classes.control}
                  labelWidth={7}
                  label="Current:"
                  value={currentPassword}
                  onChange={(value: string | number) => {
                    setCurrentPassword(value as string);
                    errorContext.removeError(id, INVALID_PASSWORD_ERROR);
                  }}
                  enforcePasswordValidation={false}
                />
              </>
            )}
          </div>

          {errorContext.validateErrors && errorContext.hasAnyErrors && (
            <Alert severity="error">{errorContext.allErrorString}</Alert>
          )}

          <Grid
            className={classes.buttons}
            container
            justifyContent="space-between"
          >
            <Button
              disabled={
                !isDirty ||
                (errorContext.validateErrors && errorContext.hasAnyErrors)
              }
              onClick={() => {
                runInAction(() => {
                  if (errorContext.hasAnyErrors) {
                    errorContext.validateErrors = true;
                    return;
                  }
                  // tslint:disable-next-line: no-floating-promises
                  handleUpdate();
                });
              }}
              color="secondary"
              variant="contained"
            >
              Update
            </Button>
            <Button
              color="default"
              variant="text"
              onClick={() => setUserPanelOpen(false)}
            >
              Cancel
            </Button>
          </Grid>
        </div>
      </Drawer>
    </React.Fragment>
  );
});

export default () => (
  <CalculationErrorContext.Provider value={new CalculationErrors(false)}>
    <Header />
  </CalculationErrorContext.Provider>
);
