import FormControlLabel from "@material-ui/core/FormControlLabel";
import MuiMenuItem, { MenuItemProps } from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { runInAction } from "mobx";
import React, { FC, ReactNode, useContext, useEffect } from "react";

import { CalculationErrorContext } from "../state/CalculationErrors";

import { useId } from "./useId";

const useMenuItemStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "#a9a9a9",
    },
  },
}));

const MenuItem: FC<MenuItemProps> = React.forwardRef(
  ({ button, ...props }, ref) => {
    const styles = useMenuItemStyles();
    return (
      <MuiMenuItem
        ref={ref}
        classes={{ root: styles.root }}
        button
        {...props}
      />
    );
  }
);

export { MenuItem };

const useStyles = makeStyles<Theme, CalculationSelectProps>((theme) => ({
  label: {
    width: "100%",
    marginBottom: ".5rem",
  },
  select: {
    flexGrow: 1,
    marginLeft: "2rem",
  },
  labelInner: {
    width: ({ labelWidth }) => `${labelWidth}rem`,
  },
  labelPlacementStart: {
    justifyContent: "space-between",
    minWidth: "15rem",
  },
  selectError: {
    "& fieldset": {
      borderColor: "red !important",
    },
  },
}));

interface CalculationSelectProps {
  value?: string;
  label: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  children?: ReactNode | ReactNode[];
  labelWidth?: number;
}

export default ({
  children,
  label,
  onChange,
  value,
  disabled = false,
  labelWidth = 15,
}: CalculationSelectProps) => {
  const id = useId();
  const styles = useStyles({ label, onChange, labelWidth });
  const calculationErrorContext = useContext(CalculationErrorContext);
  const valueError = value === undefined || value === "";

  useEffect(() => {
    if (valueError) {
      calculationErrorContext.addFormError(id, label);
    } else {
      calculationErrorContext.removeFormError(id, label);
    }
  }, [calculationErrorContext, label, valueError, id]);

  return (
    <FormControlLabel
      control={
        <Select
          className={clsx(styles.select, { [styles.selectError]: valueError })}
          variant="outlined"
          onChange={(e) =>
            runInAction(() => onChange(e.target.value as string))
          }
          disabled={disabled}
        >
          {children}
        </Select>
      }
      label={label}
      labelPlacement="start"
      className={styles.label}
      classes={{
        labelPlacementStart: styles.labelPlacementStart,
        label: styles.labelInner,
      }}
      value={value}
    />
  );
};
