import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Checkbox,
  Drawer,
  IconButton,
  Theme,
  withStyles,
} from "@material-ui/core";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { DataGrid, GridColumns } from "@material-ui/data-grid";
import ReplayIcon from "@material-ui/icons/Replay";
import Alert from "@material-ui/lab/Alert";
import makeStyles from "@material-ui/styles/makeStyles";
import { observer } from "mobx-react-lite";
import React, { FC, useContext, useEffect, useState } from "react";

import Button from "../../common/Button";
import CalculationHeader from "../../common/CalculationHeader";
import CalculationSection from "../../common/CalculationSection";
import CalculationSelect, { MenuItem } from "../../common/CalculationSelect";
import CalculationTextField from "../../common/CalculationTextField";
import Dialog from "../../common/Dialog";
import PasswordField from "../../common/PasswordField";
import { AdminContext } from "../../state/Admin";
import {
  CalculationErrorContext,
  CalculationErrors,
} from "../../state/CalculationErrors";
import { RootContext } from "../../state/root";
import { ErrorCode, StatusCode, User, UserType } from "../../state/User";
import { getUserTypeString, isUserFullAdmin } from "../../utilities/user";

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    marginTop: "1rem",
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

/**
 * some garbage code to generate a password
 */
const generatePassword = (length: number) => {
  const chars = [
    "ABCDEFGHIJKLMNOPQRSTUVWXYZ", // letters
    "abcdefghijklmnopqrstuvwxyz",
    "0123456789", // numbers
    "!@#$%^&*()", // special chars
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()", // all
  ];

  return [1, 1, 1, 1, length - 4]
    .map((len, i) =>
      Array(len)
        .fill(chars[i])
        .map((x) => x[Math.floor(Math.random() * x.length)])
        .join("")
    )
    .concat()
    .join("")
    .split("")
    .sort(() => 0.5 - Math.random())
    .join("");
};

interface ConfirmationDeleteProps {
  memberId: string;
  userId: string;
  action: string;
  disabled: boolean;
}

const ConfirmationDelete: FC<ConfirmationDeleteProps> = ({
  action,
  memberId,
  userId,
  disabled,
}) => {
  const adminContext = useContext(AdminContext);
  const [showWarning, setShowWarning] = useState(false);

  const handleDelete = async () => {
    await adminContext.deleteUser(userId);
    await adminContext.getUserList(true);
    setShowWarning(false);
  };

  return (
    <>
      <IconButton
        size="small"
        disabled={disabled}
        onClick={() => setShowWarning(true)}
      >
        <FontAwesomeIcon
          icon="trash-alt"
          color="red"
          style={{ opacity: disabled ? 0.4 : 1 }}
        />
      </IconButton>
      <Dialog
        maxWidth="sm"
        open={showWarning}
        setOpen={setShowWarning}
        title={`${action} member`}
      >
        <DialogContent>
          Are you sure you want to {action.toLowerCase()} member&nbsp;
          <b>{memberId}</b>?
          <DialogActions>
            <Button autoFocus onClick={handleDelete} color="red">
              {action}
            </Button>
            <Button
              autoFocus
              onClick={() => setShowWarning(false)}
              color="slate"
            >
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

const ConfirmationCheck: FC<{ editUser: User; user?: User }> = ({
  editUser,
  user,
}) => {
  const [showWarning, setShowWarning] = useState(false);
  const handleDeactivate = async () => {
    await editUser.saveToServer({ deactivated: !editUser.deactivated });
    setShowWarning(false);
  };
  const action = editUser.deactivated ? "Activate" : "Deactivate";
  const disabled =
    editUser.userType === "Admin" && user?.userType === "TacomaAdmin";
  return (
    <>
      <Checkbox
        checked={editUser.deactivated}
        disabled={disabled}
        onClick={() => setShowWarning(true)}
      />
      <Dialog
        maxWidth="sm"
        open={showWarning}
        setOpen={setShowWarning}
        title={`${action} member`}
      >
        <DialogContent>
          Are you sure you want to {action} member&nbsp;
          <b>{editUser.employeeId}</b>?
          <DialogActions>
            <Button autoFocus onClick={handleDeactivate} color="red">
              {action}
            </Button>
            <Button
              autoFocus
              onClick={() => setShowWarning(false)}
              color="slate"
            >
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

const useEditMemberStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "80rem",
    minWidth: "40rem",
  },
  spacing: {
    marginBottom: "1rem",
    margin: "1rem",
  },
  userPanel: {
    margin: "1rem",
    maxWidth: "30rem",
  },
  buttons: {
    marginLeft: "1rem",
    marginTop: "1.5rem",
  },
}));

interface EditMemberDrawerProps {
  editUser: User;
  onClose: () => void;
}

const EditMemberDrawer: FC<EditMemberDrawerProps> = observer(
  ({ editUser: initialUser, onClose }) => {
    const styles = useEditMemberStyles();
    const errorContext = useContext(CalculationErrorContext);
    const { user } = useContext(RootContext);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [editUser, setEditUser] = useState({
      ...initialUser._toServer(),
      isDirty: false,
    });
    const [error, setError] = useState("");

    const isDirty = password || editUser.isDirty;
    const handleUpdate = async () => {
      const result = await initialUser.saveToServer(
        editUser,
        password,
        confirmPassword
      );
      if (result.status === StatusCode.Failed) {
        switch (result.errorCode) {
          case ErrorCode.InvalidPassword:
            setError("Invalid Password");
            break;
          case ErrorCode.InvalidEmail:
            setError("Invalid Email");
            break;
        }
        return;
      }
      onClose();
    };

    return (
      <Drawer
        SlideProps={{ direction: "left" }}
        anchor="right"
        open={true}
        onClose={onClose}
      >
        <Grid className={styles.userPanel}>
          <CalculationHeader title="Edit member" variant="secondary" />
          <CalculationTextField
            label="Email:"
            value={editUser?.email ?? ""}
            labelWidth={5}
            onChange={(value) => {
              setEditUser({
                ...editUser,
                email: value as string,
                isDirty: true,
              });
            }}
            mustHaveValue={false}
            type="email"
          />
          <CalculationTextField
            label="First name:"
            value={editUser?.firstName ?? ""}
            labelWidth={5}
            onChange={(value) => {
              setEditUser({
                ...editUser,
                firstName: value as string,
                isDirty: true,
              });
            }}
            mustHaveValue={false}
          />
          <CalculationTextField
            label="Last name:"
            value={editUser?.lastName ?? ""}
            labelWidth={5}
            onChange={(value) => {
              setEditUser({
                ...editUser,
                lastName: value as string,
                isDirty: true,
              });
            }}
            mustHaveValue={false}
          />
          {isUserFullAdmin(user) && (
            <CalculationSelect
              label="Role:"
              value={editUser.userType}
              labelWidth={5}
              onChange={(value) => {
                setEditUser({
                  ...editUser,
                  userType: value as UserType,
                  isDirty: true,
                });
              }}
            >
              <MenuItem value="Admin">Admin</MenuItem>
              <MenuItem value="TacomaAdmin">Tacoma Admin</MenuItem>
              <MenuItem value="User">User</MenuItem>
            </CalculationSelect>
          )}
          <CalculationTextField
            label="Update password:"
            value={password}
            labelWidth={5}
            onChange={(value) => setPassword(value as string)}
            mustHaveValue={false}
            endAdornment={
              <IconButton onClick={() => setPassword(generatePassword(8))}>
                <ReplayIcon />
              </IconButton>
            }
          />
          {isDirty && (
            <PasswordField
              label="Admin password:"
              enforcePasswordValidation={false}
              value={confirmPassword}
              labelWidth={5}
              onChange={(value) => setConfirmPassword(value as string)}
            />
          )}
          <Grid
            className={styles.buttons}
            container
            justifyContent="space-between"
          >
            <Button
              disabled={errorContext.hasFormErrors || !isDirty}
              color="secondary"
              variant="contained"
              onClick={handleUpdate}
            >
              Update
            </Button>
            <Button color="default" variant="text" onClick={onClose}>
              Cancel
            </Button>
          </Grid>
          {error && <Alert severity="error">{error}</Alert>}
        </Grid>
      </Drawer>
    );
  }
);

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "80rem",
    minWidth: "40rem",
  },
  spacing: {
    marginBottom: "1rem",
    margin: "1rem",
  },
  userPanel: {
    margin: "1rem",
    maxWidth: "30rem",
  },
}));

const ManageMember = observer(() => {
  const styles = useStyles();
  const [editUser, setEditUser] = useState<User>();
  const adminContext = useContext(AdminContext);
  const { user } = useContext(RootContext);
  const [eeNumber, setEeNumber] = useState("");
  const [lastName, setLastName] = useState("");

  useEffect(() => {
    // tslint:disable-next-line: no-floating-promises
    adminContext.getUserList();
  }, [adminContext]);

  if (!adminContext.users) {
    return null;
  }

  const items = adminContext.users.filter((item) => {
    let match = true;
    if (eeNumber && match) {
      match =
        item.employeeId?.toLowerCase()!.includes(eeNumber.toLowerCase()) ??
        false;
    }
    if (lastName && match) {
      match =
        item.lastName?.toLowerCase()!.includes(lastName.toLowerCase()) ?? false;
    }
    return match;
  });

  const UserColumns: GridColumns = [
    {
      editable: false,
      field: "employeeId",
      headerName: "EE num",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "firstName",
      headerName: "First name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "lastName",
      headerName: "Last name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "userType",
      headerName: "Role",
      flex: 0.75,
      minWidth: 100,
      renderCell: (params) => getUserTypeString(params.row as User),
    },
    {
      editable: false,
      field: "deactivated",
      headerName: "Deactivate",
      flex: 0.75,
      minWidth: 100,
      renderCell: (params) => (
        <ConfirmationCheck editUser={params.row as User} user={user} />
      ),
    },
    {
      editable: false,
      field: "actions",
      headerName: " ",
      sortable: false,
      flex: 0.75,
      minWidth: 100,
      renderCell: (params) => {
        const disabled =
          params.row.userType === "Admin" && user?.userType === "TacomaAdmin";
        return (
          <>
            <IconButton
              size="small"
              onClick={() => setEditUser(params.row as User)}
              disabled={disabled}
              style={{ opacity: disabled ? 0.4 : 1 }}
            >
              <FontAwesomeIcon icon="pencil-alt" color="grey" />
            </IconButton>
            <ConfirmationDelete
              memberId={params.row.userName}
              userId={params.row.id}
              disabled={disabled}
              action="Delete"
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      <Grid container className={styles.container}>
        <CalculationHeader title="Manage member" variant="secondary" />
        <CalculationSection className={styles.spacing}>
          <CalculationTextField
            label="EE number:"
            value={eeNumber}
            onChange={(value) => setEeNumber(value as string)}
            mustHaveValue={false}
          />
          <CalculationTextField
            label="Last name:"
            value={lastName}
            onChange={(value) => setLastName(value as string)}
            mustHaveValue={false}
          />
        </CalculationSection>
        <Grid className={styles.spacing} item xs={12}>
          <DataGrid
            rows={items}
            columns={UserColumns}
            pageSize={10}
            getRowId={(row) => row.id}
            disableColumnMenu={true}
            autoHeight={true}
            rowsPerPageOptions={[10]}
            isCellEditable={() => false}
            isRowSelectable={() => false}
          />
        </Grid>
      </Grid>
      {!!editUser && (
        <EditMemberDrawer
          editUser={editUser}
          onClose={() => setEditUser(undefined)}
        />
      )}
    </>
  );
});

export default () => (
  <CalculationErrorContext.Provider value={new CalculationErrors()}>
    <ManageMember />
  </CalculationErrorContext.Provider>
);
