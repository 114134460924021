import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";

import { CalculationErrorContext } from "../state/CalculationErrors";
import { Colors } from "../utilities/colors";

import { TextField, validateEmail } from "./TextField";
import { useId } from "./useId";

const useStyles = makeStyles<Theme, CalculationTextFieldProps>(
  ({ props, ...theme }) => ({
    label: {
      width: "100%",
      marginBottom: ".5rem",
    },
    textField: {
      flexGrow: 1,
      marginLeft: "2rem",
    },
    textFieldRoot: {
      borderRadius: ".5rem",
      backgroundColor: Colors.White,
      "& input": {
        background: ({ disabled }) =>
          disabled ? Colors.CalculationsBackground : Colors.White,
        borderRadius: "inherit",
      },
    },
    textFieldError: {
      "& fieldset": {
        borderColor: "red !important",
      },
    },
    labelInner: {
      width: ({ labelWidth }) => `${labelWidth}rem`,
      minWidth: ({ labelWidth }) => `${labelWidth}rem`,
    },
    labelPlacementStart: {
      justifyContent: "space-between",
      minWidth: "15rem",
      alignItems: "baseline",
    },
  })
);

export interface CalculationTextFieldProps {
  id?: string;
  hasErrors?: boolean;
  className?: string;
  classes?: Partial<ReturnType<typeof useStyles>>;
  value?: string | number;
  label: string;
  type?: "number" | "text" | "password" | "email";
  onChange?: (value: string | number) => void;
  labelWidth?: number;
  mustHaveValue?: boolean;
  multiline?: boolean;
  endAdornment?: React.ReactNode;
  format?: "number" | "dollar" | "percentage";
  disabled?: boolean;
  maxLength?: number;
  validation?: (value: string | number) => string | undefined;
}

export default observer(
  ({
    id: originalId,
    hasErrors,
    className,
    classes,
    label,
    onChange,
    value,
    type = "text",
    labelWidth = 15,
    mustHaveValue = true,
    multiline,
    endAdornment: inputEndAdornment,
    format,
    disabled,
    maxLength,
    validation,
  }: CalculationTextFieldProps) => {
    const id = originalId ?? useId();
    const styles = useStyles({
      classes,
      label,
      onChange,
      labelWidth,
      disabled,
    });
    const calculationErrorContext = useContext(CalculationErrorContext);
    const valueError = mustHaveValue && (value === undefined || value === "");
    const validationError = value && validation?.(value);
    const emailError = `Please enter a valid email address`;
    const invalidEmail =
      type === "email" && value && !validateEmail(value as string);

    let startAdornment: React.ReactNode;
    let endAdornment = inputEndAdornment;

    switch (format) {
      case "dollar": {
        startAdornment = <InputAdornment position="start">$</InputAdornment>;
        break;
      }
      case "percentage": {
        endAdornment = <InputAdornment position="start">%</InputAdornment>;
        break;
      }
    }

    useEffect(() => {
      if (valueError) {
        calculationErrorContext.addFormError(id, label);
      } else if (invalidEmail) {
        calculationErrorContext.addError(id, emailError);
      }
      if (validationError) {
        calculationErrorContext.addError(id, validationError);
      }
      return () => {
        calculationErrorContext.removeFormError(id, label);
        calculationErrorContext.removeError(id, emailError);
        if (validationError) {
          calculationErrorContext.removeError(id, validationError);
        }
      };
    }, [
      id,
      calculationErrorContext,
      label,
      valueError,
      validationError,
      invalidEmail,
      emailError,
    ]);

    return (
      <FormControlLabel
        control={
          <TextField
            hasError={
              (hasErrors ||
                !!valueError ||
                !!validationError ||
                !!invalidEmail) &&
              calculationErrorContext.validateErrors
            }
            onChange={onChange}
            multiline={multiline}
            rows={6}
            maxLength={maxLength}
            type={type}
            InputProps={{
              startAdornment,
              endAdornment,
              disabled,
            }}
          />
        }
        label={label}
        labelPlacement="start"
        className={clsx(className, styles.label)}
        classes={{
          labelPlacementStart: styles.labelPlacementStart,
          label: styles.labelInner,
        }}
        value={value ?? ""}
      />
    );
  }
);
