import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { DataGrid, GridColumns, GridSortModel } from "@material-ui/data-grid";
import makeStyles from "@material-ui/styles/makeStyles";
import debounce from "debounce";
import { observer } from "mobx-react-lite";
import React, { FC, useContext, useEffect, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";

import Button from "../../common/Button";
import CalculationHeader from "../../common/CalculationHeader";
import CalculationSection from "../../common/CalculationSection";
import CalculationTextField from "../../common/CalculationTextField";
import Dialog from "../../common/Dialog";
import {
  Admin,
  AdminContext,
  CalculationListItem,
  PaginatedCalculationListItem,
} from "../../state/Admin";
import { CalculationState } from "../../state/Calculation";

interface ConfirmationDeleteProps {
  userId: string;
  calculationId: string;
  calculationName: string;
}

const ConfirmationDelete: FC<ConfirmationDeleteProps> = ({
  userId,
  calculationId,
  calculationName,
}) => {
  const adminContext = useContext(AdminContext);
  const [showWarning, setShowWarning] = useState(false);

  const handleDelete = async () => {
    await adminContext.deleteCalculation(userId, calculationId);
    setShowWarning(false);
  };

  return (
    <>
      <IconButton size="small" onClick={() => setShowWarning(true)}>
        <FontAwesomeIcon icon="trash-alt" color="red" />
      </IconButton>
      <Dialog
        maxWidth="sm"
        open={showWarning}
        setOpen={setShowWarning}
        title={`Delete calculation`}
      >
        <DialogContent>
          Are you sure you want to delete calculation{" "}
          <strong>{calculationName}</strong>?
          <DialogActions>
            <Button autoFocus onClick={handleDelete} color="red">
              Delete
            </Button>
            <Button
              autoFocus
              onClick={() => setShowWarning(false)}
              color="slate"
            >
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "80rem",
    minWidth: "40rem",
  },
  spacing: {
    marginBottom: "1rem",
    margin: "1rem",
  },
}));

const useStylesNameCell = makeStyles((theme) => ({
  link: {
    cursor: "pointer",
  },
}));

const CalculationNameCell: FC<{ params: any }> = ({ params }) => {
  const history = useHistory<string[]>();
  const styles = useStylesNameCell();
  return (
    <Link
      className={styles.link}
      onClick={async () => {
        const adminCalculation = params.row as CalculationListItem;
        await CalculationState.load(
          adminCalculation.userId,
          adminCalculation.uniqueId,
          true
        );
        history.push("/Calculation/BasicInformation");
      }}
    >
      {params.value}
    </Link>
  );
};

const CalculationColumns: GridColumns = [
  {
    field: "calculationName",
    headerName: "File name",
    sortable: false,
    flex: 1.5,
    renderCell: (params) => <CalculationNameCell params={params} />,
  },
  {
    field: "userName",
    headerName: "EE num",
    flex: 1,
  },
  {
    field: "firstName",
    headerName: "First name",
    flex: 1,
  },
  {
    field: "lastName",
    headerName: "Last name",
    flex: 1,
  },
  {
    field: "isFinal",
    headerName: "Type",
    flex: 1,
    renderCell: (params) => (params.row.isFinal ? "Final" : "Estimate"),
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
  },
  {
    editable: false,
    field: "actions",
    headerName: " ",
    sortable: false,
    flex: 0.5,
    renderCell: (params) => (
      <ConfirmationDelete
        userId={params.row.userId}
        calculationId={params.row.uniqueId}
        calculationName={params.row.calculationName}
      />
    ),
  },
];

const PAGE_LENGTH = 10;
const getCalculations = debounce(
  async (
    adminContext: Admin,
    eeNumber: string,
    lastName: string,
    sortColumn: string,
    sortOrder: string,
    page: number,
    update: (results: PaginatedCalculationListItem) => void
  ) => {
    update(
      await adminContext.getPaginatedCalculationList(
        eeNumber,
        lastName,
        sortColumn,
        sortOrder,
        page,
        PAGE_LENGTH
      )
    );
  },
  1000
);

const HomeAdmin = observer(() => {
  const styles = useStyles();
  const [eeNumber, setEeNumber] = useState("");
  const [lastName, setLastName] = useState("");
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: "userName",
      sort: "asc",
    },
  ]);
  const adminContext = useContext(AdminContext);
  const [page, setPage] = useState(0);
  const [rows, setRows] = React.useState<CalculationListItem[]>([]);
  const [rowCount, setRowCount] = React.useState(0);

  useEffect(() => {
    // tslint:disable-next-line: no-floating-promises
    getCalculations(
      adminContext,
      eeNumber,
      lastName,
      sortModel[0]?.field,
      sortModel[0]?.sort as string,
      page,
      (result) => {
        setRows(result.calculations ?? []);
        setRowCount(result.count);
      }
    );
  }, [adminContext, page, eeNumber, lastName, sortModel]);

  return (
    <Grid container className={styles.container}>
      <CalculationHeader title="Choose a calculation" variant="secondary" />
      <Grid item className={styles.spacing}>
        <Typography>
          To restore a prior calculation, enter a participant's EE number, last
          name or select from the list below. To create a new calculation, go to{" "}
          <Link
            component={RouterLink}
            to="/Admin/CreateCalculation"
            underline="always"
          >
            Admin &gt; Create calculation
          </Link>
        </Typography>
      </Grid>

      <CalculationSection className={styles.spacing}>
        <CalculationTextField
          label="EE number:"
          value={eeNumber}
          onChange={(value) => setEeNumber(value as string)}
          mustHaveValue={false}
        />
        <CalculationTextField
          label="Last name:"
          value={lastName}
          onChange={(value) => setLastName(value as string)}
          mustHaveValue={false}
        />
      </CalculationSection>

      <Grid className={styles.spacing} item xs={12}>
        <DataGrid
          onPageChange={setPage}
          rowCount={rowCount}
          rows={rows}
          columns={CalculationColumns}
          pageSize={PAGE_LENGTH}
          getRowId={(row) => row.uniqueId}
          disableColumnMenu={true}
          autoHeight={true}
          rowsPerPageOptions={[PAGE_LENGTH]}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          paginationMode={"server"}
          sortingMode={"server"}
        />
      </Grid>
    </Grid>
  );
});

export default HomeAdmin;
