import {
  Box,
  Button,
  createStyles,
  Grid,
  Hidden,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Alert } from "@material-ui/lab";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";

import { TextField, validatePassword } from "../../common/TextField";
import { RootContext } from "../../state/root";
import { StatusCode } from "../../state/User";

const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      marginTop: 0,
      marginBottom: 0,
      height: "100%",
    },
    loginForm: {
      padding: theme.spacing(2),
      marginLeft: "auto",
      marginRight: "auto",
    },
    signInBtn: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    visualContainer: {
      margin: 0,
      height: "100%",
      backgroundImage: "url(/public/images/signup.png)",
      backgroundRepeat: "no-repeat",
      clipPath: "polygon(10% 0, 100% 0, 100% 100%, 0 100%)",
    },
    disclaimer: {
      overflowX: "hidden",
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "green",
      },
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    forgotLinks: {
      marginTop: theme.spacing(1),
    },
    field: {
      backgroundColor: theme.palette.background.paper,
    },
    formContainer: {
      backgroundColor: theme.palette.background.default,
      padding: "2rem",
    },
    forgotPasswordForm: {
      padding: theme.spacing(2),
      marginLeft: "auto",
      marginRight: "auto",
    },
    noEmailInstruction: {
      overflow: "hidden",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      borderColor: theme.palette.action.disabled,
    },
    visual: {
      width: "100%",
      clipPath: "polygon(10% 0, 100% 0, 100% 100%, 0 100%)",
    },
    actions: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
      paddingTop: theme.spacing(2),
    },
    confirmation: {
      display: "flex",
      border: "1px solid",
      borderColor: theme.palette.secondary.main,
      padding: theme.spacing(1),
    },
    confirmationIcon: {
      marginRight: theme.spacing(1),
    },
    collapse: {
      display: "None",
    },
    expand: {
      display: "flex",
      marginTop: "1rem",
    },
  })
);

const ResetPassword: React.FC = observer(() => {
  const [error, setError] = useState<string>();
  const [showSuccess, setShowSuccess] = useState<boolean>();
  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const history = useHistory<string[]>();
  const rootContext = useContext(RootContext);
  const styles = useStyles();

  const hasPasswords = !!password && !!confirmPassword;
  const validPassword = !password || validatePassword(password);
  const passwordsMatch = hasPasswords && password === confirmPassword;

  const onResetPassword = async () => {
    if (!validPassword) {
      setError("Password does not meet minimum specifications");
      return;
    }
    if (!passwordsMatch) {
      setError("Password and confirm password do not match");
      return;
    }
    setError(undefined);
    // Post the form data
    const token = new URL(document.location.href).searchParams?.get("token");
    if (token && password) {
      const result = await rootContext.FinishEmailReset(token, password);
      if (result.status === StatusCode.Ok) {
        setShowSuccess(true);
        window.setTimeout(() => {
          history.push("");
        }, 2500);
        return;
      }
    }

    setError("Password reset failed token invalid or expired");
  };

  return (
    <Grid className={styles.content} container justifyContent="space-around">
      <Grid className={styles.loginForm} item sm={12} md={4}>
        <Box className={styles.formContainer}>
          <Typography variant="h6">Forgot Password</Typography>
          <Typography variant="subtitle2">
            Enter your new password below:
          </Typography>
          <TextField
            className={styles.field}
            fullWidth
            type="password"
            label="New password"
            margin="normal"
            value={password}
            onChange={(value) => setPassword(value as string)}
          />
          <Typography variant="h6">Forgot Password</Typography>
          <Typography variant="subtitle2">Confirm password below:</Typography>
          <TextField
            className={styles.field}
            fullWidth
            type="password"
            label="Confirm password"
            margin="normal"
            value={confirmPassword}
            onChange={(value) => setConfirmPassword(value as string)}
          />
          <Typography variant="caption">
            Password must contain at least one uppercase, lowercase, number and special character with a length of at least 8 characters.
          </Typography>
        </Box>
        <Grid
          className={`${styles.confirmation} ${
            showSuccess ? styles.expand : styles.collapse
          }`}
        >
          <CheckCircleIcon
            className={styles.confirmationIcon}
            color="secondary"
          />
          <Typography color="secondary">Password has been reset</Typography>
        </Grid>
        <Grid
          container
          className={styles.actions}
          direction="row"
          justifyContent="space-between"
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={!hasPasswords || showSuccess}
              onClick={onResetPassword}
            >
              Confirm
            </Button>
          </Grid>
          <Grid item>
            <Link component={RouterLink} to="/">
              Cancel
            </Link>
          </Grid>
        </Grid>
        {error && !showSuccess && <Alert severity="error">{error}</Alert>}
      </Grid>
      <Hidden smDown>
        <Grid className={styles.visualContainer} item md={6} />
      </Hidden>
    </Grid>
  );
});

export default ResetPassword;
