import { makeObservable, observable, runInAction } from "mobx";
import React from "react";

import { LocalStorage } from "./LocalStorage";
import { ServerResult, User } from "./User";

class RootStore {
  @observable
  user?: User;

  @observable
  localStorage: LocalStorage;

  constructor() {
    this.localStorage = new LocalStorage();
    makeObservable(this);
  }

  async Login(email: string, password: string): Promise<boolean> {
    const user = await User.Login(email, password);
    if (user) {
      runInAction(() => {
        this.user = user;
        this.localStorage.acceptedTerms = true;
      });
    }

    return !!user;
  }

  async ContactUs(
    name: string,
    telephone: string,
    email: string,
    message: string
  ): Promise<boolean> {
    return User.ContactUs(name, telephone, email, message);
  }

  async StartEmailReset(email: string): Promise<boolean> {
    return User.StartEmailReset(email);
  }

  async FinishEmailReset(
    token: string,
    password: string
  ): Promise<ServerResult> {
    return User.FinishEmailReset(token, password);
  }

  async Logout(): Promise<void> {
    await this.user?.logout();
    runInAction(() => {
      this.user = undefined;
    });
  }
}

const RootState = new RootStore();
const RootContext = React.createContext(RootState);

export { RootContext, RootState };
