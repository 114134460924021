import {
  Box,
  Button,
  createStyles,
  Grid,
  Hidden,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { TextField, validateEmail } from "../../common/TextField";
import { RootContext } from "../../state/root";

const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      marginTop: 0,
      marginBottom: 0,
      height: "100%",
    },
    loginForm: {
      padding: theme.spacing(2),
      marginLeft: "auto",
      marginRight: "auto",
    },
    signInBtn: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    visualContainer: {
      margin: 0,
      height: "100%",
      backgroundImage: "url(/public/images/signup.png)",
      backgroundRepeat: "no-repeat",
      clipPath: "polygon(10% 0, 100% 0, 100% 100%, 0 100%)",
    },
    disclaimer: {
      overflowX: "hidden",
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "green",
      },
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    forgotLinks: {
      marginTop: theme.spacing(1),
    },
    field: {
      backgroundColor: theme.palette.background.paper,
    },
    formContainer: {
      backgroundColor: theme.palette.background.default,
      padding: "2rem",
    },
    forgotPasswordForm: {
      padding: theme.spacing(2),
      marginLeft: "auto",
      marginRight: "auto",
    },
    noEmailInstruction: {
      overflow: "hidden",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      borderColor: theme.palette.action.disabled,
    },
    visual: {
      width: "100%",
      clipPath: "polygon(10% 0, 100% 0, 100% 100%, 0 100%)",
    },
    actions: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
      paddingTop: theme.spacing(2),
    },
    confirmation: {
      display: "flex",
      border: "1px solid",
      borderColor: theme.palette.secondary.main,
      padding: theme.spacing(1),
    },
    confirmationIcon: {
      marginRight: theme.spacing(1),
    },
    collapse: {
      display: "None",
    },
    expand: {
      display: "flex",
      marginTop: "1rem",
    },
  })
);

const ForgotPassword: React.FC = observer(() => {
  const [userEmail, setUserEmail] = useState<string>();
  const [showPasswordReset, setShowPasswordReset] = useState<boolean>();
  const rootContext = useContext(RootContext);
  const styles = useStyles();
  const invalidEmail = !!(userEmail && !validateEmail(userEmail));

  return (
    <Grid className={styles.content} container justifyContent="space-around">
      <Grid className={styles.loginForm} item sm={12} md={4}>
        <Box className={styles.formContainer}>
          <Typography variant="h6">Forgot Password</Typography>
          <Typography variant="subtitle2">
            Enter your email address below:
          </Typography>
          <TextField
            className={styles.field}
            fullWidth
            type="email"
            label="Enter your email address"
            margin="normal"
            value={userEmail}
            hasError={invalidEmail}
            onChange={(value) => setUserEmail(value as string)}
          />
          <Box
            className={`${styles.noEmailInstruction} ${styles.field}`}
            border={1}
            borderRadius="borderRadius"
            padding={"1rem"}
            component="div"
            overflow="scroll"
            maxHeight="10rem"
          >
            <Typography variant="subtitle2">
              <strong> No email? </strong>
            </Typography>
            <Typography variant="body2">
              If you don't have an email address associated with your Tacoma
              Employees' Retirement System account call the Retirement
              Department at (253) 502-8200.
            </Typography>
          </Box>
        </Box>
        <Grid
          className={`${styles.confirmation} ${
            showPasswordReset ? styles.expand : styles.collapse
          }`}
        >
          <CheckCircleIcon
            className={styles.confirmationIcon}
            color="secondary"
          />
          <Typography color="secondary">
            Password reset instructions sent to your email. Please check your
            spam folder if you do not see the email immediately.
          </Typography>
        </Grid>
        <Grid
          container
          className={styles.actions}
          direction="row"
          justifyContent="space-between"
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={!userEmail || invalidEmail || showPasswordReset}
              onClick={async () => {
                await rootContext.StartEmailReset(userEmail!);
                setShowPasswordReset(true);
              }}
            >
              Confirm
            </Button>
          </Grid>
          <Grid item>
            <Link component={RouterLink} to="/">
              Cancel
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Hidden smDown>
        <Grid className={styles.visualContainer} item md={6} />
      </Hidden>
    </Grid>
  );
});

export default ForgotPassword;
