const Colors = {
  Black: "#000000",
  MillimanBlue: "#004877",
  MillimanBlueHover: "#004899",
  MillimanGreen: "#9DB290",
  MillimanGreenHover: "#9DB2A0",
  VibrantBlue: "#0080e2",
  VibrantBlueHover: "#0080ff",
  Sky: "#50bde1",
  Spring: "#74bf60",
  SpringHover: "#74cc60",
  White: "#ffffff",
  Charcoal: "#39414d",
  CalculationsBackground: "#f7f7f7",
  NormalBackground: "#ffffff",
  TextHeader: "#161616",
  NavMenuGrey: "#232f3e",
  Steel: "#c6c9ca",
  Gold: "#ffa100",
  GoldHover: "#cf8d1d",
  Red: "#a5203f",
  RedHover: "#82152e",
  Slate: "#727a7d",
  SlateHover: "#4a4e4f",
};

export { Colors };
