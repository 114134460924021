import { makeObservable, observable, runInAction } from "mobx";
import { Moment } from "moment";
import React from "react";

import { Api } from "../utilities/request";

import { Calculation } from "./Calculation";
import { StatusCode, User, UserServerData } from "./User";

export interface PaginatedCalculationListItem {
  calculations: CalculationListItem[];
  count: number;
}

export interface CalculationListItem {
  calculationName: string;
  uniqueId: string;
  created: string;
  userName: string;
  firstName: string;
  lastName: string;
  description: string;
  userId: string;
  isFinal: boolean;
}

export interface NewUser {
  employeeNumber: string;
  firstName: string;
  lastName: string;
  gender: string;
  dateOfBirth: Moment;
  employer: string;
  dateOfMembership: Moment;
  lastDayOfEmployment: Moment;
  totalMemberContributions: number;
  totalBenefitContributions: number;
  nonTaxableContributions: number;
  memberContributionsLastPayPeriod: number;
  benefitContributionsLastPayPeriod: number;
  currentContributionsLastPayDate: Moment;
  currentGrossPayPerPeriod: number;
  additionalContributionsPre83: number;
  additionalContributionsPost83: number;
}

export interface ExportResults {
  isFinal?: boolean;
  employeeNumber?: string;
  lastName?: string;
  startDateOfMembership?: Moment;
  endDateOfMembership?: Moment;
  startDateOfCalculation?: Moment;
  endDateOfCalculation?: Moment;
  startDateOfRetirement?: Moment;
  endDateOfRetirement?: Moment;
}

export interface BulkUpdateResult {
  status: StatusCode;
  usersCreated: number;
  usersUpdated: number;
  usersFailed: number;
  usersDeleted: number;
}

export interface ServerFile {
  uniqueId: string;
  fileName: string;
  created: string;
}

export class Admin {
  private static readonly _endpoint = "/api/Admin";

  @observable
  calculationListItems: CalculationListItem[] = [];

  @observable
  users: User[] = [];

  constructor() {
    makeObservable(this);
  }

  async addUser(newUser: NewUser): Promise<{ status: StatusCode }> {
    const data = await Api.request.post<{ status: StatusCode }>(
      `${Admin._endpoint}/AddUser`,
      newUser
    );
    return data;
  }

  async addFile(file: File): Promise<{ status: StatusCode }> {
    const formData = new FormData();
    formData.append("spreadsheet", file, file.name);
    const { data } = await Api.request.post<{ status: StatusCode }>(
      `${Admin._endpoint}/Files`,
      formData
    );
    return data;
  }

  async getFiles(): Promise<{ status: StatusCode; files: ServerFile[] }> {
    const { data } = await Api.request.get<{
      status: StatusCode;
      files: ServerFile[];
    }>(`${Admin._endpoint}/Files`);
    return data;
  }

  async bulkUpdate(updateText: string): Promise<BulkUpdateResult> {
    const { data } = await Api.request.post<BulkUpdateResult>(
      `${Admin._endpoint}/BulkUpdate`,
      { updateText }
    );
    return data;
  }

  async exportResults(request: ExportResults): Promise<Blob> {
    const response = await Api.request.post(
      `${Admin._endpoint}/ExportResults`,
      request,
      {
        responseType: "blob",
      }
    );
    return response.data;
  }

  async createCalculation(
    userId: string,
    isFinal: boolean,
    calculationToCopy?: string
  ): Promise<boolean> {
    const { data } = await Api.request.post<{ status: StatusCode }>(
      `${Calculation._endpoint}/AddCalculation/${userId}`,
      { calculationToCopy, isFinal }
    );
    return data.status === StatusCode.Ok;
  }

  async deleteCalculation(
    userId: string,
    calculationId: string
  ): Promise<boolean> {
    const { data } = await Api.request.delete<{ status: StatusCode }>(
      `${Calculation._endpoint}/${userId}/${calculationId}`
    );
    return data.status === StatusCode.Ok;
  }

  async deleteUser(userId: string): Promise<boolean> {
    const { data } = await Api.request.delete<{ status: StatusCode }>(
      `${Admin._endpoint}/Users/${userId}`
    );
    return data.status === StatusCode.Ok;
  }

  async getCalculationList(
    forceLoad: boolean = false
  ): Promise<CalculationListItem[] | undefined> {
    if (this.calculationListItems.length !== 0 && !forceLoad) {
      return this.calculationListItems;
    }

    const response = await Api.request.get<{
      calculations: CalculationListItem[];
    }>(`${Admin._endpoint}/Calculations`);
    if (response.status === 200) {
      runInAction(() => {
        this.calculationListItems = response.data.calculations;
      });
      return this.calculationListItems;
    }
    return undefined;
  }

  async getPaginatedCalculationList(
    eeNumber: string,
    lastName: string,
    sortColumn: string,
    sortOrder: string,
    page: number,
    pageLength: number
  ): Promise<PaginatedCalculationListItem> {
    const response = await Api.request.get<PaginatedCalculationListItem>(
      `${Admin._endpoint}/PaginatedCalculations?eeNumber=${eeNumber}&lastName=${lastName}&page=${page}&pageLength=${pageLength}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`
    );
    return response.data;
  }

  async getUserList(forceLoad: boolean = false): Promise<User[] | undefined> {
    if (this.users.length !== 0 && !forceLoad) {
      return this.users;
    }

    const response = await Api.request.get<{ users: UserServerData[] }>(
      `${Admin._endpoint}/Users`
    );
    if (response.status === 200) {
      runInAction(() => {
        this.users = response.data.users.map((u) => User.fromServer(u));
      });
      return this.users;
    }

    return undefined;
  }
}

const AdminState = new Admin();
const AdminContext = React.createContext(AdminState);

export { AdminState, AdminContext };
