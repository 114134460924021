import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import moment, { Moment } from "moment";
import React from "react";

import { Api } from "../utilities/request";

export type BenefitType =
  | "Retirement"
  | "Disability"
  | "Death-Immediate"
  | "Death-Deferred";

export interface BenefitOption {
  description1: string;
  description2: string;
  description3: string;
  display: boolean;
  name: string;
  value: string;
}

export interface NonTaxable {
  description1: string;
  description2: string;
  description3: string;
  display: boolean;
  statement: string;
}

const EMPTY_DATE = "01/01/0001";

export interface CalculationData {
  pageId?: number;
  isFinal?: boolean;

  // Basic Information
  firstName: string;
  lastName: string;
  gender: string;
  employeeNumber: string;
  dateOfMembership: string;
  dateOfBirth: string;
  description: string;
  isProjection: string;
  benefitType: BenefitType;
  dateOfRetirement: string;
  lastDayOfEmployment: string;
  beneficiaryInformationFirstName: string;
  beneficiaryInformationLastName: string;
  beneficiaryDateOfBirth: string;
  multipleBeneficiaries: string;

  // Contributions
  employer: "C" | "T" | "H" | "L" | "D";
  totalMemberContributions: number;
  totalBenefitContributions: number;
  nonTaxableContributions: number;
  extraContributionsPre83: number;
  extraContributionsPost83: number;
  memberContributionsLastQuarter: number;
  benefitContributionsLastQuarter: number;
  memberContributionsLastPayPeriod: number;
  benefitContributionsLastPayPeriod: number;
  additionalContributionsPre83: number;
  additionalContributionsPost83: number;
  currentContributionsLastPayDate: string;

  // Service and Salary
  serviceAdjustmentPre83Years: number;
  serviceAdjustmentPre83Months: number;
  serviceAdjustmentPre83Days: number;
  serviceAdjustmentPre83Hours: number;
  serviceAdjustmentPost83Years: number;
  serviceAdjustmentPost83Months: number;
  serviceAdjustmentPost83Days: number;
  serviceAdjustmentPost83Hours: number;
  portability: string;
  portabilitySystem: string;
  portabilityFae: number;
  portabilityService: number;
  portabilityOfServicePost97: string;
  finalSalary: string;
  currentGrossPayPerPeriod: number;
  salaryInflation: string;
  monthlyRate1: number;
  longevity1: number;
  months1: number;
  monthlyRate2: number;
  longevity2: number;
  months2: number;
  monthlyRate3: number;
  longevity3: number;
  months3: number;
  monthlyRate4: number;
  longevity4: number;
  months4: number;
  monthlyRate5: number;
  longevity5: number;
  months5: number;
  monthlyRate6: number;
  longevity6: number;
  months6: number;
  monthlyRate7: number;
  longevity7: number;
  months7: number;
  monthlyRate8: number;
  longevity8: number;
  months8: number;
  code9Time: number;

  // Benefit elections
  annuitantType: string;
  annuitantDateOfBirth: string;
  benefitOption: string;
  ssAdjustment: string;
  ssAtAge62: number;
  servicePurchaseSelected: string;
  amountOfServiceToPurchase: number;
  totalBenefitContributionsLastQuarter: number;
  taxTreatmentOfPayment: string;

  // Repayment of Contributions
  amountWithdrawn: number;
  dateWithdrawn: string;
  serviceRegained: number;
  repaymentDate: string;
}

export interface CalculationResults {
  /*
   * Basic Information
   */
  ageOfRetirement: number;
  annuitantAge: number;
  deathBeneficiaryAge: number;

  /*
   * Contributions
   */
  totalMemberContributions: number;
  totalBenefitContributions: number;
  nonTaxableContributions: number;
  pre83ExtraContributions: number;
  post83ExtraContributions: number;

  /*
   * Salary
   */
  averageFinalSalary: number;

  /*
   * Service
   */
  pre83DockTime: number;
  post83DockTime: number;
  purchasedService: number;
  tersService: number;
  yearsNeededToRetire: number;
  maxEligibleServicePurchase: number;
  portabilityChange: number;
  servicePurchaseAvailable: string;

  /*
   * Benefit Values
   */
  unmodifiedBenefit: number;
  totalContributionsInclAdditional: number;
  optionADecreasingInsrAmnt: number;
  optionABenefit: number;
  optionBDecreasingInsrAmnt: number;
  optionBBenefit: number;
  optionC5Benefit: number;
  optionC10Benefit: number;
  optionDF50Participant: number;
  optionDF50Survivor: number;
  optionEF100Benefit: number;

  /*
   * Death Benefit
   */
  lSTotalMemberContributions: number;
  tenYearCertainAnnuity: number;
  immediateAnnuity: number;
  oneHalfLSAmount: number;
  oneHalfLSImmediateAnnuity: number;

  /*
   * Benefit Options
   */
  servicePurchaseYears: number;
  servicePurchaseAmount: number;
  socialSecurityBenefitAmount: number;
  socialSecurityAdvance: number;

  /*
   * Non-taxable Contributions
   */
  singleLifeMonths: number;
  singleLifeAmount: number;
  twoLivesMonths: number;
  twoLivesAmount: number;

  /*
   * Non-Social Security Adjustment Descriptions
   */
  unmodifiedDescription: string[];
  optionADescription: string[];
  optionBDescription: string[];
  optionC5Description: string[];
  optionC10Description: string[];
  optionDDescription: string[];
  optionF50Description: string[];
  optionEDescription: string[];
  optionF100Description: string[];

  /*
   * Social Security Adjustment Descriptions
   */
  unmodifiedSocialSecurityDescription: string[];
  optionASocialSecurityDescription: string[];
  optionBSocialSecurityDescription: string[];
  optionC5SocialSecurityDescription: string[];
  optionC10SocialSecurityDescription: string[];
  optionDSocialSecurityDescription: string[];
  optionF50SocialSecurityDescription: string[];
  optionESocialSecurityDescription: string[];
  optionF100SocialSecurityDescription: string[];

  /*
   * Death Benefits
   */
  immediateLumpSumBenefitDescription: string[];
  tenYearCertainDeathDescription: string[];
  immediateAnnuityDeathBenefitDescription: string[];
  halfLumpSumImmediateAnnuityDeathBenefitDescription: string[];

  /*
   * Non-taxable Portion of the Benefit
   */
  nonTaxableDescriptionA: string[];
  nonTaxableDescriptionB: string[];
  nonTaxableDescriptionC: string[];
  nonTaxableDescriptionD: string[];
  nonTaxableDescriptionE: string[];
  nonTaxableDescriptionF: string[];
  nonTaxableDescriptionG: string[];

  /*
   * Flags
   */
  flag415Limit: string;
  flag401Limit: string;
  isEligibleForRetirement: string;
  isEligibleForRetirementWithoutPurchase: string;

  /*
   * Repayment of Contributions
   */
  totalRepaymentAmount: number;
  amountPerQuarter: number;
}
interface ServerCalculation {
  uniqueId: string;
  created?: string;
  isRepayment: boolean;
  calculationName: string;
  benefitOptions?: BenefitOption[];
  nonTaxables?: NonTaxable[];
  data: CalculationData;
  results: CalculationResults;
}

interface ExportServerResults {
  export: {
    address: string;
    text: string;
  }[];
}
export interface ExportResults {
  [address: string]: string;
}

export class Calculation {
  static readonly _endpoint = "/api/Calculation";
  readonly uniqueId: string;
  readonly created: Moment;
  readonly calculationName: string;
  readonly isRepayment: boolean;
  readonly userId: string | undefined;

  isFinal?: boolean;

  @observable
  originalState?: string;

  @observable
  lastState?: string;

  @observable
  pageId?: number;

  // Basic Information
  @observable
  firstName?: string;

  @observable
  lastName?: string;

  @observable
  gender?: string;

  @observable
  employeeNumber?: string;

  @observable
  dateOfBirth?: Moment;

  @observable
  dateOfMembership?: Moment;

  @observable
  description?: string;

  @observable
  benefitType?: BenefitType;

  @observable
  isProjection?: string;

  @observable
  lastDayOfEmployment?: Moment;

  @observable
  dateOfRetirement?: Moment;

  @observable
  beneficiaryInformationFirstName?: string;

  @observable
  beneficiaryInformationLastName?: string;

  @observable
  beneficiaryDateOfBirth?: Moment;

  @observable
  multipleBeneficiaries?: string;

  // Contributions
  @observable
  employer?: "C" | "T" | "H" | "L" | "D";

  @observable
  totalMemberContributions?: number;

  @observable
  totalBenefitContributions?: number;

  @observable
  nonTaxableContributions?: number;

  @observable
  extraContributionsPre83?: number;

  @observable
  extraContributionsPost83?: number;

  @observable
  memberContributionsLastQuarter?: number;

  @observable
  benefitContributionsLastQuarter?: number;

  @observable
  memberContributionsLastPayPeriod?: number;

  @observable
  benefitContributionsLastPayPeriod?: number;

  @observable
  additionalContributionsPre83?: number;

  @observable
  additionalContributionsPost83?: number;

  @observable
  currentContributionsLastPayDate?: Moment;

  // Service and Salary
  @observable
  serviceAdjustmentPre83Years?: number;

  @observable
  serviceAdjustmentPre83Months?: number;

  @observable
  serviceAdjustmentPre83Days?: number;

  @observable
  serviceAdjustmentPre83Hours?: number;

  @observable
  serviceAdjustmentPost83Years?: number;

  @observable
  serviceAdjustmentPost83Months?: number;

  @observable
  serviceAdjustmentPost83Days?: number;

  @observable
  serviceAdjustmentPost83Hours?: number;

  @observable
  portability?: string;

  @observable
  portabilitySystem?: string;

  @observable
  portabilityFae?: number;

  @observable
  portabilityService?: number;

  @observable
  portabilityOfServicePost97?: string;

  @observable
  finalSalary?: string;

  @observable
  currentGrossPayPerPeriod?: number;

  @observable
  salaryInflation?: string;

  @observable
  monthlyRate1?: number;

  @observable
  longevity1?: number;

  @observable
  months1?: number;

  @observable
  monthlyRate2?: number;

  @observable
  longevity2?: number;

  @observable
  months2?: number;

  @observable
  monthlyRate3?: number;

  @observable
  longevity3?: number;

  @observable
  months3?: number;

  @observable
  monthlyRate4?: number;

  @observable
  longevity4?: number;

  @observable
  months4?: number;

  @observable
  monthlyRate5?: number;

  @observable
  longevity5?: number;

  @observable
  months5?: number;

  @observable
  monthlyRate6?: number;

  @observable
  longevity6?: number;

  @observable
  months6?: number;

  @observable
  monthlyRate7?: number;

  @observable
  longevity7?: number;

  @observable
  months7?: number;

  @observable
  monthlyRate8?: number;

  @observable
  longevity8?: number;

  @observable
  months8?: number;

  @observable
  code9Time?: number;

  // Benefit elections
  @observable
  annuitantType?: string;

  @observable
  annuitantDateOfBirth?: Moment;

  @observable
  benefitOption?: string;

  @observable
  ssAdjustment?: string;

  @observable
  ssAtAge62?: number;

  @observable
  servicePurchaseSelected?: string;

  @observable
  amountOfServiceToPurchase?: number;

  @observable
  totalBenefitContributionsLastQuarter?: number;

  @observable
  taxTreatmentOfPayment?: string;

  // Repayment of Contributions
  @observable
  amountWithdrawn?: number;

  @observable
  dateWithdrawn?: Moment;

  @observable
  serviceRegained?: number;

  @observable
  repaymentDate?: Moment;

  @observable
  results?: CalculationResults;

  @observable
  benefitOptions?: BenefitOption[];

  @observable
  nonTaxables?: NonTaxable[];

  constructor(
    userId: string | undefined,
    uniqueId: string,
    isRepayment: boolean,
    created: Moment,
    calculationName: string
  ) {
    makeObservable(this);
    this.userId = userId;
    this.uniqueId = uniqueId;
    this.isRepayment = isRepayment;
    this.created = created;
    this.calculationName = calculationName;
  }

  static async getCalculations(
    userId?: string,
    forceRefresh: boolean = false,
    calculationId?: string
  ): Promise<Calculation[] | undefined> {
    const response = await Api.request.get<{
      calculations: ServerCalculation[];
    }>(
      `${this._endpoint}/${
        userId ?? ""
      }?forceRefresh=${forceRefresh}&calculationId=${calculationId}`
    );

    if (response.status === 200) {
      return response.data.calculations.map((c) => {
        const calculation = new Calculation(
          userId,
          c.uniqueId,
          c.isRepayment,
          moment(c.created),
          c.calculationName
        );
        calculation._fromServer(c);
        return calculation;
      });
    }

    return undefined;
  }

  @computed
  get isDirty(): boolean {
    return (
      this.lastState !== this.originalState ||
      this.lastState !== this.getState()
    );
  }

  async export(): Promise<ExportResults | undefined> {
    const response = await Api.request.get<ExportServerResults>(
      `${Calculation._endpoint}/export/${this.uniqueId}`
    );
    if (response.status === 200) {
      const exportResult = response.data.export.reduce(
        (previous: any, current) => {
          previous[current.address] = current.text;
          return previous;
        },
        {}
      );
      return exportResult;
    }
  }

  async saveCalculation(): Promise<void> {
    const response = await Api.request.post<ServerCalculation>(
      `${Calculation._endpoint}/${this.uniqueId}`,
      this._toServer()
    );
    if (response.status === 200) {
      this._fromServer(response.data, true);
    }
  }

  async updateCalculation(): Promise<void> {
    const response = await Api.request.put<ServerCalculation>(
      `${Calculation._endpoint}/${this.uniqueId}`,
      this._toServer()
    );
    if (response.status === 200) {
      this._fromServer(response.data);
    }
  }

  getState(): string {
    return JSON.stringify(this._toServer());
  }

  @action
  private _fromServer(
    c: ServerCalculation,
    updateState: boolean = false
  ): void {
    this.pageId = c.data.pageId;
    // Basic Information
    this.firstName = c.data.firstName;
    this.lastName = c.data.lastName;
    this.employeeNumber = c.data.employeeNumber;
    this.gender = c.data.gender;
    this.dateOfBirth = moment(c.data.dateOfBirth);
    this.dateOfMembership = moment(c.data.dateOfMembership);
    this.description = c.data.description;
    this.benefitType = c.data.benefitType;
    this.isProjection = c.data.isProjection;
    this.lastDayOfEmployment = moment(c.data.lastDayOfEmployment);
    this.dateOfRetirement = moment(c.data.dateOfRetirement);
    this.beneficiaryInformationFirstName =
      c.data.beneficiaryInformationFirstName;
    this.beneficiaryInformationLastName = c.data.beneficiaryInformationLastName;
    this.beneficiaryDateOfBirth = moment(c.data.beneficiaryDateOfBirth);
    this.multipleBeneficiaries = c.data.multipleBeneficiaries;
    // Contributions
    this.employer = c.data.employer;
    this.totalMemberContributions = c.data.totalMemberContributions;
    this.totalBenefitContributions = c.data.totalBenefitContributions;
    this.nonTaxableContributions = c.data.nonTaxableContributions;
    this.extraContributionsPre83 = c.data.extraContributionsPre83;
    this.extraContributionsPost83 = c.data.extraContributionsPost83;
    this.memberContributionsLastQuarter = c.data.memberContributionsLastQuarter;
    this.benefitContributionsLastQuarter =
      c.data.benefitContributionsLastQuarter;
    this.memberContributionsLastPayPeriod =
      c.data.memberContributionsLastPayPeriod;
    this.benefitContributionsLastPayPeriod =
      c.data.benefitContributionsLastPayPeriod;
    this.additionalContributionsPre83 = c.data.additionalContributionsPre83;
    this.additionalContributionsPost83 = c.data.additionalContributionsPost83;
    this.currentContributionsLastPayDate = moment(
      c.data.currentContributionsLastPayDate
    );
    // Service and Salary
    this.serviceAdjustmentPre83Years = c.data.serviceAdjustmentPre83Years;
    this.serviceAdjustmentPre83Months = c.data.serviceAdjustmentPre83Months;
    this.serviceAdjustmentPre83Days = c.data.serviceAdjustmentPre83Days;
    this.serviceAdjustmentPre83Hours = c.data.serviceAdjustmentPre83Hours;
    this.serviceAdjustmentPost83Years = c.data.serviceAdjustmentPost83Years;
    this.serviceAdjustmentPost83Months = c.data.serviceAdjustmentPost83Months;
    this.serviceAdjustmentPost83Days = c.data.serviceAdjustmentPost83Days;
    this.serviceAdjustmentPost83Hours = c.data.serviceAdjustmentPost83Hours;
    this.portability = c.data.portability;
    this.portabilitySystem = c.data.portabilitySystem;
    this.portabilityFae = c.data.portabilityFae;
    this.portabilityService = c.data.portabilityService;
    this.portabilityOfServicePost97 = c.data.portabilityOfServicePost97;
    this.finalSalary = c.data.finalSalary;
    this.currentGrossPayPerPeriod = c.data.currentGrossPayPerPeriod;
    this.salaryInflation = c.data.salaryInflation;
    this.monthlyRate1 = c.data.monthlyRate1;
    this.longevity1 = c.data.longevity1;
    this.months1 = c.data.months1;
    this.monthlyRate2 = c.data.monthlyRate2;
    this.longevity2 = c.data.longevity2;
    this.months2 = c.data.months2;
    this.monthlyRate3 = c.data.monthlyRate3;
    this.longevity3 = c.data.longevity3;
    this.months3 = c.data.months3;
    this.monthlyRate4 = c.data.monthlyRate4;
    this.longevity4 = c.data.longevity4;
    this.months4 = c.data.months4;
    this.monthlyRate5 = c.data.monthlyRate5;
    this.longevity5 = c.data.longevity5;
    this.months5 = c.data.months5;
    this.monthlyRate6 = c.data.monthlyRate6;
    this.longevity6 = c.data.longevity6;
    this.months6 = c.data.months6;
    this.monthlyRate7 = c.data.monthlyRate7;
    this.longevity7 = c.data.longevity7;
    this.months7 = c.data.months7;
    this.monthlyRate8 = c.data.monthlyRate8;
    this.longevity8 = c.data.longevity8;
    this.months8 = c.data.months8;
    this.code9Time = c.data.code9Time;
    // Benefit elections
    this.annuitantType = c.data.annuitantType;
    this.annuitantDateOfBirth = moment(c.data.annuitantDateOfBirth);
    this.benefitOption = c.data.benefitOption;
    this.benefitOptions = c.benefitOptions;
    this.nonTaxables = c.nonTaxables;
    this.ssAdjustment = c.data.ssAdjustment;
    this.ssAtAge62 = c.data.ssAtAge62;
    this.servicePurchaseSelected = c.data.servicePurchaseSelected;
    this.amountOfServiceToPurchase = c.data.amountOfServiceToPurchase;
    this.totalBenefitContributionsLastQuarter =
      c.data.totalBenefitContributionsLastQuarter;
    this.taxTreatmentOfPayment = c.data.taxTreatmentOfPayment;
    // Repayment of Contributions
    this.amountWithdrawn = c.data.amountWithdrawn;
    this.dateWithdrawn = moment(c.data.dateWithdrawn);
    this.serviceRegained = c.data.serviceRegained;
    this.repaymentDate = moment(c.data.repaymentDate);
    // Results
    this.results = c.results;
    // Set State
    this.lastState = this.getState();
    this.originalState = updateState
      ? this.lastState
      : this.originalState ?? this.lastState;
  }

  private _toServer(): Omit<ServerCalculation, "results"> {
    return {
      calculationName: this.calculationName,
      uniqueId: this.uniqueId,
      isRepayment: this.isRepayment,
      data: {
        pageId: this.pageId,
        isFinal: this.isFinal,
        // Basic Information
        firstName: this.firstName!,
        lastName: this.lastName!,
        employeeNumber: this.employeeNumber!,
        dateOfBirth: this.dateOfBirth?.format("L") ?? EMPTY_DATE,
        dateOfMembership: this.dateOfMembership?.format("L") ?? EMPTY_DATE,
        gender: this.gender!,
        description: this.description!,
        benefitType: this.benefitType!,
        isProjection: this.isProjection!,
        lastDayOfEmployment:
          this.lastDayOfEmployment?.format("L") ?? EMPTY_DATE,
        dateOfRetirement: this.dateOfRetirement?.format("L") ?? EMPTY_DATE,
        beneficiaryInformationFirstName: this.beneficiaryInformationFirstName!,
        beneficiaryInformationLastName: this.beneficiaryInformationLastName!,
        beneficiaryDateOfBirth:
          this.beneficiaryDateOfBirth?.format("L") ?? EMPTY_DATE,
        multipleBeneficiaries: this.multipleBeneficiaries!,
        // Contributions
        employer: this.employer!,
        totalMemberContributions: this.totalMemberContributions!,
        totalBenefitContributions: this.totalBenefitContributions!,
        nonTaxableContributions: this.nonTaxableContributions!,
        extraContributionsPre83: this.extraContributionsPre83!,
        extraContributionsPost83: this.extraContributionsPost83!,
        memberContributionsLastQuarter: this.memberContributionsLastQuarter!,
        benefitContributionsLastQuarter: this.benefitContributionsLastQuarter!,
        memberContributionsLastPayPeriod:
          this.memberContributionsLastPayPeriod!,
        benefitContributionsLastPayPeriod:
          this.benefitContributionsLastPayPeriod!,
        additionalContributionsPre83: this.additionalContributionsPre83!,
        additionalContributionsPost83: this.additionalContributionsPost83!,
        currentContributionsLastPayDate:
          this.currentContributionsLastPayDate?.format("L") ?? EMPTY_DATE,
        // Service and Salary
        serviceAdjustmentPre83Years: this.serviceAdjustmentPre83Years!,
        serviceAdjustmentPre83Months: this.serviceAdjustmentPre83Months!,
        serviceAdjustmentPre83Days: this.serviceAdjustmentPre83Days!,
        serviceAdjustmentPre83Hours: this.serviceAdjustmentPre83Hours!,
        serviceAdjustmentPost83Years: this.serviceAdjustmentPost83Years!,
        serviceAdjustmentPost83Months: this.serviceAdjustmentPost83Months!,
        serviceAdjustmentPost83Days: this.serviceAdjustmentPost83Days!,
        serviceAdjustmentPost83Hours: this.serviceAdjustmentPost83Hours!,
        portability: this.portability!,
        portabilitySystem: this.portabilitySystem!,
        portabilityFae: this.portabilityFae!,
        portabilityService: this.portabilityService!,
        portabilityOfServicePost97: this.portabilityOfServicePost97!,
        finalSalary: this.finalSalary!,
        currentGrossPayPerPeriod: this.currentGrossPayPerPeriod!,
        salaryInflation: this.salaryInflation!,
        monthlyRate1: this.monthlyRate1!,
        longevity1: this.longevity1!,
        months1: this.months1!,
        monthlyRate2: this.monthlyRate2!,
        longevity2: this.longevity2!,
        months2: this.months2!,
        monthlyRate3: this.monthlyRate3!,
        longevity3: this.longevity3!,
        months3: this.months3!,
        monthlyRate4: this.monthlyRate4!,
        longevity4: this.longevity4!,
        months4: this.months4!,
        monthlyRate5: this.monthlyRate5!,
        longevity5: this.longevity5!,
        months5: this.months5!,
        monthlyRate6: this.monthlyRate6!,
        longevity6: this.longevity6!,
        months6: this.months6!,
        monthlyRate7: this.monthlyRate7!,
        longevity7: this.longevity7!,
        months7: this.months7!,
        monthlyRate8: this.monthlyRate8!,
        longevity8: this.longevity8!,
        months8: this.months8!,
        code9Time: this.code9Time!,
        // Benefit elections
        annuitantType: this.annuitantType!,
        annuitantDateOfBirth:
          this.annuitantDateOfBirth?.format("L") ?? EMPTY_DATE,
        benefitOption: this.benefitOption!,
        ssAdjustment: this.ssAdjustment!,
        ssAtAge62: this.ssAtAge62!,
        servicePurchaseSelected: this.servicePurchaseSelected!,
        amountOfServiceToPurchase: this.amountOfServiceToPurchase!,
        totalBenefitContributionsLastQuarter:
          this.totalBenefitContributionsLastQuarter!,
        taxTreatmentOfPayment: this.taxTreatmentOfPayment!,
        // Repayment of Contributions
        amountWithdrawn: this.amountWithdrawn!,
        dateWithdrawn: this.dateWithdrawn?.format("L") ?? EMPTY_DATE,
        serviceRegained: this.serviceRegained!,
        repaymentDate: this.repaymentDate?.format("L") ?? EMPTY_DATE,
      },
    };
  }
}

class CalculationStore {
  @observable
  calculations?: Calculation[];

  @observable
  currentCalculation?: Calculation;

  constructor() {
    makeObservable(this);
  }

  async load(
    userId?: string,
    calculationId?: string,
    forceRefresh: boolean = false
  ): Promise<void> {
    const calculations = await Calculation.getCalculations(
      userId,
      forceRefresh,
      calculationId
    );
    runInAction(() => {
      this.calculations = calculations;
      this.currentCalculation = calculationId
        ? calculations?.find((c) => c.uniqueId === calculationId)
        : calculations?.find((c) => !c.isRepayment);
    });
  }
}

const CalculationState = new CalculationStore();
const CalculationContext = React.createContext(CalculationState);

export { CalculationState, CalculationContext };
