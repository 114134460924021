import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/core";
import MuiTooltip from "@material-ui/core/Tooltip";
import React from "react";

import { Colors } from "../utilities/colors";

const useStyles = makeStyles(() => ({
  tooltip: {
    marginLeft: "0.5rem",
    marginTop: "-0.2rem",
  },
}));

const Tooltip: React.FC<{
  tooltip?: React.ReactChild | React.ReactFragment | React.ReactPortal;
  style?: React.CSSProperties;
}> = ({ tooltip, style }) => {
  const styles = useStyles();
  return (
    <>
      {tooltip && (
        <MuiTooltip style={style} title={tooltip} placement="right">
          <div className={styles.tooltip}>
            <FontAwesomeIcon
              icon="question-circle"
              size="lg"
              color={Colors.MillimanBlue}
            />
          </div>
        </MuiTooltip>
      )}
    </>
  );
};

export { Tooltip };
