import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { FC, useEffect, useState } from "react";
import { useContext } from "react";
import { useParams } from "react-router";

import { CalculationContext, ExportResults } from "../../state/Calculation";
import { Colors } from "../../utilities/colors";

import { CalculationPageProps } from "./CalculationPage";

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: "bold",
  },
  parent: {
    padding: "2rem",
    "@media print": {
      padding: "1rem",
      margin: "0 !important",
      zoom: 0.75,
    },
  },
  container: {
    "@media print": {
      padding: "0 !important",
      margin: "0 !important",
    },
  },
  blue: {
    backgroundColor: Colors.MillimanBlue,
    color: Colors.White,
  },
  underline: {
    borderBottom: "2px solid black",
  },
  grey: {
    backgroundColor: Colors.CalculationsBackground,
    fontWeight: "bold",
  },
}));

const toRows = (count: number, start: number, headers: number[]) =>
  Array.from({ length: count }, (_, i) => i + start).map((i) => [
    i,
    headers.includes(i),
  ]);

const Table: FC<{ children: JSX.Element[] | JSX.Element }> = ({ children }) => (
  <table cellSpacing={0} style={{ width: "100%", marginTop: "1rem" }}>
    <tbody>{children}</tbody>
  </table>
);

const TD: FC<{
  children: JSX.Element[] | JSX.Element | string;
  headers?: number[];
  index: number;
  underlines?: number[];
}> = ({ children, headers, index, underlines }) => {
  const styles = useStyles();
  return (
    <td
      className={clsx({
        [styles.underline]: underlines?.includes(index),
        [styles.grey]: headers?.includes(index),
      })}
    >
      {children}
    </td>
  );
};

const Export: React.FC<CalculationPageProps> = observer(() => {
  const calculationContext = useContext(CalculationContext);
  const { calculationId } = useParams<{ calculationId: string }>();
  const [er, setExportResults] = useState<ExportResults | undefined>(undefined);
  const styles = useStyles();
  const c = calculationContext?.calculations?.find(
    (c) => c.uniqueId === calculationId
  );

  useEffect(() => {
    if (!calculationContext.calculations) {
      // tslint:disable-next-line: no-floating-promises
      calculationContext.load(undefined, calculationId);
    }
  }, [calculationContext, calculationId]);

  useEffect(() => {
    c?.export().then(setExportResults);
  }, [c]);

  if (!c || !er) {
    return null;
  }

  return (
    <Grid
      container
      justifyContent="space-between"
      className={styles.parent}
      spacing={2}
    >
      <Grid className={styles.container} item sm={12} md={4}>
        <Table>
          <tr>
            <td className={styles.bold}>Name</td>
            <td>{er["$C$4"]}</td>
          </tr>
          <tr>
            <td className={styles.bold}>SAP Number</td>
            <td>{er["$C$5"]}</td>
          </tr>
          <tr>
            <td className={styles.bold}>Filename</td>
            <td>{c.calculationName}</td>
          </tr>
        </Table>
      </Grid>

      <Grid className={styles.container} item sm={12} md={8}>
        <Table>
          <tr className={styles.blue}>
            <td colSpan={6}>Age Calculations</td>
          </tr>
          <tr className={styles.grey}>
            <td></td>
            <td>Start</td>
            <td>End</td>
            <td>AA-Qtrs</td>
            <td>AA-Mnths</td>
            <td>Attained Age</td>
          </tr>
          <tr>
            <td>Participant</td>
            <td>{er["$G$5"]}</td>
            <td>{er["$H$5"]}</td>
            <td>{er["$J$5"]}</td>
            <td>{er["$K$5"]}</td>
            <td>{er["$L$5"]}</td>
          </tr>
          <tr>
            <td>Death Beneficiary</td>
            <td>{er["$G$6"]}</td>
            <td>{er["$H$6"]}</td>
            <td>{er["$J$6"]}</td>
            <td>{er["$K$6"]}</td>
            <td>{er["$L$6"]}</td>
          </tr>
          <tr>
            <td>Annuitant</td>
            <td>{er["$G$7"]}</td>
            <td>{er["$H$7"]}</td>
            <td>{er["$J$7"]}</td>
            <td>{er["$K$7"]}</td>
            <td>{er["$L$7"]}</td>
          </tr>
        </Table>
      </Grid>

      <Grid className={styles.container} item sm={12}>
        <Table>
          <tr className={styles.blue}>
            <td colSpan={10}>Service Calculations</td>
          </tr>
          <tr className={styles.grey}>
            <td></td>
            <td>Start</td>
            <td>Days</td>
            <td>Days</td>
            <td>End</td>
            <td>Years</td>
            <td>Months</td>
            <td>Days</td>
            <td>Hours</td>
            <td>Year Frac</td>
          </tr>
          <>
            {[11, 12, 13].map((index) => (
              <tr key={index}>
                <td>{er[`$B$${index}`]}</td>
                <td>{er[`$C$${index}`]}</td>
                <td>{er[`$D$${index}`]}</td>
                <td>{er[`$E$${index}`]}</td>
                <TD index={index} underlines={[12]}>
                  {er[`$F$${index}`]}
                </TD>
                <TD index={index} underlines={[12]}>
                  {er[`$G$${index}`]}
                </TD>
                <TD index={index} underlines={[12]}>
                  {er[`$H$${index}`]}
                </TD>
                <TD index={index} underlines={[12]}>
                  {er[`$I$${index}`]}
                </TD>
                <TD index={index} underlines={[12]}>
                  {er[`$J$${index}`]}
                </TD>
                <TD index={index} underlines={[12]}>
                  {er[`$K$${index}`]}
                </TD>
              </tr>
            ))}
          </>
          <tr>
            <td colSpan={10}>&nbsp;</td>
          </tr>
          <tr className={styles.grey}>
            <td></td>
            <td>Start</td>
            <td>BOM</td>
            <td>BOM</td>
            <td>End</td>
            <td>Years</td>
            <td>Months</td>
            <td>Days</td>
            <td>Hours</td>
            <td>Year Frac</td>
          </tr>
          <>
            {[16, 17, 18].map((index) => (
              <tr key={index}>
                <TD index={index}>{er[`$B$${index}`]}</TD>
                <TD index={index}>{er[`$C$${index}`]}</TD>
                <TD index={index}>{er[`$D$${index}`]}</TD>
                <TD index={index}>{er[`$E$${index}`]}</TD>
                <TD index={index} underlines={[17]}>
                  {er[`$F$${index}`]}
                </TD>
                <TD index={index} underlines={[17]}>
                  {er[`$G$${index}`]}
                </TD>
                <TD index={index} underlines={[17]}>
                  {er[`$H$${index}`]}
                </TD>
                <TD index={index} underlines={[17]}>
                  {er[`$I$${index}`]}
                </TD>
                <TD index={index} underlines={[17]}>
                  {er[`$J$${index}`]}
                </TD>
                <TD index={index} underlines={[17]}>
                  {er[`$K$${index}`]}
                </TD>
              </tr>
            ))}
          </>
          <tr>
            <td colSpan={10}>&nbsp;</td>
          </tr>
          <tr className={styles.grey}>
            <td></td>
            <td>Start</td>
            <td>BOM</td>
            <td>BOM</td>
            <td>End</td>
            <td>Years</td>
            <td>Months</td>
            <td>Days</td>
            <td></td>
            <td>Year Frac</td>
          </tr>
          <>
            {[21].map((index) => (
              <tr key={index}>
                <td>{er[`$B$${index}`]}</td>
                <td>{er[`$C$${index}`]}</td>
                <td>{er[`$D$${index}`]}</td>
                <td>{er[`$E$${index}`]}</td>
                <td>{er[`$F$${index}`]}</td>
                <td>{er[`$G$${index}`]}</td>
                <td>{er[`$H$${index}`]}</td>
                <td>{er[`$I$${index}`]}</td>
                <td>{er[`$J$${index}`]}</td>
                <td>{er[`$K$${index}`]}</td>
              </tr>
            ))}
          </>
          <tr>
            <td colSpan={10}>&nbsp;</td>
          </tr>
          <>
            {[23, 24, 25].map((index) => (
              <tr key={index}>
                <td>{er[`$B$${index}`]}</td>
                <td>{er[`$C$${index}`]}</td>
                <td>{er[`$D$${index}`]}</td>
                <td>{er[`$E$${index}`]}</td>
                <td>{er[`$F$${index}`]}</td>
                <td colSpan={3}>{er[`$G$${index}`]}</td>
                <td>{er[`$J$${index}`]}</td>
                <td>{er[`$K$${index}`]}</td>
              </tr>
            ))}
          </>
        </Table>
      </Grid>

      <Grid className={styles.container} item sm={12} md={8}>
        <Table>
          <tr className={styles.blue}>
            <td colSpan={6}>Salary Calculations</td>
          </tr>
          <tr className={styles.grey}>
            <td>Rate</td>
            <td>Long</td>
            <td>Hourly</td>
            <td>Monthly</td>
            <td>#Months</td>
            <td>Amount</td>
          </tr>
          <>
            {Array.from({ length: 19 }, (_, i) => i + 29).map((index) => (
              <tr key={index}>
                <td>{er[`$B$${index}`]}</td>
                <td>{er[`$C$${index}`]}</td>
                <td>{er[`$D$${index}`]}</td>
                <td>{er[`$E$${index}`]}</td>
                <td>{er[`$F$${index}`]}</td>
                <td>{er[`$G$${index}`]}</td>
              </tr>
            ))}
          </>
        </Table>
      </Grid>

      <Grid className={styles.container} item sm={12} md={4}>
        <Table>
          <tr className={styles.blue}>
            <td colSpan={6}>Contribution Calculations</td>
          </tr>
          <>
            {Array.from({ length: 26 }, (_, i) => i + 28).map((index) => (
              <tr key={index}>
                <TD index={index} underlines={[32, 38, 45]}>
                  {er[`$I$${index}`]}
                </TD>
                <TD index={index} underlines={[32, 38, 45]}>
                  {er[`$L$${index}`]}
                </TD>
              </tr>
            ))}
          </>
        </Table>
      </Grid>

      <Grid className={styles.container} item sm={12} md={8}>
        <Table>
          <tr className={styles.blue}>
            <td colSpan={6}>Benefit Factors</td>
          </tr>
          <>
            {Array.from({ length: 22 }, (_, i) => i + 49).map((index) => (
              <tr key={index}>
                <td>{er[`$B$${index}`]}</td>
                <td>{er[`$C$${index}`]}</td>
                <td>{er[`$D$${index}`]}</td>
                <td>{er[`$E$${index}`]}</td>
                <td>{er[`$F$${index}`]}</td>
                <td>{er[`$G$${index}`]}</td>
              </tr>
            ))}
          </>
        </Table>
      </Grid>

      <Grid className={styles.container} item sm={12} md={4}>
        <Table>
          <tr className={styles.blue}>
            <td colSpan={6}>2xEECI Benefit</td>
          </tr>
          <>
            {[57, 58, 59, 60, 61].map((index) => (
              <tr key={index}>
                <td>{er[`$I$${index}`]}</td>
                <td>{er[`$L$${index}`]}</td>
              </tr>
            ))}
          </>
        </Table>

        <Table>
          <tr className={styles.blue}>
            <td colSpan={6}>Service Retirement Benefit</td>
          </tr>
          <>
            {[65, 66, 67, 68, 69].map((index) => (
              <tr key={index}>
                <td>{er[`$I$${index}`]}</td>
                <td>{er[`$L$${index}`]}</td>
              </tr>
            ))}
          </>
        </Table>
      </Grid>

      {/** Portability Retirement Benefit */}
      <Grid className={styles.container} item sm={12} md={8}>
        <Table>
          <tr className={styles.blue}>
            <td colSpan={4}>{er[`$B$71`]}</td>
          </tr>
          <>
            {toRows(12, 72, [72]).map(([index, isHeader]) => (
              <tr
                key={index as number}
                className={clsx({ [styles.grey]: isHeader })}
              >
                <td>{er[`$B$${index}`]}</td>
                <td>{er[`$C$${index}`]}</td>
                <td>{er[`$D$${index}`]}</td>
                <td>{er[`$E$${index}`]}</td>
              </tr>
            ))}
          </>
        </Table>
      </Grid>

      <Grid className={styles.container} item sm={12} md={4}>
        {/** Disability Retirement Benefit */}
        <Table>
          <tr className={styles.blue}>
            <td colSpan={4}>{er[`$G$71`]}</td>
          </tr>
          <>
            {[72, 73, 74, 75, 76, 77].map((index) => (
              <tr key={index}>
                <td>{er[`$G$${index}`]}</td>
                <td>{er[`$K$${index}`]}</td>
              </tr>
            ))}
          </>
        </Table>
        {/** Service Purchase Tests */}
        <Table>
          <tr className={styles.blue}>
            <td colSpan={4}>{er[`$G$79`]}</td>
          </tr>
          <>
            {[80, 81].map((index) => (
              <tr key={index}>
                <td>{er[`$G$${index}`]}</td>
                <td>{er[`$K$${index}`]}</td>
              </tr>
            ))}
          </>
        </Table>
      </Grid>

      {/** Service Purchase Retirement Benefit */}
      <Grid className={styles.container} item sm={12} md={8}>
        <Table>
          <tr className={styles.blue}>
            <td colSpan={4}>{er[`$B$85`]}</td>
          </tr>
          <>
            {Array.from({ length: 28 }, (_, i) => i + 86).map((index) => (
              <tr key={index}>
                <td>{er[`$B$${index}`]}</td>
                <td>{er[`$C$${index}`]}</td>
                <td>{er[`$D$${index}`]}</td>
                <td>{er[`$E$${index}`]}</td>
              </tr>
            ))}
          </>
        </Table>
      </Grid>

      <Grid className={styles.container} item sm={12} md={4}>
        {/** Unmodified Benefits & 415 Limits */}
        <Table>
          <tr className={styles.blue}>
            <td colSpan={4}>{er[`$G$85`]}</td>
          </tr>
          <>
            {toRows(27, 86, [87, 91, 98, 107]).map(([index, isHeader]) => (
              <tr
                key={index as number}
                className={clsx({ [styles.grey]: isHeader })}
              >
                <td>{er[`$G$${index}`]}</td>
                <td>{er[`$J$${index}`]}</td>
              </tr>
            ))}
          </>
        </Table>
      </Grid>

      {/** Option Factors */}
      <Grid className={styles.container} item sm={12} md={12}>
        <Table>
          <tr className={styles.blue}>
            <td colSpan={10}>{er[`$B$115`]}</td>
          </tr>
          <>
            {Array.from({ length: 21 }, (_, i) => i + 116).map((index) => (
              <tr key={index}>
                <td>{er[`$B$${index}`]}</td>
                <td>{er[`$C$${index}`]}</td>
                <td>{er[`$D$${index}`]}</td>
                <td></td>
                <td>{er[`$F$${index}`]}</td>
                <td>{er[`$H$${index}`]}</td>
                <td></td>
                <td>{er[`$J$${index}`]}</td>
                <td>{er[`$L$${index}`]}</td>
              </tr>
            ))}
          </>
        </Table>
      </Grid>

      <Grid className={styles.container} item sm={12} md={4}>
        <Table>
          <tr className={styles.blue}>
            <td colSpan={3}>For Internal Use Only</td>
          </tr>
          <>
            {[139, 140, 141, 142].map((index) => (
              <tr key={index}>
                <td>{er[`$B$${index}`]}</td>
                <td>{er[`$D$${index}`]}</td>
              </tr>
            ))}
          </>
        </Table>
      </Grid>

      <Grid className={styles.container} item sm={12} md={4}>
        <Table>
          <tr className={styles.blue}>
            <td colSpan={3}>Portability Change</td>
          </tr>
          <>
            {[139, 140].map((index) => (
              <tr key={index}>
                <td>{er[`$F$${index}`]}</td>
                <td>{er[`$J$${index}`]}</td>
              </tr>
            ))}
          </>
        </Table>
      </Grid>

      <Grid className={styles.container} item sm={12} md={4}>
        <Table>
          <tr className={styles.blue}>
            <td colSpan={3}>Modified Retirement Benefits</td>
          </tr>
          <>
            {[143, 144, 145].map((index) => (
              <tr key={index}>
                <td>{er[`$G$${index}`]}</td>
                <td>{er[`$K$${index}`]}</td>
              </tr>
            ))}
          </>
        </Table>
      </Grid>
    </Grid>
  );
});

export { Export };
