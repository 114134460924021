import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";

import { CalculationErrorContext } from "../state/CalculationErrors";

import CalculationTextField, {
  CalculationTextFieldProps,
} from "./CalculationTextField";
import { useId } from "./useId";

const PASSWORD_ERROR =
  "Password must contain at least one uppercase, lowercase, number and special character with a length of at least 8 characters.";
const PASSWORD_REGEX =
  /(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{6,}/;

interface PasswordFieldProps extends CalculationTextFieldProps {
  enforcePasswordValidation: boolean;
}

export default ({
  type,
  value,
  enforcePasswordValidation,
  ...props
}: PasswordFieldProps) => {
  const id = useId();
  const calculationErrorContext = useContext(CalculationErrorContext);
  const [showPassword, setShowPassword] = useState(false);
  const currentType = showPassword ? "text" : "password";
  const passwordError =
    enforcePasswordValidation &&
    (!value || !PASSWORD_REGEX.test(value as string));

  useEffect(() => {
    if (passwordError) {
      calculationErrorContext.addError(id, PASSWORD_ERROR);
    }

    return () => {
      calculationErrorContext.removeError(id, PASSWORD_ERROR);
    };
  }, [calculationErrorContext, value, passwordError, id]);

  const adornment = (
    <InputAdornment position="end">
      <IconButton onClick={() => setShowPassword(!showPassword)}>
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <CalculationTextField
      {...props}
      value={value}
      type={currentType}
      hasErrors={passwordError}
      endAdornment={adornment}
    />
  );
};
