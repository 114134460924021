import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { AttachFile } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";

import Button from "../../common/Button";
import CalculationSection from "../../common/CalculationSection";
import { AdminContext, ServerFile } from "../../state/Admin";

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
  button: {
    marginTop: "1rem",
  },
}));

const Spreadsheet: React.FC = observer(() => {
  const styles = useStyles();
  const adminContext = useContext(AdminContext);
  const [serverFile, setServerFile] = useState<ServerFile | undefined>();
  const [file, setFile] = useState<File | undefined>();

  useEffect(() => {
    if (!serverFile) {
      // tslint:disable-next-line: no-floating-promises
      (async () => {
        const result = await adminContext.getFiles();
        const [file] = result.files;
        setServerFile(file);
      })();
    }
  }, [serverFile, adminContext]);

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const onFileUpload = async () => {
    await adminContext.addFile(file!);
    setServerFile(undefined);
    setFile(undefined);
  };

  return (
    <CalculationSection>
      <Grid container item sm={12}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography>Current spreadsheet:</Typography>
          </Grid>
          {serverFile ? (
            <Grid item>
              <Typography>{serverFile.fileName}</Typography>
              <Typography>{serverFile.created}</Typography>
            </Grid>
          ) : (
            <Grid item>
              <Typography>None</Typography>
            </Grid>
          )}
        </Grid>
        <Grid container item alignItems="center" justifyContent="flex-end">
          <input
            accept=".xlsx, .xls"
            className={styles.input}
            id="icon-button-file"
            type="file"
            onChange={onFileChange}
          />
          <label htmlFor="icon-button-file">
            <IconButton color="primary" component="span">
              <AttachFile />
            </IconButton>
          </label>
          <Button
            disabled={!file}
            variant="contained"
            color="primary"
            onClick={onFileUpload}
          >
            Upload
          </Button>
        </Grid>
      </Grid>
    </CalculationSection>
  );
});

export default Spreadsheet;
