import DialogBase from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React, { FC } from "react";

import CalculationHeader from "./CalculationHeader";

const useDialogTitleStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

interface DialogTitleProps {
  title: string;
  onClose: () => void;
}

const DialogTitle: FC<DialogTitleProps> = (props) => {
  const { onClose, title } = props;
  const classes = useDialogTitleStyles();
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <CalculationHeader title={title} />
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

export const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export interface DialogProps {
  open: boolean;
  title: string;
  setOpen: (open: boolean) => void;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
}

const Dialog: FC<DialogProps> = ({
  open,
  setOpen,
  title,
  maxWidth,
  children,
}) => (
  <DialogBase maxWidth={maxWidth} open={open} onClose={() => setOpen(false)}>
    <DialogTitle onClose={() => setOpen(false)} title={title} />
    <DialogContent dividers>
      <Grid container spacing={2}>
        {children}
      </Grid>
    </DialogContent>
  </DialogBase>
);

export default Dialog;
