import CssBaseline from "@material-ui/core/CssBaseline";
import Hidden from "@material-ui/core/Hidden";
import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import clsx from "clsx";
import React, { FC, useContext } from "react";
import { useLocation } from "react-router";

import Header from "./components/Header";
import NavMenu from "./components/NavMenu";
import { RootContext } from "./state/root";
import { Colors } from "./utilities/colors";

declare module "@material-ui/core/styles/overrides" {
  export interface ComponentNameToClassKey {
    MuiAlert: any;
    MuiDataGrid: any;
  }
}

let theme = createTheme({
  palette: {
    primary: {
      light: "#4473a6",
      main: "#004877",
      dark: "#00214b",
    },
    secondary: {
      light: "#c1e4c0",
      main: "#90B290",
      dark: "#628262",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiAlert: {
      root: {
        marginTop: "1rem",
      },
    },
    MuiButton: {
      root: {
        minWidth: "5rem",
        height: "1.5rem",
      },
      contained: {
        color: Colors.White,
        borderRadius: "0.2rem",
        boxShadow: "none",
        marginRight: ".5rem",
        minWidth: "5rem",
        height: "1.5rem",
        backgroundColor: Colors.VibrantBlue,
        "&:active": {
          boxShadow: "none",
        },
        "&:hover": {
          backgroundColor: Colors.VibrantBlueHover,
        },
      },
      label: {
        marginTop: ".2rem",
      },
      containedSizeLarge: {
        height: "2.5rem",
      },
      containedSecondary: {
        color: Colors.White,
        backgroundColor: Colors.Spring,
        "&:hover": {
          backgroundColor: Colors.SpringHover,
        },
      },
      containedPrimary: {
        backgroundColor: Colors.MillimanBlue,
        "&:hover": {
          backgroundColor: Colors.MillimanBlueHover,
        },
      },
      text: {
        color: Colors.VibrantBlue,
        textTransform: "none",
        textDecoration: "underline !important",
        borderRadius: "0.2rem",
      },
    },
    MuiDataGrid: {
      root: {
        "& .MuiDataGrid-cell:focus": {
          outline: "none",
        },
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: Colors.Charcoal,
        display: "flex",
        justifyContent: "space-between",
      },
      paperAnchorRight: {
        backgroundColor: Colors.CalculationsBackground,
        padding: "1rem",
        paddingTop: "1.5rem",
      },
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: "620px",
      },
    },
    MuiFormControlLabel: {
      disabled: {
        color: Colors.Black,
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: Colors.White,
      },
    },
    MuiTabs: {
      root: {
        borderBottom: "1px solid #e8e8e8",
        marginBottom: "1rem",
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: Colors.VibrantBlue,
      },
    },
    MuiTab: {
      root: {
        textTransform: "none",
        margin: "0 16px",
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up("md")]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        backgroundColor: Colors.White,
        borderRadius: "inherit",
        padding: ".6rem",
      },
    },
    MuiInputLabel: {
      outlined: {
        marginTop: "-.5rem",
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
        width: "2.5rem",
        height: "2.5rem",
      },
      colorPrimary: {
        color: Colors.MillimanBlue,
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
        fontSize: "0.9rem",
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#404854",
      },
    },
    MuiListItem: {
      button: {
        "&:hover": {
          backgroundColor: Colors.NavMenuGrey,
          textDecoration: "none !important",
        },
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
        marginRight: 0,
        "& svg": {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

const drawerWidth = 256;
const useStyles = makeStyles({
  "@global": {
    body: {
      background: Colors.NormalBackground,
    },
    "input::-ms-clear, input::-ms-reveal": {
      display: "none",
    },
    ".no-print": {
      "@media print": {
        display: "none",
      },
    },
  },
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  main: {
    flex: 1,
    padding: theme.spacing(6, 4),
    background: Colors.NormalBackground,
  },
  footer: {
    background: Colors.NormalBackground,
  },
});

export const Layout: FC<{ children: JSX.Element[] | JSX.Element }> = ({
  children,
}) => {
  const classes = useStyles();
  const rootContext = useContext(RootContext);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { pathname } = useLocation();
  const inBackupCalculations = pathname.startsWith("/BackupCalculations");
  const showHeader =
    !!rootContext.user &&
    !rootContext.user.needsPasswordChange &&
    !inBackupCalculations;
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        {showHeader && (
          <nav className={clsx(classes.drawer, "no-print")}>
            <Hidden smUp>
              <NavMenu
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
              />
            </Hidden>
            <Hidden xsDown>
              <NavMenu PaperProps={{ style: { width: drawerWidth } }} />
            </Hidden>
          </nav>
        )}
        <div className={classes.app}>
          <Header />
          {showHeader ? (
            <main className={classes.main}>{children}</main>
          ) : (
            children
          )}
          <footer className={classes.footer}></footer>
        </div>
      </div>
    </ThemeProvider>
  );
};
