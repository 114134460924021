import { Grid } from "@material-ui/core";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { Theme, withStyles } from "@material-ui/core/styles";
import { saveAs } from "file-saver";
import React, { FC, useContext, useState } from "react";

import Button from "../../common/Button";
import CalculationDate from "../../common/CalculationDate";
import CalculationHeader from "../../common/CalculationHeader";
import CalculationTextField from "../../common/CalculationTextField";
import Dialog from "../../common/Dialog";
import { AdminContext, ExportResults } from "../../state/Admin";
import {
  CalculationErrorContext,
  CalculationErrors,
} from "../../state/CalculationErrors";
import { RootContext } from "../../state/root";
import { isUserFullAdmin } from "../../utilities/user";

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: "space-between",
  },
}))(MuiDialogActions);

export interface ExportResultsProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const ExportResultsDialog: FC<ExportResultsProps> = ({ open, setOpen }) => {
  const [request, setRequest] = useState<ExportResults>({});
  const adminContext = useContext(AdminContext);
  const { user } = useContext(RootContext);
  const updateRequest = (request: Partial<ExportResults>) => {
    setRequest((oldRequest) => ({ ...oldRequest, ...request }));
  };
  const handleExportResults = async (isFinal: boolean) => {
    const result = await adminContext.exportResults({ ...request, isFinal });
    saveAs(result, "export.csv");
    setRequest({});
    setOpen(false);
  };

  return (
    <Dialog
      setOpen={() => setOpen(false)}
      open={open}
      title="Export results"
      maxWidth="md"
    >
      <DialogContent>
        <Grid container item md={12} spacing={2}>
          <Grid item md={6}>
            <CalculationTextField
              label="Employee #:"
              value={request.employeeNumber}
              onChange={(value) =>
                updateRequest({ employeeNumber: value as string })
              }
              mustHaveValue={false}
              labelWidth={10}
              type="text"
            />
          </Grid>
          <Grid item md={6}>
            <CalculationTextField
              label="Last name:"
              value={request.lastName}
              onChange={(value) => updateRequest({ lastName: value as string })}
              mustHaveValue={false}
              labelWidth={10}
            />
          </Grid>
          <CalculationHeader
            variant="secondary"
            title={`Date of membership:`}
          />
          <Grid item md={6}>
            <CalculationDate
              label="Start:"
              value={request.startDateOfMembership}
              onChange={(value) =>
                updateRequest({ startDateOfMembership: value })
              }
              mustHaveValue={false}
              labelWidth={5}
            />
          </Grid>
          <Grid item md={6}>
            <CalculationDate
              label="End:"
              value={request.endDateOfMembership}
              onChange={(value) =>
                updateRequest({ endDateOfMembership: value })
              }
              mustHaveValue={false}
              labelWidth={5}
            />
          </Grid>
          <CalculationHeader
            variant="secondary"
            title={`Date of calculation:`}
          />
          <Grid item md={6}>
            <CalculationDate
              label="Start:"
              value={request.startDateOfCalculation}
              onChange={(value) =>
                updateRequest({ startDateOfCalculation: value })
              }
              mustHaveValue={false}
              labelWidth={5}
            />
          </Grid>
          <Grid item md={6}>
            <CalculationDate
              label="End:"
              value={request.endDateOfCalculation}
              onChange={(value) =>
                updateRequest({ endDateOfCalculation: value })
              }
              mustHaveValue={false}
              labelWidth={5}
            />
          </Grid>
          <CalculationHeader
            variant="secondary"
            title={`Date of retirement:`}
          />
          <Grid item md={6}>
            <CalculationDate
              label="Start:"
              value={request.startDateOfRetirement}
              onChange={(value) =>
                updateRequest({ startDateOfRetirement: value })
              }
              mustHaveValue={false}
              labelWidth={5}
            />
          </Grid>
          <Grid item md={6}>
            <CalculationDate
              label="End:"
              value={request.endDateOfRetirement}
              onChange={(value) =>
                updateRequest({ endDateOfRetirement: value })
              }
              mustHaveValue={false}
              labelWidth={5}
            />
          </Grid>
        </Grid>
        <DialogActions>
          <Button
            autoFocus
            onClick={async () => {
              await handleExportResults(false);
            }}
            color="secondary"
            size="large"
          >
            Export results
          </Button>
          {isUserFullAdmin(user) && (
            <Button
              autoFocus
              onClick={async () => {
                await handleExportResults(true);
              }}
              color="primary"
              size="large"
            >
              Export Final Results
            </Button>
          )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default (props: ExportResultsProps) => (
  <CalculationErrorContext.Provider value={new CalculationErrors(false)}>
    <ExportResultsDialog {...props} />
  </CalculationErrorContext.Provider>
);
