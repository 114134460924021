import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import MuiLink from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import CalculationDate from "../../common/CalculationDate";
import CalculationHeader from "../../common/CalculationHeader";
import CalculationLabel from "../../common/CalculationLabel";
import CalculationSection from "../../common/CalculationSection";
import CalculationSelect, { MenuItem } from "../../common/CalculationSelect";
import CalculationTextField from "../../common/CalculationTextField";
import { CalculationContext } from "../../state/Calculation";
import { GuardContext } from "../../state/Guard";
import { RootContext } from "../../state/root";
import { filterUserBenefitOptions, isUserAdmin } from "../../utilities/user";

import { CalculationPageProps } from "./CalculationPage";

const AnnuitantInformationToolTip = () => (
  <>
    <p>
      Annuitant: The beneficiary of an annuity, also known as the person you
      chose as your beneficiary.
    </p>
  </>
);

const ServicePurchaseToolTip = () => (
  <>
    <p>
      Purchase of Time: Effective January 1, 2002, any Member who is vested and
      has five or more years of City Service as a Member may purchase up to five
      years of creditable service at the time of retirement, provided that no
      purchase made shall result in a member's total years of creditable service
      exceeding 30. Payment shall be in a lump sum on an after-tax basis, by a
      direct trustee-to-trustee transfer to the Retirement Fund from a plan
      qualified under Internal Revenue Code Section 457 or 403 (b), or effective
      September 1, 2002, by a direct rollover to the Retirement Fund from a
      individual retirement account or annuity qualified under Internal Revenue
      Code 408, or by irrevocably electing to apply the amount equal to 1.5
      times the Member's overtime contributions, plus interest that could be
      withdrawn.
    </p>
    <p>
      Purchase of creditable service is not treated as an employee contribution
      and does not affect the 150 percent refund of accumulated normal
      contributions and interest or the guarantee that a member's pension will
      be at least the actuarial equivalent of 200 percent of accumulated normal
      contributions.
    </p>
  </>
);

const useStyles = makeStyles((theme) => ({
  benefit: {
    marginLeft: "1rem",
  },
  link: {
    cursor: "pointer",
  },
}));

const BenefitElections: React.FC<CalculationPageProps> = observer(() => {
  const calculationContext = useContext(CalculationContext);
  const rootContext = useContext(RootContext);
  const history = useHistory<string[]>();
  const guard = useContext(GuardContext);
  const style = useStyles();

  const { currentCalculation } = calculationContext;
  const isAdmin = isUserAdmin(rootContext.user);

  useEffect(() => {
    if (
      currentCalculation?.benefitOptions &&
      !filterUserBenefitOptions(
        currentCalculation?.benefitOptions,
        isAdmin
      )?.find((option) => option.value === currentCalculation.benefitOption)
    ) {
      runInAction(() => {
        currentCalculation.benefitOption = "All";
      });
    }
  });

  useEffect(() => {
    if (
      currentCalculation?.servicePurchaseSelected === "No" ||
      currentCalculation?.results?.servicePurchaseAvailable === "No"
    ) {
      runInAction(() => {
        currentCalculation.amountOfServiceToPurchase = 0;
      });
    }
  });

  useEffect(() => {
    if (currentCalculation?.ssAdjustment === "No") {
      runInAction(() => {
        currentCalculation.ssAtAge62 = 0;
      });
    }
  });

  useEffect(() => {
    if (currentCalculation?.annuitantType === "No Annuitant") {
      runInAction(() => {
        currentCalculation.annuitantDateOfBirth = undefined;
      });
    }
  });

  if (!currentCalculation) {
    return null;
  }

  return (
    <Grid container>
      <CalculationHeader title="Benefit elections" />

      {currentCalculation.benefitType !== "Death-Immediate" && (
        <>
          <CalculationHeader
            title="Annuitant information"
            variant="secondary"
            tooltip={<AnnuitantInformationToolTip />}
          />
          <CalculationSection>
            <CalculationSelect
              label="Annuitant type:"
              value={currentCalculation.annuitantType}
              onChange={(value) => (currentCalculation.annuitantType = value)}
            >
              <MenuItem value="Spouse">Spouse</MenuItem>
              <MenuItem value="Non-Spouse">Non-Spouse</MenuItem>
              <MenuItem value="No Annuitant">No Annuitant</MenuItem>
            </CalculationSelect>
            {currentCalculation.annuitantType !== "No Annuitant" && (
              <CalculationDate
                label="Annuitant date of birth:"
                value={currentCalculation.annuitantDateOfBirth}
                onChange={(date) =>
                  (currentCalculation.annuitantDateOfBirth = date)
                }
              />
            )}
          </CalculationSection>
        </>
      )}

      <CalculationHeader title="Benefit form" variant="secondary" />
      <CalculationSection>
        <CalculationSelect
          label="Benefit option:"
          value={currentCalculation.benefitOption}
          onChange={(value) => (currentCalculation.benefitOption = value)}
        >
          <MenuItem key="All" value="All">
            Show all options
          </MenuItem>
          {filterUserBenefitOptions(
            currentCalculation.benefitOptions,
            isAdmin
          )?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </CalculationSelect>

        <Typography paragraph className={style.benefit}>
          To see a description of these options, click on&nbsp;
          <Link
            component={MuiLink}
            className={style.link}
            onClick={async () => {
              if (!(await guard.showGuard())) {
                return;
              }
              history.push("/BenefitOptions");
            }}
            underline="always"
          >
            benefit options
          </Link>
          .
        </Typography>

        {isAdmin && (
          <>
            <CalculationSelect
              label="Social security adjustments:"
              value={currentCalculation.ssAdjustment}
              onChange={(value) => (currentCalculation.ssAdjustment = value)}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </CalculationSelect>
            {currentCalculation.ssAdjustment === "Yes" && (
              <CalculationTextField
                label="Social security benefit at age 62:"
                value={currentCalculation.ssAtAge62}
                onChange={(value) =>
                  (currentCalculation.ssAtAge62 = value as number)
                }
                type="number"
                format="dollar"
              />
            )}
          </>
        )}
      </CalculationSection>

      <CalculationHeader
        title="Service purchase"
        variant="secondary"
        tooltip={<ServicePurchaseToolTip />}
      />
      <CalculationSection>
        <CalculationLabel
          label="Is service purchase available:"
          value={currentCalculation.results?.servicePurchaseAvailable}
        />
        {currentCalculation.results?.servicePurchaseAvailable === "Yes" && (
          <>
            <CalculationLabel
              label="Years of service purchase needed to retire:"
              value={currentCalculation.results?.yearsNeededToRetire}
              minimumFractionDigits={5}
            />
            <CalculationLabel
              label="Maximum available years of service purchase:"
              value={currentCalculation.results?.maxEligibleServicePurchase}
              minimumFractionDigits={5}
            />
            <CalculationSelect
              label="Are you purchasing additional service?:"
              value={currentCalculation.servicePurchaseSelected}
              onChange={(value) =>
                (currentCalculation.servicePurchaseSelected = value)
              }
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </CalculationSelect>
            {currentCalculation.servicePurchaseSelected === "Yes" && (
              <>
                <CalculationTextField
                  label="Years of service to purchase:"
                  value={currentCalculation.amountOfServiceToPurchase}
                  onChange={(value) =>
                    (currentCalculation.amountOfServiceToPurchase =
                      value as number)
                  }
                  validation={(value: number | string) => {
                    if (
                      value >
                      currentCalculation.results?.maxEligibleServicePurchase!
                    ) {
                      return "Years of purchase cannot exceed maximum allowable service purchase";
                    }
                  }}
                  type="number"
                  maxLength={5}
                />
                {isAdmin && (
                  <CalculationSelect
                    label="Tax Treatment of Service Purchase Payment:"
                    value={currentCalculation.taxTreatmentOfPayment}
                    onChange={(value) =>
                      (currentCalculation.taxTreatmentOfPayment = value)
                    }
                  >
                    <MenuItem value="Pre-Tax">Pre-Tax</MenuItem>
                    <MenuItem value="Post-Tax">Post-Tax</MenuItem>
                  </CalculationSelect>
                )}
                {currentCalculation.isProjection === "N" &&
                  currentCalculation.results
                    .isEligibleForRetirementWithoutPurchase === "N" && (
                    <CalculationTextField
                      label="Total benefit contributions at last quarter:"
                      value={
                        currentCalculation.totalBenefitContributionsLastQuarter
                      }
                      onChange={(value) =>
                        (currentCalculation.totalBenefitContributionsLastQuarter =
                          value as number)
                      }
                      type="number"
                      format="dollar"
                    />
                  )}
              </>
            )}
          </>
        )}
      </CalculationSection>
    </Grid>
  );
});

export default BenefitElections;
