import { action, makeObservable, observable } from "mobx";
import React, { ReactNode } from "react";

export interface AlertProps {
  type: "success" | "error" | "warning" | "info";
  content: ReactNode | ReactNode[];
}

class AlertStore {
  @observable
  currentAlert?: AlertProps;

  constructor() {
    makeObservable(this);
  }

  @action
  showAlert(props?: AlertProps): void {
    this.currentAlert = props;
  }
}

const AlertState = new AlertStore();
const AlertContext = React.createContext(AlertState);

export { AlertContext, AlertState };
