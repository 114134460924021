import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/styles/makeStyles";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";

import Button from "../../common/Button";
import CalculationHeader from "../../common/CalculationHeader";
import CalculationSection from "../../common/CalculationSection";
import CalculationSelect, { MenuItem } from "../../common/CalculationSelect";
import CalculationTextField from "../../common/CalculationTextField";
import { AdminContext, CalculationListItem } from "../../state/Admin";
import { AlertContext } from "../../state/Alert";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "80rem",
    minWidth: "40rem",
  },
  spacing: {
    marginBottom: "1rem",
    margin: "1rem",
  },
}));

const CreateCalculation = observer(() => {
  const styles = useStyles();
  const [estimateType, setEstimateType] = useState("Estimate");
  const [copyExisting, setCopyExisting] = useState("No");
  const [calculationToCopy, setCalculationToCopy] = useState<string>();
  const [eeNumber, setEeNumber] = useState("");
  const [items, setItems] = useState<CalculationListItem[]>([]);
  const alertContext = useContext(AlertContext);
  const adminContext = useContext(AdminContext);

  useEffect(() => {
    // tslint:disable-next-line: no-floating-promises
    (async () => {
      const result = await adminContext.getPaginatedCalculationList(
        eeNumber,
        "",
        "calculationName",
        "asc",
        0,
        10
      );
      setItems(result.calculations);
    })();
  }, [adminContext, eeNumber]);

  const foundCalculations = items
    .filter((li) => li.userName === eeNumber)
    .sort((a, b) => a.calculationName.localeCompare(b.calculationName));
  const foundUser = !!foundCalculations.length;
  const [calculation] = foundCalculations;

  const handleCreateCalculation = async () => {
    const success = await adminContext.createCalculation(
      calculation.userId,
      estimateType === "Final",
      copyExisting === "Yes"
        ? calculationToCopy ?? foundCalculations[0].uniqueId
        : undefined
    );
    setEeNumber("");
    setCalculationToCopy(undefined);
    setEstimateType("Estimate");
    setCopyExisting("No");
    alertContext.showAlert({
      type: success ? "success" : "error",
      content: success ? "Calculation created" : "Error",
    });
  };

  return (
    <Grid container className={styles.container}>
      <CalculationHeader title="Create calculation" variant="secondary" />
      <CalculationSection className={styles.spacing}>
        <CalculationTextField
          label="EE number:"
          value={eeNumber}
          onChange={(value) => setEeNumber(value as string)}
          mustHaveValue={false}
        />
        {foundUser && (
          <>
            <CalculationSelect
              label="Estimate or final:"
              value={estimateType}
              onChange={setEstimateType}
            >
              <MenuItem value="Estimate">Estimate</MenuItem>
              <MenuItem value="Final">Final</MenuItem>
            </CalculationSelect>
            <CalculationSelect
              label="Copy Existing?:"
              value={copyExisting}
              onChange={setCopyExisting}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </CalculationSelect>
            {copyExisting === "Yes" && (
              <CalculationSelect
                label="Calculation to copy:"
                value={calculationToCopy ?? foundCalculations[0].uniqueId}
                onChange={setCalculationToCopy}
              >
                {foundCalculations.map((c) => (
                  <MenuItem value={c.uniqueId}>{c.calculationName}</MenuItem>
                ))}
              </CalculationSelect>
            )}
          </>
        )}
      </CalculationSection>
      <Button
        style={{ marginLeft: "1rem" }}
        disabled={!foundUser}
        onClick={handleCreateCalculation}
        color="secondary"
      >
        Create
      </Button>
    </Grid>
  );
});

export default CreateCalculation;
