import { Grid, makeStyles, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";

import Button from "../../common/Button";
import CalculationDate from "../../common/CalculationDate";
import CalculationHeader from "../../common/CalculationHeader";
import CalculationLabel from "../../common/CalculationLabel";
import CalculationSection from "../../common/CalculationSection";
import CalculationTextField from "../../common/CalculationTextField";
import { CalculationContext } from "../../state/Calculation";
import {
  CalculationErrorContext,
  CalculationErrors,
} from "../../state/CalculationErrors";

const useStyles = makeStyles((theme) => ({
  paragraph: {
    marginLeft: "1rem",
    marginBottom: "1rem",
  },
  note: {
    marginTop: "1rem",
    marginLeft: "1rem",
    marginBottom: "1rem",
  },
  buttons: {
    marginTop: "1rem",
  },
  buttonRight: {
    float: "right",
    marginRight: 0,
  },
}));

const RepaymentOfContributionsToolTip = () => (
  <>
    <p>
      Repayment of Contributions: If you have already re-paid your contributions
      this should already be reflected in your membership date and contribution
      totals. If you have questions please contact the Retirement Department.
    </p>
  </>
);

const RepaymentOfContributions: React.FC = observer(() => {
  const styles = useStyles();
  const calculationContext = useContext(CalculationContext);
  const calculationErrorContext = useContext(CalculationErrorContext);

  useEffect(() => {
    if (!calculationContext.calculations) {
      // tslint:disable-next-line: no-floating-promises
      calculationContext.load();
    }
  }, [calculationContext]);

  if (!calculationContext.calculations) {
    return null;
  }

  const [repayment] = calculationContext.calculations.filter(
    (c) => c.isRepayment
  );

  if (!repayment) {
    return null;
  }

  const hasResult =
    !!repayment.results?.totalRepaymentAmount ||
    !!repayment.results?.amountPerQuarter;

  return (
    <>
      <Grid container>
        <CalculationHeader
          title="Repayment of contributions"
          variant="secondary"
          tooltip={<RepaymentOfContributionsToolTip />}
        />
        <Typography className={styles.paragraph}>
          If you withdrew your contributions after termination and were later
          rehired, you are eligible to regain your prior service by repaying
          your withdrawn contributions plus interest.
        </Typography>
        <Typography className={styles.paragraph}>
          Enter the following information to calculate the repayment amount.
        </Typography>
        <CalculationHeader
          title="Participant information"
          variant="secondary"
        />
        <CalculationSection>
          <CalculationTextField
            type="number"
            format="dollar"
            label="Amount withdrawn:"
            value={repayment.amountWithdrawn}
            onChange={(value) => (repayment.amountWithdrawn = value as number)}
          />
          <CalculationDate
            label="Date withdrawn:"
            value={repayment.dateWithdrawn}
            onChange={(value) => (repayment.dateWithdrawn = value)}
          />
          <CalculationTextField
            type="number"
            label="Service regained:"
            value={repayment.serviceRegained}
            maxLength={5}
            onChange={(value) => (repayment.serviceRegained = value as number)}
          />
          <CalculationDate
            label="Date of membership:"
            value={repayment.dateOfMembership}
            onChange={(value) => (repayment.dateOfMembership = value)}
          />
          <CalculationDate
            label="Date of termination:"
            value={repayment.lastDayOfEmployment}
            onChange={(value) => (repayment.lastDayOfEmployment = value)}
          />
          <CalculationDate
            label="Repayment Date:"
            value={repayment.repaymentDate}
            onChange={(value) => (repayment.repaymentDate = value)}
          />
        </CalculationSection>

        {hasResult && (
          <>
            <CalculationHeader title="Results" variant="secondary" />
            <CalculationSection>
              <CalculationLabel
                format="dollar"
                label="Repayment amount:"
                value={repayment.results?.totalRepaymentAmount}
                minimumFractionDigits={2}
              />
              <CalculationLabel
                format="dollar"
                label="Amount per quarter:"
                value={repayment.results?.amountPerQuarter}
                minimumFractionDigits={2}
              />
            </CalculationSection>
          </>
        )}

        <Typography className={styles.note}>
          Note that this is an estimate. The exact cost will be calculated by
          Staff.
        </Typography>
      </Grid>

      {calculationErrorContext.validateErrors &&
        calculationErrorContext.hasAnyErrors && (
          <Alert severity="error">
            {calculationErrorContext.allErrorString}
          </Alert>
        )}

      <div className={styles.buttons}>
        <Button
          disabled={calculationErrorContext.hasFormErrors}
          color="primary"
          onClick={async () => {
            runInAction(() => (calculationErrorContext.validateErrors = true));
            if (calculationErrorContext.hasAnyErrors) {
              return;
            }
            await repayment.saveCalculation();
          }}
        >
          Calculate
        </Button>
        <Button
          color="gold"
          disabled={!hasResult || repayment?.isDirty}
          className={styles.buttonRight}
          onClick={() => window.print()}
        >
          Print
        </Button>
      </div>
    </>
  );
});

export default () => (
  <CalculationErrorContext.Provider value={new CalculationErrors(false)}>
    <RepaymentOfContributions />
  </CalculationErrorContext.Provider>
);
