import { BenefitOption } from "../state/Calculation";
import { User } from "../state/User";

export const isUserAdmin = (user: User | undefined) =>
  user?.userType === "Admin" || user?.userType === "TacomaAdmin";

export const isUserFullAdmin = (user: User | undefined) =>
  user?.userType === "Admin";

export const getUserTypeString = (user: User | undefined) => {
  switch (user?.userType) {
    case "TacomaAdmin": {
      return "Tacoma Admin";
    }
    default:
      return user?.userType;
  }
};

export const filterUserBenefitOptions = (
  benefitOptions: BenefitOption[] | undefined,
  isAdmin: boolean
): BenefitOption[] | undefined =>
  benefitOptions
    ?.filter((bo) => bo.display)
    .filter((bo) => bo.value !== "5YR" || isAdmin);
