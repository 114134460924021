import { makeStyles, Theme } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import MuiTextField, {
  OutlinedTextFieldProps,
} from "@material-ui/core/TextField";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import clsx from "clsx";
import { runInAction } from "mobx";
import React, { useState } from "react";
import NumberFormat from "react-number-format";

import { Colors } from "../utilities/colors";

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat<number> | null) => void;
  onChange: (event: { target: { value: number | undefined } }) => void;
}

const NumberFormatCustom = (props: NumberFormatCustomProps) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.floatValue,
          },
        });
      }}
      thousandSeparator
    />
  );
};

const useStyles = makeStyles<Theme, TextFieldProps>(() => ({
  textField: {
    flexGrow: 1,
    marginLeft: "2rem",
  },
  textFieldRoot: {
    borderRadius: ".5rem",
    backgroundColor: Colors.White,
    "& input": {
      background: ({ disabled }) =>
        disabled ? Colors.CalculationsBackground : Colors.White,
      borderRadius: "inherit",
    },
  },
  textFieldError: {
    "& fieldset": {
      borderColor: "red !important",
    },
  },
}));

export const validatePassword = (password: string) =>
  !!password.match(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*\-)(]).{8,}$/
  );

export const validateEmail = (email: string) =>
  !!email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

export interface TextFieldProps
  extends Omit<OutlinedTextFieldProps, "onChange" | "variant"> {
  value?: string | number;
  type?: "number" | "text" | "password" | "email";
  onChange?: (value: string | number) => void;
  hasError?: boolean;
  maxLength?: number;
}

const TextField: React.FC<TextFieldProps> = ({
  maxLength,
  InputProps,
  type,
  onChange,
  hasError,
  ...props
}) => {
  const styles = useStyles(props);
  const [showPassword, setShowPassword] = useState(false);

  const endAdornment = type === "password" && (
    <InputAdornment position="end">
      <IconButton onClick={() => setShowPassword(!showPassword)}>
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  const currentType =
    type !== "password" ? "text" : showPassword ? "text" : "password";

  return (
    <MuiTextField
      classes={{ root: styles.textFieldRoot }}
      className={clsx(styles.textField, {
        [styles.textFieldError]: hasError,
      })}
      variant="outlined"
      onChange={(e) => runInAction(() => onChange?.(e.target.value))}
      type={currentType}
      rows={6}
      InputProps={{
        endAdornment,
        inputProps:
          type === "number"
            ? { decimalScale: maxLength ?? 2, fixedDecimalScale: true }
            : { maxLength },
        inputComponent:
          type === "number" ? (NumberFormatCustom as any) : undefined,
        ...InputProps,
      }}
      onKeyDown={(event) => {
        if (type === "number" && ["+", "-", "e"].includes(event.key)) {
          event.preventDefault();
        }
      }}
      {...props}
    />
  );
};

export { TextField };
