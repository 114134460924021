import { makeObservable, observable, runInAction } from "mobx";

import { Api } from "../utilities/request";

export enum StatusCode {
  Ok = 0,
  Failed = 1,
}

export enum ErrorCode {
  LoginInvalid = 1,
  ResetPasswordExpired = 2,
  InvalidModel = 3,
  InvalidEmail = 4,
  InvalidPassword = 5,
  InvalidPermissions = 6,
  InvalidObjectId = 7,
}
export interface ServerResult {
  status: StatusCode;
  errorCode: ErrorCode;
}

export interface UserServerData {
  email: string;
  id: string;
  userName: string;
  firstName: string;
  lastName: string;
  userType: string;
  deactivated: boolean;
}

export type UserType = "Admin" | "User" | "TacomaAdmin";

export class User {
  static readonly _endpoint = "/api/User";

  @observable
  email?: string;

  @observable
  firstName?: string;

  @observable
  lastName?: string;

  @observable
  employeeId?: string;

  @observable
  deactivated?: boolean;

  @observable
  id?: string;

  @observable
  userType?: UserType;

  @observable
  needsPasswordChange?: boolean;

  constructor() {
    makeObservable(this);
  }

  static async Login(
    username: string,
    password: string
  ): Promise<User | undefined> {
    const response = await Api.request.post(`${this._endpoint}/login`, {
      username,
      password,
    });
    if (response.status === 200) {
      return new User().load();
    }

    return undefined;
  }

  static async ContactUs(
    name: string,
    telephone: string,
    email: string,
    message: string
  ): Promise<boolean> {
    const response = await Api.request.post(`${this._endpoint}/ContactUs`, {
      name,
      telephone,
      email,
      message,
    });
    return response.status === 200;
  }

  static async StartEmailReset(email: string): Promise<boolean> {
    const response = await Api.request.post(
      `${this._endpoint}/ResetPassword/Request`,
      {
        email,
      }
    );
    return response.status === 200;
  }

  static async FinishEmailReset(
    token: string,
    password: string
  ): Promise<ServerResult> {
    const response = await Api.request.post<ServerResult>(
      `${this._endpoint}/ResetPassword/?token=${token}`,
      {
        password,
      }
    );
    return response.data;
  }

  static fromServer(userData: UserServerData): User {
    const newUser = new User();
    Object.assign(newUser, userData);
    return newUser;
  }

  async load(): Promise<User | undefined> {
    const response = await Api.request.get<UserServerData>(
      `${User._endpoint}/profile`,
      {}
    );

    if (response.status === 200) {
      runInAction(() => {
        Object.assign(this, response.data);
      });
      return this;
    }

    return undefined;
  }

  async saveToServer(
    data: Partial<UserServerData>,
    password?: string,
    currentPassword?: string
  ): Promise<ServerResult> {
    const response = await Api.request.put<UserServerData & ServerResult>(
      `${User._endpoint}/${this.id}`,
      {
        ...this._toServer(data),
        password,
        currentPassword,
      }
    );

    runInAction(() => {
      if (response.status === 200) {
        Object.assign(this, response.data);
      }
    });
    return response.data;
  }

  async logout(): Promise<void> {
    return Api.request.post(`${User._endpoint}/logout`);
  }

  _toServer(data?: Partial<UserServerData>): any {
    return {
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      userType: this.userType,
      deactivated: this.deactivated,
      needsPasswordChange: this.needsPasswordChange,
      ...data,
    };
  }
}
