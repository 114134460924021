import { Grid } from "@material-ui/core";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";

import CalculationHeader from "../../common/CalculationHeader";
import CalculationLabel from "../../common/CalculationLabel";
import CalculationSection from "../../common/CalculationSection";
import CalculationSelect, { MenuItem } from "../../common/CalculationSelect";
import CalculationTextField from "../../common/CalculationTextField";
import { CalculationContext } from "../../state/Calculation";
import { RootContext } from "../../state/root";
import { isUserAdmin } from "../../utilities/user";

import { CalculationPageProps } from "./CalculationPage";

export const employerLookUp = {
  C: "City of Tacoma",
  T: "Pierce Transit",
  H: "Health",
  L: "LESA",
  D: "Deferred",
};

export type EmployeeLookupKey = typeof employerLookUp;

const Contributions: React.FC<CalculationPageProps> = observer(() => {
  const calculationContext = useContext(CalculationContext);
  const rootContext = useContext(RootContext);
  const { currentCalculation } = calculationContext;
  const isAdmin = isUserAdmin(rootContext.user);

  useEffect(() => {
    if (currentCalculation?.dateOfMembership?.isAfter("01/01/1983")) {
      runInAction(() => {
        currentCalculation.extraContributionsPre83 = 0;
        currentCalculation.additionalContributionsPre83 = 0;
      });
    }
  });

  if (!currentCalculation) {
    return null;
  }

  return (
    <Grid container>
      <CalculationHeader title="Contributions" />
      {currentCalculation.isProjection === "Y" ? (
        <>
          {!isAdmin ? (
            <>
              <CalculationSection>
                <CalculationLabel
                  label="Employer:"
                  value={employerLookUp[currentCalculation.employer!]}
                />
              </CalculationSection>
              <CalculationHeader
                title="Employee contributions"
                variant="secondary"
              />
              <CalculationSection>
                <CalculationLabel
                  label="Benefit contributions as of last quarter:"
                  format="dollar"
                  value={currentCalculation.benefitContributionsLastQuarter}
                />
              </CalculationSection>
            </>
          ) : (
            <>
              <CalculationSection>
                <CalculationSelect
                  label="Employer:"
                  value={currentCalculation.employer}
                  onChange={(value) =>
                    (currentCalculation.employer = value as any)
                  }
                >
                  {Object.keys(employerLookUp).map((key) => (
                    <MenuItem value={key} key={key}>
                      {employerLookUp[key as keyof EmployeeLookupKey]}
                    </MenuItem>
                  ))}
                </CalculationSelect>
              </CalculationSection>
              <CalculationHeader
                title="Employee contributions"
                variant="secondary"
              />
              <CalculationSection>
                <CalculationTextField
                  label="Member contributions as of last quarter:"
                  format="dollar"
                  value={currentCalculation.memberContributionsLastQuarter}
                  type="number"
                  onChange={(value) =>
                    (currentCalculation.memberContributionsLastQuarter =
                      value as number)
                  }
                />
                <CalculationTextField
                  label="Benefit contributions as of last quarter:"
                  format="dollar"
                  value={currentCalculation.benefitContributionsLastQuarter}
                  type="number"
                  onChange={(value) =>
                    (currentCalculation.benefitContributionsLastQuarter =
                      value as number)
                  }
                />
                <CalculationTextField
                  label="Member contributions as of last pay period:"
                  format="dollar"
                  value={currentCalculation.memberContributionsLastPayPeriod}
                  type="number"
                  onChange={(value) =>
                    (currentCalculation.memberContributionsLastPayPeriod =
                      value as number)
                  }
                />
                <CalculationTextField
                  label="Benefit contributions as of last pay period:"
                  format="dollar"
                  value={currentCalculation.benefitContributionsLastPayPeriod}
                  type="number"
                  onChange={(value) =>
                    (currentCalculation.benefitContributionsLastPayPeriod =
                      value as number)
                  }
                />
              </CalculationSection>

              <CalculationHeader
                title="Additional contributions as of last quarter"
                variant="secondary"
              />
              <CalculationSection>
                {currentCalculation.dateOfMembership?.isBefore("8/1/1983") && (
                  <CalculationTextField
                    label="Made before 8/1/1983:"
                    type="number"
                    format="dollar"
                    value={currentCalculation.additionalContributionsPre83}
                    onChange={(value) =>
                      (currentCalculation.additionalContributionsPre83 =
                        value as number)
                    }
                  />
                )}
                <CalculationTextField
                  label="Made after 8/1/1983:"
                  type="number"
                  format="dollar"
                  value={currentCalculation.additionalContributionsPost83}
                  onChange={(value) =>
                    (currentCalculation.additionalContributionsPost83 =
                      value as number)
                  }
                />
              </CalculationSection>
            </>
          )}
        </>
      ) : !isAdmin ? (
        <>
          <CalculationHeader
            title="Employee contributions"
            variant="secondary"
          />
          <CalculationSection>
            <CalculationLabel
              label="Total benefit contributions:"
              format="dollar"
              value={currentCalculation.totalBenefitContributions}
            />
            <CalculationLabel
              label="Non-taxable contributions:"
              format="dollar"
              value={currentCalculation.nonTaxableContributions}
            />
          </CalculationSection>
        </>
      ) : (
        <>
          <CalculationHeader
            title="Employee contributions"
            variant="secondary"
          />
          <CalculationSection>
            <CalculationTextField
              label="Total member contributions:"
              type="number"
              format="dollar"
              value={currentCalculation.totalMemberContributions}
              onChange={(value) =>
                (currentCalculation.totalMemberContributions = value as number)
              }
            />
            <CalculationTextField
              label="Total benefit contributions:"
              format="dollar"
              type="number"
              value={currentCalculation.totalBenefitContributions}
              onChange={(value) =>
                (currentCalculation.totalBenefitContributions = value as number)
              }
            />
            <CalculationTextField
              label="Non-taxable contributions:"
              format="dollar"
              type="number"
              value={currentCalculation.nonTaxableContributions}
              onChange={(value) =>
                (currentCalculation.nonTaxableContributions = value as number)
              }
            />
          </CalculationSection>
          <CalculationHeader
            title="Additional contributions"
            variant="secondary"
          />
          <CalculationSection>
            {currentCalculation.dateOfMembership?.isBefore("8/1/1983") && (
              <CalculationTextField
                label="Made before 8/1/1983:"
                type="number"
                format="dollar"
                value={currentCalculation.extraContributionsPre83}
                onChange={(value) =>
                  (currentCalculation.extraContributionsPre83 = value as number)
                }
              />
            )}
            <CalculationTextField
              label="Made after 8/1/1983:"
              type="number"
              format="dollar"
              value={currentCalculation.extraContributionsPost83}
              onChange={(value) =>
                (currentCalculation.extraContributionsPost83 = value as number)
              }
            />
          </CalculationSection>
        </>
      )}

      {currentCalculation.isProjection === "Y" && (
        <div style={{ margin: "1rem" }}>
          <p>
            These are your latest known contributions. If these numbers do not
            appear correct, contact the TERS administrative office.
          </p>
          {isAdmin && (
            <p>
              If the last pay period falls at the end of a quarter, the
              contribution amounts as of the last quarter and last pay period
              should be the same. If not, the contributions as of the last pay
              period should be greater for an active employee.
            </p>
          )}
        </div>
      )}
    </Grid>
  );
});

export default Contributions;
