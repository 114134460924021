import { action, computed, makeObservable, observable } from "mobx";
import React from "react";

class CalculationErrors {
  @observable
  formErrors = observable.map<string, string>();

  @observable
  errors = observable.map<string, string>();

  @observable
  validateErrors: boolean;

  constructor(validateErrors: boolean | undefined = true) {
    makeObservable(this);
    this.validateErrors = validateErrors;
  }

  @computed
  get hasFormErrors(): boolean {
    return this.formErrors.size !== 0;
  }

  @computed
  get hasAnyErrors(): boolean {
    return this.formErrors.size !== 0 || this.errors.size !== 0;
  }

  get allErrorString(): string {
    return `${this.errorString} ${this.formErrorString}`;
  }

  get formErrorString(): string {
    if (this.formErrors.size !== 0) {
      return `${[...this.formErrors.values()].join(", ")} field(s) required.\n`;
    } else {
      return "";
    }
  }

  get errorString(): string {
    if (this.errors.size !== 0) {
      return `${[...this.errors.values()].join(", ")}.`;
    } else {
      return "";
    }
  }

  @action
  addError(id: string, formKey: string): void {
    this.errors.set(`${id}:${formKey}`, this._trim(formKey));
  }

  @action
  removeError(id: string, formKey: string): void {
    this.errors.delete(`${id}:${formKey}`);
  }

  @action
  addFormError(id: string, formKey: string): void {
    this.formErrors.set(`${id}:${formKey}`, this._trim(formKey));
  }

  @action
  removeFormError(id: string, formKey: string): void {
    this.formErrors.delete(`${id}:${formKey}`);
  }

  @action
  clear(): void {
    this.formErrors.clear();
    this.errors.clear();
  }

  @action
  setValidateErrors(validateErrors: boolean): void {
    this.validateErrors = validateErrors;
  }

  private _trim(value: string): string {
    return value.replaceAll(":", "").trim();
  }
}

const CalculationErrorsState = new CalculationErrors();
const CalculationErrorContext = React.createContext(CalculationErrorsState);

export { CalculationErrors, CalculationErrorsState, CalculationErrorContext };
