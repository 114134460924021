import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import { KeyboardDatePicker } from "@material-ui/pickers/DatePicker";
import clsx from "clsx";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { Moment } from "moment";
import React, { useContext, useEffect } from "react";

import { CalculationErrorContext } from "../state/CalculationErrors";
import { Colors } from "../utilities/colors";

import { useId } from "./useId";

interface CalculationDateProps {
  value?: Moment | null;
  label: string | React.ReactNode;
  labelOverride?: string;
  onChange?: (value: Moment) => void;
  mustHaveValue?: boolean;
  labelWidth?: number;
  minDateMessage?: string;
  minDate?: ParsableDate;
  maxDateMessage?: string;
  maxDate?: ParsableDate;
  validation?: (date: Moment) => string | undefined;
}

const useStyles = makeStyles<Theme, CalculationDateProps>((theme) => ({
  label: {
    width: "100%",
    marginBottom: ".5rem",
  },
  input: {
    width: "100%",
    marginLeft: "2rem",
    "& > div": {
      background: Colors.White,
    },
    "& .MuiIconButton-root": {
      display: "none",
    },
  },
  inputError: {
    "& fieldset": {
      borderColor: "red !important",
    },
  },
  labelInner: {
    width: ({ labelWidth }) => `${labelWidth}rem`,
    minWidth: ({ labelWidth }) => `${labelWidth}rem`,
  },
  labelPlacementStart: {
    justifyContent: "space-between",
    minWidth: "15rem",
  },
}));

export default observer(
  ({
    label,
    onChange,
    value: inputValue,
    mustHaveValue = true,
    labelWidth = 15,
    minDateMessage = "",
    minDate,
    maxDateMessage = "",
    maxDate,
    validation,
    labelOverride,
  }: CalculationDateProps) => {
    const id = useId();
    const styles = useStyles({ label, labelWidth });
    const calculationErrorContext = useContext(CalculationErrorContext);
    const value = inputValue?.isSame("0001-01-01") ? null : inputValue;
    const isValid = inputValue?.isValid();
    const isInRange =
      inputValue?.isAfter("1900-01-01") && inputValue?.isBefore("2100-01-01");
    const validationError = isValid && isInRange && validation?.(inputValue!);
    const rangeError = `${
      labelOverride ?? label
    } required. Please enter a valid date`;

    useEffect(() => {
      if ((!isValid && mustHaveValue) || !isInRange) {
        calculationErrorContext.addError(id, rangeError);
      }
      if (validationError) {
        calculationErrorContext.addError(id, validationError);
      }
      return () => {
        if ((!isValid && mustHaveValue) || !isInRange) {
          calculationErrorContext.removeError(id, rangeError);
        }
        if (validationError) {
          calculationErrorContext.removeError(id, validationError);
        }
      };
    }, [
      id,
      isInRange,
      rangeError,
      calculationErrorContext,
      label,
      value,
      isValid,
      mustHaveValue,
      validationError,
    ]);

    return (
      <FormControlLabel
        control={
          <KeyboardDatePicker
            format="MM/DD/yyyy"
            inputVariant="outlined"
            invalidDateMessage=""
            placeholder="MM/DD/YYYY"
            minDateMessage={minDateMessage}
            minDate={minDate}
            maxDateMessage={maxDateMessage}
            maxDate={maxDate}
            open={false}
            value={value ?? null}
            className={clsx(styles.input, {
              [styles.inputError]:
                (!isValid || validationError || !isInRange) &&
                calculationErrorContext.validateErrors,
            })}
            onChange={(date) =>
              runInAction(() => onChange?.(date as moment.Moment))
            }
            keyboardIcon={null}
            allowKeyboardControl={false}
          />
        }
        label={label}
        labelPlacement="start"
        className={styles.label}
        classes={{
          labelPlacementStart: styles.labelPlacementStart,
          label: styles.labelInner,
        }}
      />
    );
  }
);
