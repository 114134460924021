import { Grid } from "@material-ui/core";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";

import CalculationDate from "../../common/CalculationDate";
import CalculationHeader from "../../common/CalculationHeader";
import CalculationLabel from "../../common/CalculationLabel";
import CalculationSection from "../../common/CalculationSection";
import CalculationSelect, { MenuItem } from "../../common/CalculationSelect";
import CalculationTextField from "../../common/CalculationTextField";
import { Tooltip } from "../../common/Tooltip";
import { BenefitType, CalculationContext } from "../../state/Calculation";
import { RootContext } from "../../state/root";
import { isUserAdmin } from "../../utilities/user";

import { CalculationPageProps } from "./CalculationPage";

const ParticipantInformationToolTip = () => (
  <>
    To update your name, date of birth, or date of membership:
    <ul>
      <li>
        If you are currently employed, notify human resources of the correct
        information so that they can make corrections.
      </li>
      <li>
        If you are not currently employed, use the Contact Us page to let the
        Retirement Department know the correct information.
      </li>
      <li>
        If date of membership is incorrect use the Contact Us page to let the
        Retirement Department know the correct information.
      </li>
    </ul>
  </>
);

const DeathImmediateInformationToolTip = () => (
  <>
    Please enter the Death Beneficiary's Date of Birth. If the benefit is going
    to the Estate, please enter the member's Date of Birth.
  </>
);

const BasicInformation: React.FC<CalculationPageProps> = observer(() => {
  const calculationContext = useContext(CalculationContext);
  const rootContext = useContext(RootContext);
  const { currentCalculation } = calculationContext;
  const isAdmin = isUserAdmin(rootContext.user);

  useEffect(() => {
    if (
      currentCalculation?.benefitType === "Retirement" ||
      currentCalculation?.benefitType === "Disability"
    ) {
      runInAction(() => {
        currentCalculation.beneficiaryInformationFirstName = "";
        currentCalculation.beneficiaryInformationLastName = "";
        currentCalculation.multipleBeneficiaries = "No";
        currentCalculation.beneficiaryDateOfBirth = undefined;
      });
    }
  });

  if (!currentCalculation) {
    return null;
  }

  return (
    <Grid container>
      <CalculationSection>
        <CalculationTextField
          label="Calculation description:"
          maxLength={25}
          value={currentCalculation.description}
          onChange={(value) =>
            (currentCalculation.description = value as string)
          }
        />
        <CalculationSelect
          label="Benefit type:"
          value={currentCalculation.benefitType}
          onChange={(value) =>
            (currentCalculation.benefitType = value as BenefitType)
          }
        >
          <MenuItem value="Retirement">Retirement</MenuItem>
          <MenuItem value="Disability">Disability</MenuItem>
          <MenuItem value="Death-Immediate">Death-Immediate</MenuItem>
          <MenuItem value="Death-Deferred">
            Death-Survivor's Retirement
          </MenuItem>
        </CalculationSelect>

        {isAdmin && (
          <CalculationSelect
            label="Do you know the exact value of the contributions as of the retirement date?:"
            value={currentCalculation.isProjection}
            onChange={(value) => (currentCalculation.isProjection = value)}
          >
            <MenuItem value="N">Yes</MenuItem>
            <MenuItem value="Y">No</MenuItem>
          </CalculationSelect>
        )}
      </CalculationSection>

      <CalculationHeader
        title="Participant information"
        variant="secondary"
        tooltip={<ParticipantInformationToolTip />}
      />
      <CalculationSection>
        <CalculationLabel
          label="Employee number:"
          value={currentCalculation.employeeNumber}
        />
        <CalculationLabel
          label="First name:"
          value={currentCalculation.firstName}
        />
        <CalculationLabel
          label="Last name:"
          value={currentCalculation.lastName}
        />
        {isAdmin ? (
          <CalculationDate
            label="Date of birth:"
            value={currentCalculation.dateOfBirth}
            onChange={(date) => (currentCalculation.dateOfBirth = date)}
          />
        ) : (
          <CalculationLabel
            label="Date of birth:"
            value={currentCalculation.dateOfBirth!.format("L")}
          />
        )}
        {isAdmin ? (
          <CalculationDate
            label="Date of membership:"
            value={currentCalculation.dateOfMembership}
            onChange={(date) => (currentCalculation.dateOfMembership = date)}
          />
        ) : (
          <CalculationLabel
            label="Date of membership:"
            value={currentCalculation.dateOfMembership!.format("L")}
          />
        )}
        <CalculationDate
          label={
            isAdmin ? (
              <>
                Last day of employment:
                <Tooltip
                  style={{ float: "right" }}
                  tooltip="For death benefit calculations, enter the date of death here rather than the termination date of the member."
                />
              </>
            ) : (
              "Last day of employment:"
            )
          }
          value={currentCalculation.lastDayOfEmployment}
          onChange={(date) => (currentCalculation.lastDayOfEmployment = date)}
          validation={(date) => {
            if (date < currentCalculation.dateOfMembership!) {
              return "Last day of employment cannot be before Date of membership";
            }
          }}
        />
        <CalculationDate
          label="Date of retirement:"
          value={currentCalculation.dateOfRetirement}
          onChange={(date) => (currentCalculation.dateOfRetirement = date)}
          minDate={currentCalculation.lastDayOfEmployment}
          validation={(date) => {
            if (date < currentCalculation.lastDayOfEmployment!) {
              return "Date of retirement cannot be before Last day of employment";
            }
            if (date.date() !== 1) {
              return "Date of retirement can only be 1st of a month";
            }
          }}
        />
        <CalculationLabel
          label="Age at retirement:"
          minimumFractionDigits={2}
          value={currentCalculation.results!.ageOfRetirement}
        />
        {isAdmin && (
          <CalculationSelect
            label="Gender:"
            value={currentCalculation.gender}
            onChange={(value) => (currentCalculation.gender = value)}
          >
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="Female">Female</MenuItem>
          </CalculationSelect>
        )}
      </CalculationSection>

      {(currentCalculation.benefitType === "Death-Deferred" ||
        currentCalculation.benefitType === "Death-Immediate") && (
        <>
          <CalculationSection>
            {isAdmin && (
              <CalculationSelect
                label="Multiple Beneficiaries?"
                value={currentCalculation.multipleBeneficiaries}
                onChange={(value) =>
                  (currentCalculation.multipleBeneficiaries = value)
                }
              >
                <MenuItem value="Y">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </CalculationSelect>
            )}
            <CalculationTextField
              label="First name:"
              value={currentCalculation.beneficiaryInformationFirstName}
              onChange={(value) =>
                (currentCalculation.beneficiaryInformationFirstName =
                  value as string)
              }
            />
            <CalculationTextField
              label="Last name:"
              value={currentCalculation.beneficiaryInformationLastName}
              onChange={(value) =>
                (currentCalculation.beneficiaryInformationLastName =
                  value as string)
              }
            />
            <CalculationDate
              labelOverride="Date of birth:"
              label={
                <span style={{ display: "flex" }}>
                  Date of birth:
                  <Tooltip
                    tooltip={
                      currentCalculation.benefitType === "Death-Immediate" && (
                        <DeathImmediateInformationToolTip />
                      )
                    }
                  />
                </span>
              }
              value={currentCalculation.beneficiaryDateOfBirth}
              onChange={(date) =>
                (currentCalculation.beneficiaryDateOfBirth = date)
              }
            />
          </CalculationSection>
        </>
      )}
    </Grid>
  );
});

export default BasicInformation;
